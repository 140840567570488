import React, { useEffect, useState } from 'react';
import ApplicationLayoutDriverCatalog from 'v3/layouts/Application/HeaderVechicleControlTower';
import { Button, Text } from 'v3/components';
import { DefaultLoadingComponent } from 'v3/components/List';
import { Col, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { cooperplaceApi } from 'services/api';
import apiReport from 'services/apis/report';
import { useSnackbar } from 'v3/components/Snackbar';
import { Grid } from './Grid';
import { Filter } from './Filter';
import LoadOfferModal from './LoadOfferModal';
import AggregationOfferModal from './AggregationOfferModal';
import MapDrivers from './MapDrivers';


export default function DriverCatalog() {
  const snackbar = useSnackbar();
  const history = useHistory();
  const [showCargoOfferModal, setShowCargoOfferModal] = useState(false);
  const [showAggregationOfferModal, setShowAggregationOfferModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingMap, setLoadingMap] = useState(true);
  const [filters, setFilters] = useState({});
  const [showMap , setShowMap ] = useState(false)
  const [showFilters, setShowFilters] = useState(true)
  const [isDriverChecked , setIsDriverChecked ] = useState(false)
  const [driversData, setDriversData] = useState([]);
  const [driverLocations, setDriverLocations] = useState([]);
  const [selectedDriverId, setSelectedDriverId] = useState(null)
  const [pagination, setPagination] = useState(null);
  const [perPage, setPerPage] = useState(50);

  function createSearchUrl(params) {
    const newQuery = new URLSearchParams();
    if (params.activeInPortal && params.activeInPortal.value) {
      newQuery.set('activeInPortal', params.activeInPortal.value);
    }
    if (params.activeInApp && params.activeInApp.value) {
      newQuery.set('activeInApp', params.activeInApp.value);
    }
    if (params.verify && params.verify.value) {
      newQuery.set('verify', params.verify.value);
    }
    if (params.currentCity && params.currentCity.length > 0) {
      newQuery.set('currentCity', params.currentCity.map(v => v.id).join(','));
    }
    if (params.currentProvince && params.currentProvince.length > 0) {
      newQuery.set('currentProvince', params.currentProvince.map(v => v.id).join(',')
      );
    }
    if (params.currentCountry && params.currentCountry.length > 0) {
      newQuery.set('currentCountry', params.currentCountry.map(v => v.id).join(',')
      );
    }
    if (params.homeCity && params.homeCity.length > 0) {
      newQuery.set('homeCity', params.homeCity.map(v => v.id).join(','));
    }
    if (params.homeProvince && params.homeProvince.length > 0) {
      newQuery.set('homeProvince', params.homeProvince.map(v => v.id).join(',')
      );
    }
    if (params.homeCountry && params.homeCountry.length > 0) {
      newQuery.set('homeCountry', params.homeCountry.map(v => v.id).join(',')
      );
    }
    if (params.driver && params.driver.length > 0) {
      newQuery.set('driver', params.driver.map(v => v.id).join(','));
    }
    if (params.plate && params.plate.length > 0) {
      newQuery.set('plate', params.plate.map(v => v.id).join(','));
    }
    if (params.vehicleType && params.vehicleType.length > 0) {
      newQuery.set('vehicleType', params.vehicleType.map(v => v.id).join(','));
    }
    if (params.bodyType && params.bodyType.length > 0) {
      newQuery.set('bodyType', params.bodyType.map(v => v.id).join(','));
    }
    if (params.implementType && params.implementType.length > 0) {
      newQuery.set(
        'implementType',
        params.implementType.map(v => v.id).join(',')
      );
    }
    if (params.phoneNumber && params.phoneNumber.trim() !== '') {
      newQuery.set('phoneNumber', params.phoneNumber.trim());
    }
    if (params.locatedUpTo && params.locatedUpTo.value) {
      newQuery.set('locatedUpTo', params.locatedUpTo.value);
    }
    history.replace({ search: newQuery.toString() });

    return newQuery
  }

  async function fetchData(query) {
    try {
      setLoading(true);
      setIsDriverChecked(false)

      if (!query.get('perPage')) {
        query.set('perPage', perPage)
      }
      
      const response = await apiReport.get(
        `/drivers?${query.toString()}`
      );

      const { pagination, data } = response.data
      setPagination(pagination);
      setDriversData(data);
    } catch (error) {
      snackbar.show(<Text>Erro ao recuperar motoristas.</Text>, {
        type: 'error',
      });
    } finally {
      setLoading(false);
    }
  }

  async function fetchPositions(query) {
    setLoadingMap(true)
    try {
      const response = await apiReport.get(
        `/drivers/coordinates?${query.toString()}`
      );
      setDriverLocations(response.data.drivers);
    } catch (error) {
      snackbar.show(<Text>Erro ao recuperar posições dos motoristas.</Text>, {
        type: 'error',
      });
    }finally{
      setLoadingMap(false)
    }
  }

  function onFilter() {
    const query = createSearchUrl(filters);
    fetchData(query)
    fetchPositions(query)
  }

  function onChangePage(page) {
    const query = createSearchUrl(filters);
    query.set('page', page)
    fetchData(query)
  }

  function onChangePerPage(newPerPage) {
    const query = createSearchUrl(filters);
    query.set('perPage', newPerPage)
    setPerPage(newPerPage)
    fetchData(query)
  }

  useEffect(() => {
    const fetchFiltersPreference = async () => {
      try {
        const response = await cooperplaceApi.get(
          `/user/definitions?types[]=easy_frota_filters`
        );
        if (response?.data[0]?.definition) {
          setFilters(JSON.parse(response?.data[0]?.definition));
          const query = createSearchUrl(JSON.parse(response?.data[0]?.definition));
          await Promise.all([fetchData(query), fetchPositions(query)]);
        } else {
          const query = createSearchUrl(filters);
          await Promise.all([fetchData(query), fetchPositions(query)]);
        }
      } catch (error) {
        snackbar.show(<Text>Erro ao recuperar preferência de filtros</Text>, {
          type: 'error',
        });
      }
    };
    fetchFiltersPreference();
  }, []);

  return (
    <>
      { selectedDriverId && <LoadOfferModal
        showModal={showCargoOfferModal}
        setShowModal={setShowCargoOfferModal}
        driverId={selectedDriverId}
      />}
     <AggregationOfferModal
        showModal={showAggregationOfferModal}
        setShowModal={setShowAggregationOfferModal}
        driverId={selectedDriverId}
      />
      <ApplicationLayoutDriverCatalog
        title="Easy Frota"
        contentFluid
        isOld
        onlyCenterComponent
        pageInformation={false}
      >
        <div style={{ paddingLeft: '50px', marginTop: '10px' }} className="row-cols-md-12">
          <Row className="justify-content-end">
            <Col md={2} sm={12} style={{ display: 'flex', alignItems: 'end', marginBottom: '10px', maxWidth: '200px'}}>
              <Button
                style={{ flex: 1 }}
                loading={loading}
                onClick={() => setShowFilters(!showFilters)}
              >
                <Text type="regular" weight={500}>
                  {`${ showFilters ? 'Minimizar filtros' :  'Maximizar filtros' }`}
                </Text>
              </Button>
            </Col>
            <Col md={2} sm={12} style={{ display: 'flex', alignItems: 'end', marginBottom: '10px',  maxWidth: '200px'}}>
              <Button
                style={{ flex: 1 }}
                loading={loadingMap}
                onClick={() => setShowMap(!showMap)}
              >
                <Text type="regular" weight={500}>
                  {`${ showMap ? 'Minimizar mapa' : 'Ver mapa'}`}
                </Text>
              </Button>
            </Col>
          </Row>
        </div>
        { showFilters && <div style={{ paddingLeft: '50px' }} className="row-cols-md-12">
          <Filter
            filters={filters}
            setFilters={setFilters}
            setLoading={setLoading}
            onFilter={onFilter}
            loading={loading}
          />
        </div>}
        <div style={{ paddingLeft: '50px' }} className="row-cols-md-12">
          <MapDrivers
            showMap={showMap}
            driversData={driverLocations}
            loading={loadingMap}
            checked={isDriverChecked}
            setChecked={setIsDriverChecked}
            locatedUpTo={filters?.locatedUpTo?.value}
          />
        </div>
        <div style={{ paddingLeft: '50px' }}>
          {loading ? (
            <DefaultLoadingComponent />
          ) : (
            <Grid
              driverData={driversData}
              pagination={pagination}
              setShowCargoOfferModal={setShowCargoOfferModal}
              setShowAggregationOfferModal={setShowAggregationOfferModal}
              loading={loading}
              onChangePage={onChangePage}
              onChangerPerPage={onChangePerPage}
              perPage={perPage}
              setDriverId={setSelectedDriverId}
            />
          )}
        </div>
      </ApplicationLayoutDriverCatalog>
    </>
  );
}
