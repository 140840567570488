import React, { useState } from 'react';
import { Button, Card, Text } from 'v3/components';
import Space from 'v3/components/Space';
import { Inputs } from './Inputs';
import { VehicleDataSchema } from '../../validator';
import { useSnackbar } from 'v3/components/Snackbar';
import { cooperplaceApi } from 'services/api';
import { convertNumberToDatabase } from 'v3/utils/formatter';
import { VehicleDetails } from './VehicleDetails';

export function VehicleData({
  preLoad,
  hasPermissionToEdit,
  getPreLoad,
  loading,
  shouldDisableEditing,
  disabledTitle,
}) {
  const [editing, setEditing] = useState(false);
  const [errors, setErrors] = useState({});
  const [data, setData] = useState();
  const [submiting, setSubmiting] = useState(false);
  const snackbar = useSnackbar();

  async function validate(dataToValidate, setErrors) {
    const errorList = {};
    try {
      await VehicleDataSchema.validate(dataToValidate, { abortEarly: false });
      setErrors({ ...errorList });
      return true;
    } catch (err) {
      if (err.inner) {
        err.inner.forEach(error => {
          errorList[error.path] = error.message;
        });

        setErrors({ ...errorList });
        return false;
      }
    }
  }

  async function handleSubmit() {
    setSubmiting(true);
    if (
      !(await validate(
        {
          vehicleTypes: data?.vehicleTypes,
          vehicleBodies: data?.vehicleBodyTypes,
          vehicleImplements: data?.vehicleImplementTypes,
          disposition: data?.disposition,
          weight: data?.weight
        },
        setErrors
      ))
    ) {
      snackbar.show(
        <Text type="body" weight={500}>
          Verifique os campos obrigatórios!
        </Text>,
        {
          type: 'error',
        }
      );
      setSubmiting(false);
      return false;
    }

    const payload = {
      takerId: preLoad?.taker.id,
      productId: preLoad?.product.id,
      branchOfficeId: preLoad?.branchOffice?.id,
      costCenterId: preLoad?.costCenter?.id,
      takerValue: +preLoad?.takerValue,
      currency: preLoad?.currency?.value || 'BRL',
      dueDate: preLoad?.dueDate,
      dispositionId: data?.disposition?.id,
      loadWeight: +convertNumberToDatabase(data?.weight),
      vehicleTypeIds: data?.vehicleTypes?.map(type => type.id),
      vehicleImplementTypeIds: data?.vehicleImplementTypes?.map(
        implement => implement?.id
      ),
      vehicleBodyTypeIds: data?.vehicleBodyTypes?.map(body => body.id),
    };

    try {
      await cooperplaceApi.put(`pre-loads/${preLoad?.id}`, payload);
      await getPreLoad();
      setTimeout(() => {
        setSubmiting(false);
        setEditing(false);
      }, [1000]);
    } catch (error) {
      setSubmiting(false);
      const message = error?.response?.data?.message || 'Erro ao atualizar pré carga!';
      snackbar.show(
        <Text type="body" weight={500}>
          {message}
        </Text>,
        { type: 'error' }
      );
    }
  }

  return (
    <Card
      header={
        <Text color="#464E5F" type="header">
          Dados do Veículo
        </Text>
      }
      loading={submiting || loading}
      HeaderRightComponent={
        hasPermissionToEdit && (
          <Space x={'0.5rem'}>
            {editing && (
              <Button
                onClick={() => setEditing(false)}
                variant="secondary"
                loading={loading}
                className="mr-2"
              >
                <Text color="dark" type="regular">
                  Cancelar
                </Text>
              </Button>
            )}
            <Button
              onClick={editing ? () => handleSubmit() : () => setEditing(true)}
              variant={editing ? 'primary' : 'secondary'}
              disabled={shouldDisableEditing}
              loading={submiting}
              title={shouldDisableEditing ? disabledTitle : ''}
            >
              <Text color={editing ? 'white' : 'dark'} type="regular">
                {editing ? 'Salvar' : 'Editar'}
              </Text>
            </Button>
          </Space>
        )
      }
    >
      {editing ? (
        <Inputs
          data={data}
          setData={setData}
          preLoad={preLoad}
          errors={errors}
        />
      ) : (
        <VehicleDetails preLoad={preLoad} />
      )}
    </Card>
  );
}
