/* eslint-disable consistent-return */
import React, { useState, useLayoutEffect } from 'react';
import axios from 'axios';
import { Grid } from '@material-ui/core';

import Button from 'v3/components/Button';
import Text from 'v3/components/Text';
import Stepper from '../../components/Stepper';
import Input from '../../components/Input';
import { useSnackbar } from 'v3/components/Snackbar';

import api, { cooperplaceApi } from '../../services/api';
import { isAuthenticated } from '../../services/auth';
import config from '../../config';
import { loadRecaptcha } from 'services/reCaptcha'

import { validate } from './validate';

import {
  Container,
  Card,
  Logo,
  Line,
  ForgotPassword,
  Version,
  GoBack,
} from './styles';

const Steps = {
  LOGIN: 0,
  FORGOT_PASSWORD: 1,
};

function Login({ history }) {

  const snackbar = useSnackbar();
  const [error, setErrors] = useState({});

  const [data, setData] = useState({
    email: '',
    password: '',
  });

  const [loading, setLoading] = useState(false);

  const initialStep = Steps.LOGIN;
  const [currentStep, setCurrentStep] = useState(initialStep);

  useLayoutEffect(() => {
    if (isAuthenticated()) {
      history.push('/');
    }

    const script = document.createElement("script");
    script.src = "https://www.google.com/recaptcha/api.js?render=6Ld3Ec4qAAAAAE4Ao5zjdsFycQxD6PH4hXUrR0XN";
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);

  }, []);

  async function handleLogsLogin() {
    try {
      const log = {
        name: 'login',
        path: null,
        description: 'login',
      };

      await api.post('v3/user/logs', log);
    } catch (error) {
      return [];
    }
  }

  const setPermissions = async (user) => {
    try {
      const profile = await api.get(
        `users-profiles/${user.profileId}`
      );
      const permissions_data = profile.data.permissions;
      localStorage.setItem('permissions', JSON.stringify(permissions_data));

      const issue_permission =
        permissions_data.some(
          permission => permission.slug === 'EMITIR_CTE' && permission.active
        ) && user.currentCompany.canIssue;

      localStorage.setItem('issue_permission', issue_permission);
    } catch (error) {
      return [];
    }
  }

  const setPushNotifications = async (user) => {
    const isPushNotificationsEnabled = localStorage.getItem(
      'isPushNotificationsEnabled'
    );
    if (isPushNotificationsEnabled) {
      const oneSignalPlayerId = localStorage.getItem('oneSignalPlayerId');
      if (oneSignalPlayerId !== user.pushUserId) {
        if (oneSignalPlayerId) {
          await api.put('user/add-user-id', {
            push_user_id: oneSignalPlayerId,
          });
          localStorage.setItem('updatePushUserId', false);
        }
      }
    }
  }

  async function submitLogin() {
    setLoading(true);

    try {

      const isDataValid = await validate(data, setErrors, 'LOGIN');

      if (!isDataValid) {
        snackbar.show(<Text>E-mail e senha obrigatórios</Text>, {
          type: 'error',
        });
        setLoading(false);
        return;
      }
      const recaptchaToken = await loadRecaptcha();

      const response = await cooperplaceApi.post(`sessions/login`, {
        email: data.email,
        password: data.password,
        recaptchaToken: recaptchaToken || ''
      });

      if (response.status === 200) {
        const user_data = response.data.user;
        localStorage.setItem('token', response.data.token);
        localStorage.setItem('updatePushUserId', true);

        axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
          'token'
        )}`;

        await setPermissions(user_data);
        setPushNotifications(user_data);

        window.location.href = '/';
        handleLogsLogin();
      }
    } catch (ex) {
      const message = ex?.response?.data?.error;
      snackbar.show(<Text>{message}</Text>, {
        type: 'error',
      });
    } finally {
      setLoading(false);
    }
  }

  async function submitPasswordRecovery() {
    setLoading(true);
    try {

      const isDataValid = await validate({
        email_recovery: data.email,
      }, setErrors, 'FORGOT_PASSWORD');

      if (!isDataValid) {
        snackbar.show(<Text>E-mail obrigatório</Text>, {
          type: 'error',
        });
        setLoading(false);
        return;
      }

      const response = await api.post('forgot-password', {
        email: data.email,
      });

      if (response.status === 200) {
        snackbar.show(<Text>Enviamos o link para alteração de senha para seu e-mail.</Text>, {
          type: 'success',
        });
        setData({ ...data, email: '' });
        setCurrentStep(Steps.LOGIN);
      }
    } catch (ex) {
      snackbar.show(<Text>Enviamos o link para alteração de senha para seu e-mail</Text>, {
        type: 'success',
      });
      setData({ ...data, email: '' });
      setCurrentStep(Steps.LOGIN);
    } finally {
      setLoading(false);
    }
  }

  async function handleSubmit(event) {
    event.preventDefault();

    if (currentStep === Steps.LOGIN) {
      await submitLogin();
    } else {
      await submitPasswordRecovery();
    }
  }

  const steps = [
    {
      step: Steps.LOGIN,
      component: (
        <>
          <Input
            name="email"
            label={
              <div className="mb-2">
                <Text color="primary" weight="500">
                  E-mail
                </Text>
              </div>
            }
            placeholder="Para começar insira seu e-mail"
            value={data.email}
            type="email"
            error={error.email}
            onChange={event =>
              setData({
                ...data,
                email: event.target.value,
              })
            }
          />
          <Input
            name="password"
            label={
              <div className="mb-2">
                <Text color="primary" weight="500">
                  Senha
                </Text>
              </div>
            }
            placeholder="Insira sua senha"
            value={data.password}
            type="password"
            error={error.password}
            autoFocus
            onChange={event =>
              setData({
                ...data,
                password: event.target.value,
              })
            }
          />
          <Grid
            container
            direction="column"
            alignItems="center"
            className="mt-4"
          >
            <Button type="submit" loading={loading}>
              <Text color="white" weight="500" type="regular">
                Entrar
              </Text>
            </Button>
            <ForgotPassword
              onClick={() => setCurrentStep(Steps.FORGOT_PASSWORD)}
            >
              <Text color="dark" weight="500">
                Esqueci minha senha
              </Text>
            </ForgotPassword>
          </Grid>
        </>
      ),
    },
    {
      step: Steps.FORGOT_PASSWORD,
      component: (
        <>
          <Input
            name="email"
            label={
              <div className="mb-2">
                <Text color="primary" weight="500">
                  Recuperar senha
                </Text>
              </div>
            }
            placeholder="Insira o e-mail de login para recuperar a senha"
            value={data.email}
            type="email"
            onChange={event =>
              setData({
                ...data,
                email: event.target.value,
                error: false,
              })
            }
            error={error.email_recovery}
          />
          <Grid
            container
            direction="column"
            alignItems="center"
            className="mt-3"
          >
            <Button type="submit" loading={loading}>
              <Text color="white" weight="500" type="regular">
                Enviar
              </Text>
            </Button>

            <GoBack
              onClick={() => {
                setCurrentStep(Steps.LOGIN);
                setData({ email: '', password: '' });
                setErrors({})
              }}
            >
              <Text color="dark" weight="500">
                Voltar
              </Text>
            </GoBack>
          </Grid>
        </>
      ),
    },
  ];

  return (
    <Container>
      <Card>
        <Grid container direction="column" alignItems="center">
          <Logo src="/images/Logo-Cooperplace.png" alt="Cooperplace" />
          <Line />
        </Grid>
        <form onSubmit={handleSubmit}>
          <Stepper steps={steps} currentStep={currentStep} />
        </form>
      </Card>
      <Version>
        Versão {config.versionNumber}
      </Version>
    </Container>
  );
}

export default Login;
