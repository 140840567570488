/* eslint-disable import/no-unresolved */
import React, { useState } from 'react';

import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import Grid from '@material-ui/core/Grid';

import dealAPI from 'services/apis/deal';

import './style.css';

function TextComposer({ deal, setMessages, iniciateButton, userId }) {
  const [newMessage, setNewMessage] = useState('');

  const isDisabled = deal?.negotiator_id == userId ? false : true;

  async function sendMessage(e) {
    e.preventDefault();

    if (deal?.closed) return;

    if (newMessage.trim() !== '') {
      const usuario = localStorage.getItem('usuario');
      const jsonU = JSON.parse(usuario);

      const data = {
        sender_id: jsonU.id,
        receiver_id: deal?.driver_id,
        deal_id: deal?.id,
        message: newMessage,
      };

      setNewMessage('');
      setMessages(prev => [
        ...prev,
        {
          ...data,
          created_at: new Date(),
        },
      ]);

      try {
        await dealAPI.post('messages', data);
      } catch (ex) {
        // Handle exception
      }
    }
  }

  return (
    (!deal?.closed && deal?.negotiator_id)  && (
      <form onSubmit={sendMessage}>
        <Grid className="compose">
          <Input
            className="compose-input"
            placeholder="Digite a mensagem"
            id="composer"
            value={newMessage}
            onChange={e => setNewMessage(e.target.value)}
            disabled={isDisabled || iniciateButton}
          />
          <Button disabled={isDisabled || iniciateButton} type="submit">enviar</Button>
        </Grid>
      </form>
    )
  );
}

export default TextComposer;
