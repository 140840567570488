import React from 'react';
import { AnimationCircleStyle } from './styles';

export default function AnimationCircle({
  animation = true,
  top,
  left,
  right,
  bottom,
  color = 'red',
  width,
  height,
}) {
  return (
    <AnimationCircleStyle
      top={top}
      left={left}
      bottom={bottom}
      right={right}
      width={width}
      height={height}
      color={color}
      animation={animation}
    />
  );
}
