import React from 'react';
import { ConfigProvider } from 'antd';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { OccurrencesAditionalFieldsProvider } from 'contexts/OccurrencesAditionalFields';
import { BlockedTravelProvider } from 'contexts/BlockedTravelProvider';
import { OneSignalProvider } from 'contexts/OneSignal';
import { UserContextProvider } from 'contexts/UserContext';
import { ProgressBarProvider } from 'v3/components/ProgressBarContext';
import store from './store';
import Routes from './routes';
import theme from './theme';
import { NotificationContextProvider } from './v3/components/Menu/Notifications';
import GlobalStyle from './styles';
import Snackbar from './v3/components/Snackbar';
import SnackbarStacked from 'v3/components/SnackbarStacked';
import ErrorBoundary from './v3/components/ErrorBoundary';
import Alerts from './v3/pages/Alerts';
// import HelpButton from './v3/components/HelpButton';

export default function App() {
  return (
    <UserContextProvider>
      <OneSignalProvider>
        <Provider store={store}>
          <ConfigProvider
            theme={{
              token: {
                controlItemBgActive: '#c2dffc',
                controlItemBgHover: '#D8EBFF',
              },
            }}
          >
            <ThemeProvider theme={theme}>
              <div className="App h-100">
                <GlobalStyle />
                <ErrorBoundary>
                  <ProgressBarProvider>
                    <SnackbarStacked>
                      <Snackbar>
                        <Alerts />
                        <NotificationContextProvider>
                          <BlockedTravelProvider>
                            <OccurrencesAditionalFieldsProvider>
                              <Routes />
                            </OccurrencesAditionalFieldsProvider>
                          </BlockedTravelProvider>
                        </NotificationContextProvider>
                      </Snackbar>
                    </SnackbarStacked>
                  </ProgressBarProvider>
                </ErrorBoundary>
              </div>
            </ThemeProvider>
          </ConfigProvider>
        </Provider>
      </OneSignalProvider>
    </UserContextProvider>
  );
}
