import styled from 'styled-components';
import theme from 'v3/theme';

const MarkerContainer = styled.div`
  background-color: ${({ background }) =>
    theme.colors[background] || background};
  padding: 5px 10px;
  min-width: 280px;
  border-radius: 5px;

  &:after {
    content: '';
    position: absolute;
    bottom: 2px;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid
      ${({ background }) => theme.colors[background] || background};
  }
`;

export default  MarkerContainer ;
