import React from 'react';

import ConversationItem from '../conversationItem/index';

function ConversationList({
  deals,
  click,
  selected,
  selectedDealList,
}) {
  return (
    <div style={{ height: 600, overflow: 'auto' }}>
      {deals
        ? deals.map(deal => (
          <ConversationItem
            deal={deal}
            click={click}
            key={deal.id}
            active={selected.id === deal.id}
            selectedDealList={selectedDealList}
          />
        ))
        : ''}
    </div>
  );
}

export default ConversationList;
