import React, { useState, useCallback, useEffect } from 'react';

import dashboardApi from 'services/apis/dashboard';
import { Table, Text, Link, Button } from 'v3/components';
import { formatDateTime, formatPlate } from 'v3/utils/formatter';
import moment from 'moment';
import { useQuery } from 'hooks';
import Card, { Number, Description, SeeAll } from './Card';

export default function TravelDriverPending({ dashboard, name, id }) {
  const [isFetching, setFetching] = useState(false);
  const [data, setData] = useState({});
  const [filter, setFilter] = useState({});
  const [currentPage, setCurrentPage] = useState({});
  const [pagination, setPagination] = useState({});
  const [loading, setLoading] = useState(false);
  const [daysFromNow, setDaysFromNow] = useState(3);
  const queryParams = useQuery();

  useEffect(() => {
    setDaysFromNow(moment(new Date()).diff(filter.start_date, 'days'));
  }, [filter.start_date]);

  function setQueryParams(filters) {
    const { tags = [], start_date, end_date } = filters;

    queryParams.delete('tags');

    if (tags) {
      tags.forEach(tag => {
        queryParams.append('tags', tag.id);
      });
    }

    if (start_date) {
      queryParams.set('start_date', start_date);
    }
    if (end_date) {
      queryParams.set('end_date', end_date);
    }
    queryParams.set('status', 2);
    queryParams.set('pending_vehicle', 1);

    if (tags?.length === 0) {
      queryParams.set('no-tags', true);
    } else {
      queryParams.delete('no-tags');
    }
  }

  const fetchTravelDriverPending = useCallback(async (filters = {}) => {
    try {
      setLoading(true);
      const {
        tags = [],
        clients = [],
        negotiators = [],
        costCenters = [],
        recuseCostCenters = [],
        pcps = [],
        companies = [],
        page = 1,
        start_date,
        end_date,
      } = filters;

      if (page === 1) {
        setFetching(true);
      }

      setQueryParams(filters);
      setFilter(filters);
      setCurrentPage(+page);

      const response = await dashboardApi.get(
        'loads-attendeds-vehicle-pending',
        {
          params: {
            tags: tags?.map(tag => tag.id),
            clients: clients?.map(client => client.id),
            start_date,
            negotiators: negotiators?.map(negotiator => negotiator?.id),
            costCenters: costCenters?.map(costCenter => costCenter?.id),
            recuseCostCenters: recuseCostCenters?.map(costCenters => costCenters?.id),
            pcps: pcps?.map(pcp => pcp?.id),
            companies: companies?.map(company => company?.id),
            end_date: end_date || undefined,
            page,
          },
        }
      );

      setPagination(response.data?.last?.pagination);

      if (page === 1) {
        setData(response.data);
      } else {
        setData(oldData => ({
          ...oldData,
          last: {
            ...oldData.last,
            data: [...oldData.last.data, ...response.data.last.data],
          },
        }));
      }
    } catch (error) {
      // comment
    } finally {
      setFetching(false);
      setLoading(false);
    }
  }, []);

  return (
    <Card
      title="Viagens com veículo não validado"
      isLoading={isFetching}
      fetchFn={fetchTravelDriverPending}
      onFiltersChanged={fetchTravelDriverPending}
      excludeFilterByRecuseCostCenters={false}
      dashboard={dashboard}
      name={name}
      id={id}
      excludeFilterByBranchOffice={true}
      ListComponent={
        <>
          <Table striped>
            <thead>
              <tr>
                <th>
                  <Text weight="500" color="dark">
                    Centro de custo
                  </Text>
                </th>
                <th>
                  <Text weight="500" color="dark">
                    Empresa
                  </Text>
                </th>
                <th>
                  <Text weight="500" color="dark">
                    Tags da carga
                  </Text>
                </th>
                <th>
                  <Text weight="500" color="dark">
                    Viagem
                  </Text>
                </th>
                <th>
                  <Text weight="500" color="dark">
                    Veículo
                  </Text>
                </th>
                <th>
                  <Text weight="500" color="dark">
                    Carregamento
                  </Text>
                </th>
                <th>
                  <Text weight="500" color="dark">
                    Negociador
                  </Text>
                </th>
                <th>
                  <Text weight="500" color="dark">
                    Origem
                  </Text>
                </th>
                <th>
                  <Text weight="500" color="dark">
                    Destino
                  </Text>
                </th>
                <th>
                  <Text weight="500" color="dark">
                    Cliente
                  </Text>
                </th>
                <th>
                  <Text weight="500" color="dark">
                    Motorista
                  </Text>
                </th>
              </tr>
            </thead>
            <tbody>
              {data.last?.data?.map(travel => {
                const title = [
                  `Cliente ${travel.social_name} - ${travel.cgccpf}`,
                  `Motorista ${travel.driver_name}`,
                  `Placa veículo tração ${formatPlate(travel.plate)}`,
                ].join('\n');

                return (
                  <tr>
                    <td>
                      <Text type="regular" color="gray">
                        {travel?.cost_center || '-'}
                      </Text>
                    </td>
                    <td>
                      <Text type="regular" color="gray">
                        {travel?.company_name || '-'}
                      </Text>
                    </td>
                    <td>
                      <Text type="regular" color="gray">
                        {travel?.load_tags || '-'}
                      </Text>
                    </td>
                    <td>
                      <Link
                        to={`/viagens/${travel.attended_id}`}
                        title={title}
                        target="_blank"
                      >
                        {travel.attended_id}
                      </Link>
                    </td>
                    <td>
                      <Link
                        to={`/veiculos/${travel.vehicle_id}`}
                        target="_blank"
                      >
                        {travel.plate}
                      </Link>
                    </td>
                    <td>
                      <Text type="regular" color="gray">
                        {formatDateTime(travel.scheduled_origin)}
                      </Text>
                    </td>
                    <td>
                      <Text type="regular" color="gray">
                        {travel.user_created}
                      </Text>
                    </td>
                    <td>
                      <Text type="regular" color="dark" truncate>
                        {`${travel?.city_origin} - ${travel.uf_origin}`}
                      </Text>
                    </td>
                    <td>
                      <Text type="regular" color="dark" truncate>
                        {`${travel?.city_destination} - ${travel.uf_destination}`}
                      </Text>
                    </td>
                    <td>
                      <Text type="regular" color="gray" truncate>
                        {travel.social_name}
                      </Text>
                    </td>
                    <td>
                      <Text type="regular" color="gray" truncate>
                        {travel.driver_name}
                      </Text>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          {pagination?.lastPage > 1 && pagination?.to < pagination?.total && (
            <div className="text-center mt-3 col-12">
              <Button
                className="mt-2 ml-auto align-item-center"
                onClick={() => {
                  fetchTravelDriverPending({
                    page: currentPage + 1,
                    tags: filter?.tags,
                    clients: filter?.clients,
                    start_date: filter?.start_date,
                    end_date: filter?.end_date,
                    costCenters: filter?.costCenters,
                    recuseCostCenters: filter?.recuseCostCenters,
                    pcps: filter?.pcps,
                    negotiators: filter?.negotiators,
                    companies: filter?.companies,
                  });
                }}
                loading={loading}
              >
                <Text type="regular" color="white" truncate>
                  Ver mais
                </Text>
              </Button>
            </div>
          )}
          {!!data?.total && (
            <SeeAll
              to={{
                pathname: '/viagens',
                search: queryParams.toString(),
              }}
            >
              ver todas as {data?.total} viagens
            </SeeAll>
          )}
        </>
      }
      NumericComponent={
        <div className="d-flex justify-content-center text-center flex-column">
          <Number color="error">{data?.total}</Number>
          <Description>
            viagens com veículo com cadastro pendente
            {daysFromNow > 1 && daysFromNow < 61
                ? ` nos últimos ${daysFromNow} dias`
                : ` nos últimos ${filter.start_date} dias`}
          </Description>
        </div>
      }
    />
  );
}
