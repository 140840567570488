/* eslint-disable import/no-unresolved */
/* eslint-disable no-shadow */
import React from 'react';

import { Link } from 'react-router-dom';
import { Link as LinkComponent } from 'v3/components';
import { Row, Col } from 'react-bootstrap';

import {
  formatCurrency,
  formatNumber,
  makeConversion,
  conversionParams,
} from 'v3/utils/formatter';

import Text from 'v3/components/Text';
import Card, { DefaultLoadingBodyComponent } from 'v3/components/Card';

import { useTravel } from 'v3/pages/Travel';
import { formatDateTime } from '../../../../utils/formatter';

function Load({ loadDataRef }) {
  const travel = useTravel();
  const loadCurrencyInfo =
    travel?.data?.loads?.loadCurrency === 'BRL'
      ? { symbol: 'R$', name: 'Real Brasileiro', currency: 'BRL' }
      : { symbol: '$', name: 'Dólar Americano', currency: 'USD' };
  const conversion = conversionParams[loadCurrencyInfo?.symbol];

  if (travel.isLoading) {
    return null;
  }

  return (
    <Card
      ref={loadDataRef}
      loading={travel.isLoading}
      LoadingBodyComponent={() => (
        <DefaultLoadingBodyComponent linesCount={3} perLine={2} />
      )}
      header={
        <Row>
          <Col xs={12} className="mb-2">
            <Text color="dark" type="header">
              Dados da carga
            </Text>
            {travel.data?.loads?.id && (
              <Link
                to={
                  `/cargas/${travel.data?.loads?.id}`
                }
                target="_blank"
              >
                <Text className="ml-2" type="label" color="gray">
                  {travel.data?.loads?.id}
                </Text>
              </Link>
            )}

            {travel.data.externalId ? (
              <Text color="gray" type="label">
                ID externo {travel.data?.externalId}
              </Text>
            ) : null}
          </Col>
        </Row>
      }
      HeaderRightComponent={
        <Row>
          <Col xs={12} className="mt-1">
            {travel.data?.loads?.id ? (
              <>
                <Text type="regular" color="gray" as="p">
                  Criada por {travel.data?.loads?.userCreated?.username}
                  <Text type="label" color="gray" as="p">
                    Em {formatDateTime(travel?.data?.loads?.createdAt)}
                  </Text>
                </Text>
              </>
            ) : null}
          </Col>
        </Row>
      }
    >
      <Row>
        <Col md={6} xs={12}>
          <Text type="label" color="dark">
            Cliente Tomador
          </Text>
          <Link
            to={travel?.data?.loads?.taker?.id &&
              `/clientes/${travel?.data?.loads?.taker?.id}`
            } target="_blank"
          >
            <Text type="regular" color="gray" as="p" className="mt-2">
              {travel?.data?.loads?.taker?.label
                ? `${travel?.data?.loads?.taker?.label}`
                : travel?.data?.loads?.taker?.socialName?.length > 0 &&
                  travel?.data?.loads?.taker?.cpfCnpj?.length > 0
                  ? `${travel?.data?.loads?.taker?.socialName} -
                  ${travel?.data?.loads?.taker?.cpfCnpj}`
                  : 'Não informado'}
            </Text>
          </Link>
        </Col>
        {travel?.data?.policy?.operation && travel?.data?.policy?.policy && (
          <Col xs={12} style={{ marginBottom: 0 }}>
            <Text type="label" color="#464E5F" weight="500">
              Apólice - Operação
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              <LinkComponent
                target="_blank"
                rel="noopener noreferrer"
                to={`/apolices/${travel.data.policy.policy.id}`}
              >
                {`${travel.data.policy.policy.name} - ${travel.data.policy.operation.name}`}
              </LinkComponent>
            </Text>
          </Col>
        )}
        <Col md={6}>
          <Text type="label" color="dark">
            Moeda{' '}
          </Text>
          <Text type="regular" color="gray" as="p" className="mt-2">
            {loadCurrencyInfo?.name || 'Não informado'}
          </Text>
        </Col>
        <Col md={6} xs={12}>
          <Text type="label" color="dark">
            Valor da carga
          </Text>
          <Text
            type="regular"
            color="gray"
            as="p"
            className="mt-2"
            style={{ marginBottom: 0 }}
          >
            {travel.data?.loads?.cargoValue
              ? formatCurrency(
                travel.data?.loads?.cargoValue,
                loadCurrencyInfo?.currency,
                loadCurrencyInfo?.symbol
              )
              : 'Não informado'}
          </Text>
          <Text type="label" color="black" weight="bold" className="mt-2">
            {travel.data?.exchange && conversion ? (
              <>
                Convertido:{' '}
                {formatCurrency(
                  makeConversion(
                    travel.data?.loads?.cargoValue,
                    conversion?.operation,
                    travel.data?.exchange
                  ),
                  conversion?.currencyName,
                  conversion?.symbol
                )}
              </>
            ) : null}
          </Text>
        </Col>
        <Col md={6} xs={12}>
          <Text type="label" color="dark">
            Centro de custo
          </Text>
          <Link
            to={travel?.data?.loads?.costCenter?.id &&
              `/centro-de-custo/${travel?.data?.loads?.costCenter?.id}`
            } target="_blank"
          >
            <Text type="regular" color="gray" as="p" className="mt-2">
              {travel?.data?.loads?.costCenter?.name || 'Não informado'}
            </Text>
          </Link>
        </Col>
        <Col md={6} xs={12} className="mt-2">
          <Text type="label" color="dark">
            Produto
          </Text>
          <Link
            to={travel.data?.loads?.product?.id &&
              `/produtos/${travel.data?.loads?.product?.id}`
            } target="_blank"
          >
            <Text type="regular" color="gray" as="p" className="mt-2">
              {travel.data?.loads?.product?.productNcm?.code &&
                travel?.data?.loads?.productType?.name &&
                travel?.data?.loads?.product?.name
                ? `NCM ${travel.data?.loads?.product?.productNcm?.code} - ${travel?.data?.loads?.product?.name} - ${travel?.data?.loads?.productType?.name}`
                : travel?.data?.loads?.productType?.name &&
                  travel?.data?.loads?.product?.name
                  ? `${travel?.data?.loads?.product?.name} - ${travel?.data?.loads?.productType?.name}`
                  : travel.data?.loads?.product?.productNcm?.code &&
                    travel?.data?.loads?.product?.name
                    ? `NCM ${travel.data?.loads?.product?.productNcm?.code} - ${travel?.data?.loads?.product?.name}`
                    : travel?.data?.loads?.product?.name || 'Não informado'}
            </Text>
          </Link>
        </Col>
        <Col md={6} xs={12} className="mt-2">
          <Text type="label" color="dark">
            Negociação
          </Text>
          <Text type="regular" color="gray" as="p" className="mt-2">
            {travel?.data?.loads?.negotiation?.name ?? 'Não informado'}
          </Text>
        </Col>
        <Col md={6} xs={12}>
          <Text type="label" color="dark">
            Filial
          </Text>
          <Text type="regular" color="gray" as="p" className="mt-2">
            {travel.data?.loads?.branchOffice?.name ?? 'Não informado'}
          </Text>
        </Col>
        <Col md={6} xs={12}>
          <Text type="label" color="dark">
            Disposição
          </Text>
          <Text type="regular" color="gray" as="p" className="mt-2">
            {travel.data?.loads?.disposition?.name || 'Não informado'}
          </Text>
        </Col>

        <Col md={6} xs={12}>
          <Text type="label" color="dark">
            Pallets
          </Text>
          <Text type="regular" color="gray" as="p" className="mt-2">
            {travel.data?.loads?.palletNumber || 'Não paletizada'}
          </Text>
        </Col>

        <Col md={6} xs={12}>
          <Text type="label" color="dark">
            Peso
          </Text>
          <Text type="regular" color="gray" as="p" className="mt-2">
            {travel.data?.loads?.loadWeight
              ? formatNumber(travel.data?.loads?.loadWeight)
              : 'Não informado'}
          </Text>
        </Col>

        <Col md={6} xs={12}>
          <Text type="label" color="dark">
            Volume em M³
          </Text>
          <Text type="regular" color="gray" as="p" className="mt-2">
            {travel.data?.loads?.loadVolume > 0
              ? formatNumber(travel.data?.loads?.loadVolume)
              : 'Não informado'}
          </Text>
        </Col>
        <Col md={6} xs={12}>
          <Text type="label" color="dark">
            Observações
          </Text>
          <Text
            type="regular"
            color="gray"
            as="p"
            className="mt-2"
            wordwrap="wrap"
          >
            {travel.data?.loads?.comments || 'Não informado'}
          </Text>
        </Col>
        <Col md={6} xs={12}>
          <Text type="label" color="dark">
            Temperatura mínima
          </Text>
          <Text type="regular" color="gray" as="p" className="mt-2">
            {typeof travel.data.loads?.temperatureMin === 'number'
              ? `${travel.data.loads?.temperatureMin}°C`
              : 'Não informado'}
          </Text>
        </Col>
        <Col md={6} xs={12}>
          <Text type="label" color="dark">
            Temperatura máxima
          </Text>
          <Text type="regular" color="gray" as="p" className="mt-2">
            {typeof travel.data.loads?.temperature === 'number'
              ? `${travel.data.loads?.temperature}°C`
              : 'Não informado'}
          </Text>
        </Col>

        <Col md={6} xs={12}>
          <Text type="label" color="dark">
            Tags
          </Text>
          {travel.data?.loads?.tags?.length > 0 ? (
            <Text type="regular" color="gray" className="mt-2" as="p">
              {travel.data?.loads?.tags?.map(tag => tag.name).join(', ')}
            </Text>
          ) : (
            <Text type="regular" color="gray" className="mt-2" as="p">
              Não informado
            </Text>
          )}
        </Col>
        <Col md={6} xs={12}>
          <Text type="label" color="dark">
            Invoice
          </Text>
          <Text type="regular" color="gray" as="p" className="mt-2">
            {travel.data?.loads?.externalInvoiceNumber || 'Não informado'}
          </Text>
        </Col>

        <Col xs={12}>
          <Text type="label" color="dark">
            CRT
          </Text>
          <Text type="regular" color="gray" as="p" className="mt-2">
            {travel.data?.loads?.crt || 'Não informado'}
          </Text>
        </Col>
        <Col xs={12} className='mb-3'>
          <Text type="label" color="#464E5F">
            CRT Adicionais
          </Text>
          {travel.data?.loads?.crts?.map((crt) => (
            <Text type="regular" color="gray" as="p" className="mt-2">
              {crt?.customer?.label} - {crt?.number}
            </Text>
          ))}
        </Col>
        <Col xs={12} md={6}>
          <Text type="label" color="dark">
            Número do pedido do cliente
          </Text>
          <Text type="regular" color="gray" as="p" className="mt-2">
            {travel.data?.loads?.clientOrderNumber || 'Não informado'}
          </Text>
        </Col>
      </Row>
    </Card>
  );
}

export default Load;
