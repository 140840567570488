import React, { useState, useEffect, useCallback } from 'react';
import { SnackbarStackedContext } from './controller';
import * as Styled from './styles'
const [SnackbarProvider, useSnackbarStacked] = SnackbarStackedContext;

export default function SnackbarStacked({ children }) {
  const [snackbars, setSnackbars] = useState([]);

  const show = useCallback((_content, options = {}) => {
    const newSnackbar = {
      id: Date.now(),
      content: _content,
      duration: options.duration || 3000,
      type: options.type || 'primary',
      showClose: options.showClose || false,
    };

    setSnackbars((prev) => [...prev, newSnackbar]);
  }, []);

  useEffect(() => {
    const timers = snackbars.map((snackbar) =>
      setTimeout(() => {
        setSnackbars((prev) => prev.filter((s) => s.id !== snackbar.id));
      }, snackbar.duration)
    );

    return () => timers.forEach(clearTimeout);
  }, [snackbars]);

  function onClose(id) {
    setSnackbars((prev) => prev.filter((s) => s.id !== id));
  }

  return (
    <SnackbarProvider value={{ show }}>
      <div
        style={{
          position: 'fixed',
          left: '50%',
          zIndex: 9999,
        }}
      >
        {snackbars.map((snackbar, index) => (
          <Styled.Div
            key={snackbar.id}
            type={snackbar.type}
            isVisible={true}
            topOffset={`${index * 80}px`}
          >
            {snackbar.showClose && (
              <Styled.Close onClick={() => onClose(snackbar.id)}>&times;</Styled.Close>
            )}
            <Styled.Content>{snackbar.content}</Styled.Content>
          </Styled.Div>
        ))}
      </div>
      {children}
    </SnackbarProvider>
  );
}

export { useSnackbarStacked };
