/* eslint-disable import/no-unresolved */
import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Col } from 'react-bootstrap';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { formatDateTime } from 'v3/utils/formatter';
import { FaSearch, FaDownload, FaTrash } from 'react-icons/fa';
import { cooperplaceApi } from 'services/api';
import Fallback from 'v3/components/Fallback';
import { Text, Button, Modal } from 'v3/components';
import PDFView from 'v3/components/PDFView';
import StringBuilder from 'utils/StringBuilder';
import theme from 'v3/theme';
import { useSnackbar } from 'v3/components/Snackbar';
import {
  AttachmentDetail,
  AttachmentDetailsContainer,
  AttachmentsHeader,
  Backdrop,
  DateTime,
  SectionContainer,
} from '../styles';
import { OccurrenceDescriptionsContainer } from '../OccurrenceInfo/styles';
import { OccurrenceAttachmentModal } from '../AttachmentsModal';

export default function ShowOccurrenceAttachments({
  occurrenceId,
  handleAddAttchmentsDirectly,
  isOccurrenceConcluded,
  occurrenceIsAnnulleed
}) {
  const [attachments, setAttachments] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const fetchAttachments = useCallback(async () => {
    try {
      setLoading(true);
      const response = await cooperplaceApi.get(
        `occurrence-media/${occurrenceId}`
      );
      setAttachments(response.data);
    } catch (error) {
      //
    } finally {
      setLoading(false);
    }
  }, [occurrenceId]);

  useEffect(() => {
    fetchAttachments();
  }, [occurrenceId]);

  return (
    <>
      <OccurrenceAttachmentModal
        isOpen={showModal}
        handleClose={() => setShowModal(false)}
        onSendDirectly={handleAddAttchmentsDirectly}
        sendDirectly={true}
      />
      <SectionContainer>
        <AttachmentsHeader>
          <Text type="header" color="dark" weight={500}>
            Anexos
          </Text>
          {isOccurrenceConcluded === false && 
            !occurrenceIsAnnulleed && (
            <Button size="sm" onClick={() => setShowModal(true)}>
              Adicionar anexo
            </Button>
          )}
        </AttachmentsHeader>
        <Fallback
          component={
            <Col xs={12} className="align-items-center d-flex flex-column my-2">
              <Text color={theme.colors.gray}>
                Não há anexos cadastrados para essa ocorrência.
              </Text>
            </Col>
          }
          fall={!attachments?.length}
        >
          <OccurrenceDescriptionsContainer>
            <AttachmentDetailsContainer>
              {attachments?.map(attachment => {
                return (
                  <AttachmentDetail>
                    <OccurrenceAttachment
                      attachment={attachment}
                      attachments={attachments}
                      occurrenceId={occurrenceId}
                      refetchAttachments={fetchAttachments}
                    />
                    <Text
                      type="regular"
                      className="mt-2"
                      style={{ 'max-width': '75%', 'word-wrap': 'break-word' }}
                    >
                      {attachment?.media?.description ||
                        attachment?.media?.name}
                    </Text>
                  </AttachmentDetail>
                );
              })}
            </AttachmentDetailsContainer>
          </OccurrenceDescriptionsContainer>
        </Fallback>
      </SectionContainer>
    </>
  );
}

export function OccurrenceAttachment({
  attachment,
  attachments,
  occurrenceId,
  refetchAttachments,
  hasPermissionToRemove = true,
}) {
  const customReactModalStyle = {
    overlay: {
      zIndex: 2000,
    },
  };
  const [isHovering, setHovering] = useState(false);
  const [isShowingModal, setShowingModal] = useState(false);
  const snackbar = useSnackbar();
  const [loading, setLoading] = useState(false);

  const attachmentsWithoutPDF = useMemo(() => {
    return attachments?.filter(item => {
      if (item.media) {
        const filterPDF = item?.media?.name?.split('.');
        if (filterPDF[filterPDF?.length - 1] === 'pdf') return null;
        return item;
      }
    });
  }, [attachments]);

  const [lightbox, setLightbox] = useState({
    isOpen: false,
    attachments: attachmentsWithoutPDF,
    index: 0,
  });

  async function removeAttachment(attachmentId) {
    try {
      setLoading(true);
      await cooperplaceApi.delete(
        `occurrence-media/${occurrenceId}/${attachmentId}`
      );
      await refetchAttachments();
    } catch (error) {
      snackbar.show('Não foi possível excluir o anexo', { type: 'error' });
    } finally {
      setLoading(false);
    }
  }

  const lightboxIndexes = useMemo(() => {
    const next = (lightbox.index + 1) % lightbox.attachments?.length;
    const previous =
      (lightbox.index + lightbox.attachments.length - 1) %
      lightbox.attachments.length;
    return { next, previous };
  }, [lightbox.index, lightbox.attachments]);

  function onClickPreview(id) {
    const index = id ? attachmentsWithoutPDF.findIndex(a => a.id === id) : 0;

    setLightbox(prev => ({
      ...prev,
      index,
      attachments: attachmentsWithoutPDF,
      isOpen: true,
    }));
  }

  function handleClick() {
    const fullPathArray = attachment?.media?.name?.split('.');

    if (fullPathArray[fullPathArray?.length - 1] === 'pdf') {
      setShowingModal(true);
    } else {
      onClickPreview(attachment.id);
    }
  }

  function getImagePath(index, alwaysDisplay) {
    const isSameAsIndex =
      lightbox.index === lightboxIndexes.next &&
      lightbox.index === lightboxIndexes.previous;

    if (!alwaysDisplay && isSameAsIndex && lightbox.attachments.length === 1) {
      return null;
    }
    const processedStartPath = process.env.REACT_APP_BASE_STORAGE_URL;
    return `${processedStartPath}${lightbox.attachments[index]?.media?.container}/${lightbox.attachments[index]?.media?.path}/${lightbox.attachments[index]?.media?.name}`;
  }

  function getDocumentMetadata() {
    const document = lightbox.attachments[lightbox.index];
    const type = document?.description || 'Sem descrição';
    const createdAt = formatDateTime(document?.createdAt);

    return { type, createdAt };
  }

  const lightboxTitle = useMemo(() => {
    const builder = new StringBuilder(' ');
    const documentMetadata = getDocumentMetadata();
    builder
      .append(`${lightbox.index + 1}/${lightbox.attachments?.length}`)
      .append('|')
      .append(documentMetadata.type)
      .append(`(${documentMetadata.createdAt})`);

    return builder.build();
  }, [lightbox.index, lightbox.attachments]);

  return (
    <div>
      <Modal
        show={isShowingModal}
        onHide={() => setShowingModal(false)}
        heading="Visualizar PDF"
        body={
          <PDFView
            file={`${process.env.REACT_APP_BASE_STORAGE_URL}${attachment?.media?.container}/${attachment?.media?.path}/${attachment?.media?.name}`}
          />
        }
      />
      <div
        className="position-relative"
        onMouseEnter={() => setHovering(true)}
        onMouseLeave={() => setHovering(false)}
        style={{
          width: 240,
          height: 120,
          background: `url(${
            attachment?.media?.name?.split('.').pop() === 'pdf'
              ? '/images/default-doc.png'
              : attachment?.media?.name?.split('.').pop() === 'xlsx'
              ? '/images/default-excel.png'
              : attachment?.media?.name?.split('.').pop() === 'xls'
              ? '/images/default-excel.png'
              : `${process.env.REACT_APP_BASE_STORAGE_URL}${attachment?.media?.container}/${attachment?.media?.path}/${attachment?.media?.name}`
          }) center center no-repeat`,
          backgroundSize: 'cover,contain',
        }}
      >
        {lightbox.isOpen && (
          <Lightbox
            mainSrc={getImagePath(lightbox.index, true)}
            nextSrc={getImagePath(lightboxIndexes.next)}
            prevSrc={getImagePath(lightboxIndexes.previous)}
            onCloseRequest={() =>
              setLightbox(prev => ({ ...prev, isOpen: false }))
            }
            onMovePrevRequest={() =>
              setLightbox(prev => ({
                ...prev,
                index: lightboxIndexes.previous,
              }))
            }
            onMoveNextRequest={() =>
              setLightbox(prev => ({
                ...prev,
                index: lightboxIndexes.next,
              }))
            }
            imageTitle={lightboxTitle}
            reactModalStyle={customReactModalStyle}
          />
        )}
        {isHovering && (
          <Backdrop>
            {attachment?.media?.name?.split('.').pop() !== 'xlsx' &&
              attachment?.media?.name?.split('.').pop() !== 'xls' && (
                <button type="button" onClick={handleClick}>
                  <FaSearch color="#fff" size={32} />
                </button>
              )}
            <a
              href={`${process.env.REACT_APP_BASE_STORAGE_URL}${attachment?.media?.container}/${attachment?.media?.path}/${attachment?.media?.name}`}
              download
            >
              <FaDownload color="#fff" size={32} />
            </a>
            {hasPermissionToRemove && (
              <Button
                variant="transparent"
                loading={loading}
                size={0}
                onClick={() => removeAttachment(attachment?.attachmentId)}
              >
                <FaTrash size={32} />
              </Button>
            )}
          </Backdrop>
        )}

        <DateTime>
          <Text type="label" color="white">
            {formatDateTime(attachment.createdAt)}
          </Text>
        </DateTime>
      </div>
    </div>
  );
}
