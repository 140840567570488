import styled from 'styled-components';

import { Card as MaterialCard } from '@material-ui/core';

import theme from 'v3/theme';

import Button from 'components/Button/styles';
import Label from 'components/Label';

export const Container = styled.div`
  background-color: ${theme.colors.dark_blue};

  height: 100vh;

  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

export const Card = styled(MaterialCard)`
  position: relative;
  && {
    box-shadow: ${({ theme }) => theme.shadow};
  }

  margin: 20px;
  padding: 0 35px 60px 35px;

  ${Button} {
    margin-top: 30px;
  }

  ${Label} {
    text-transform: none;
  }
`;

export const BackWrapper = styled.div`
  position: absolute;
  top: 20px;
  margin-right: 20px;
`;

export const Logo = styled.img`
  margin: 10px;
  padding-top: 30px;
  width: 350px;

  @media (max-width: 768px) {
    width: 80%;
  }
`;

export const Line = styled.div`
  width: 65px;
  height: 2px;

  background-color: ${theme.colors.primary};

  margin-bottom: 30px;
`;

export const ForgotPassword = styled.button`
  background-color: transparent;

  color: ${({ theme }) => theme.colors.secondary};
  font-size: 0.9rem;
  text-transform: uppercase;
  cursor: pointer;

  margin-top: 20px;
`;

export const GoBack = styled.button`
  background-color: transparent;

  color: ${({ theme }) => theme.colors.secondary};
  font-size: 0.9rem;
  text-transform: uppercase;
  cursor: pointer;
  margin-top: 15px;
`;

export const Version = styled.span`
  position: absolute;
  left: 20px;
  bottom: 2px;

  color: #fff;
  font-size: 0.9rem;
  text-transform: uppercase;
`;
