import React from 'react'
import Text from 'v3/components/Text';
import moment from 'moment';

export default function DealStatus({ deal }) {

  const DealStatus = ({ data }) => {
    if (!data?.closed && !data?.negotiator_id) {
      return (
        <>
          <Text
            color={'yellow_rating_star'}
            fontSize={'12px'}
            weight={600}>NÃO ATENDIDA - {moment(data?.created_at).format('DD/MM/YYYY HH:MM')}
          </Text>
        </>
      )
    } else if (data?.closed) {
      if (data?.travel_id) {

        if (data?.isAttendedInDeal) {
          return (
            <>
              <Text
                color={'success'}
                fontSize={'12px'}
                weight={600}> ENCERRADA - ATENDIDA - {data?.travel_created_at && moment(data?.travel_created_at).format('DD/MM/YYYY HH:MM') || null}
              </Text>
            </>
          )
        } else {
          return (
            <>
              <Text
                color={'error'}
                fontSize={'12px'}
                weight={600}> ENCERRADA - NÃO ATENDIDA  -  {data?.closed_at && moment(data?.closed_at).format('DD/MM/YYYY HH:MM') || null}
              </Text>
            </>
          )
        }
      } else {
        return (
          <>
            <Text
              color={'error'}
              fontSize={'12px'}
              weight={600}> ENCERRADA - NÃO ATENDIDA  -  {data?.closed_at && moment(data?.closed_at).format('DD/MM/YYYY HH:MM') || null}
            </Text>
          </>
        )
      }
    } else if (!data?.closed && data?.negotiator_id) {
      return (
        <>
          <Text
            color={'success'}
            fontSize={'12px'}
            weight={600}> EM ATENDIMENTO - {data?.initial_deal_at && moment(data?.initial_deal_at).format('DD/MM/YYYY HH:MM') || null}
          </Text>
        </>
      )
    }
    return null
  }

  return (
    <DealStatus data={deal} />
  )
}
