import React, { useState } from 'react';
import { Button, Card, Text } from 'v3/components';
import { LoadDetails } from './LoadDetails';
import Space from 'v3/components/Space';
import { Inputs } from './Inputs';
import { PreLoadDataSchema } from '../../validator';
import { useSnackbar } from 'v3/components/Snackbar';
import { checkClientCreditLimit } from 'v3/pages/Load/Register/controller';
import { cooperplaceApi } from 'services/api';
import { convertNumberToDatabase } from 'v3/utils/formatter';

export function Data({
  preLoad,
  preLoadCurrency,
  hasPermissionToEdit,
  getPreLoad,
  loading,
  shouldDisableEditing,
  disabledTitle,
}) {
  const [editing, setEditing] = useState(false);
  const [errors, setErrors] = useState({});
  const [data, setData] = useState();
  const [submiting, setSubmiting] = useState(false);
  const snackbar = useSnackbar();

  async function validate(dataToValidate, setErrors) {
    const errorList = {};
    try {
      await PreLoadDataSchema.validate(dataToValidate, { abortEarly: false });
      setErrors({ ...errorList });
      return true;
    } catch (err) {
      if (err.inner) {
        err.inner.forEach(error => {
          errorList[error.path] = error.message;
        });

        setErrors({ ...errorList });
        return false;
      }
    }
  }

  async function handleSubmit() {
    setSubmiting(true);
    if (!(await validate(data, setErrors))) {
      snackbar.show(
        <Text type="body" weight={500}>
          Verifique os campos obrigatórios!
        </Text>,
        {
          type: 'error',
        }
      );
      setSubmiting(false);
      return false;
    }
    if (
      !(await checkClientCreditLimit(
        data.taker.id,
        +data?.takerValue,
        setErrors
      ))
    ) {
      snackbar.show(
        <Text type="body" weight={500}>
          Cliente sem limite de crédito! Não é possível cadastrar pre carga para
          tomadores sem limite de crédito!
        </Text>,
        { type: 'error' }
      );
      setSubmiting(false);
      return false;
    }

    const payload = {
      personClientId: data.taker.id,
      productId: data.product.id,
      branchOfficeId: data.branchOffice?.id,
      costCenterId: data.costCenter?.id,
      takerValue: +data.takerValue,
      currency: data.currency?.value || 'BRL',
      dueDate: data.dueDate,
      dispositionId: preLoad?.disposition?.id,
      loadWeight: +convertNumberToDatabase(preLoad?.loadWeight),
      vehicleTypeIds: preLoad?.vehicleTypes?.map(type => type.id),
      vehicleImplementTypeIds: preLoad?.vehicleImplementTypes?.map(
        implement => implement?.id
      ),
      vehicleBodyTypeIds: preLoad?.vehicleBodyTypes?.map(body => body.id),
      clientOrderNumber: data?.clientOrderNumber || null,
      loadPublish: data?.loadPublish,
      comments: data?.observations,
      externalInvoiceNumber: data?.externalInvoiceNumber || null,
      searchDriver: data?.searchDriver ? 1 : 0,
      searchDriverRadius: data?.searchDriverRadius,
    };

    try {
      await cooperplaceApi.put(`pre-loads/${preLoad?.id}`, payload);
      await getPreLoad();

      setSubmiting(false);
      setEditing(false);
    } catch (error) {
      setSubmiting(false);
      const message = error?.response?.data?.message || 'Erro ao atualizar pré carga!';
      snackbar.show(
        <Text type="body" weight={500}>
          {message}
        </Text>,
        { type: 'error' }
      );
    }
  }

  return (
    <Card
      header={
        <Text color="#464E5F" type="header">
          Dados da Carga
        </Text>
      }
      loading={submiting || loading}
      HeaderRightComponent={
        hasPermissionToEdit && (
          <Space x={'0.5rem'}>
            {editing && (
              <Button
                onClick={() => setEditing(false)}
                variant="secondary"
                loading={loading}
                className="mr-2"
              >
                <Text color="dark" type="regular">
                  Cancelar
                </Text>
              </Button>
            )}
            <Button
              onClick={editing ? () => handleSubmit() : () => setEditing(true)}
              variant={editing ? 'primary' : 'secondary'}
              disabled={shouldDisableEditing}
              loading={submiting}
              title={shouldDisableEditing ? disabledTitle : ''}
            >
              <Text color={editing ? 'white' : 'dark'} type="regular">
                {editing ? 'Salvar' : 'Editar'}
              </Text>
            </Button>
          </Space>
        )
      }
    >
      {editing ? (
        <Inputs
          data={data}
          setData={setData}
          preLoad={preLoad}
          preLoadCurrency={preLoadCurrency}
          loading={loading}
          errors={errors}
          setErrors={setErrors}
          snackbar={snackbar}
        />
      ) : (
        <LoadDetails
          preLoad={preLoad}
          preLoadCurrency={preLoadCurrency}
          hasPermissionToEdit={hasPermissionToEdit}
        />
      )}
    </Card>
  );
}
