import api from 'services/api';

export async function save({ password, verified, driver, phone, name, email, active }) {
  const data = {
    password,
    verified,
    phone,
    name,
    email,
    active,
  };

  return api.put(`driver/updateUserData/${driver.cgccpf}`, data);
}

function verifyPhonenumber(phone) {
  const normalizePhone = phone.replace(/[^0-9]/g, '');
  const phoneRegex = /^[0-9]{10,14}$/;
  return phoneRegex.test(normalizePhone);
}

function validarEmail(email) {
  const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return regex.test(email);
}

export function validate({ password, confirmPassword, isEditingPassword, email, confirmEditingEmail, isEditingEmail,  phonenumber, isEditingPhonenumber, setErrors }) {
  const err = {};

  if (isEditingPassword && !password) {;
    err.password = 'Difite uma senha';
    err.hasErrors = true;
  }

  if (isEditingPassword && !confirmPassword) {;
    err.confirmPassword = 'Digite a confirmação de senha';
    err.hasErrors = true;
  }

  if (isEditingPassword && password !== confirmPassword) {
    err.confirmPassword = 'A senha de confirmação está diferente da senha informada';
    err.hasErrors = true;
  }

  if(isEditingEmail && email){
    const verifyEmail = validarEmail(email)
    if(!verifyEmail){
      err.email = 'E-mail inválido';
      err.hasErrors = true;
    }
  }

  if (isEditingEmail && !email) {;
    err.email = 'Digite um e-mail';
    err.hasErrors = true;
  }

  if (isEditingEmail && !confirmEditingEmail) {;
    err.confirmEditingEmail = 'Digite o e=mail de confirmação';
    err.hasErrors = true;
  }

  if (isEditingEmail && email !== confirmEditingEmail) {
    err.confirmEditingEmail = 'O e-mail de confirmação está diferente do e-mail informado';
    err.hasErrors = true;
  }

  if(isEditingPhonenumber && phonenumber){
    const verifyNumber = verifyPhonenumber(phonenumber)
    if(!verifyNumber){
      err.phonenumber = 'Numero de telefone inválido';
      err.hasErrors = true;
    }
  }

  if(isEditingPhonenumber){
    const verifyNumber = verifyPhonenumber(phonenumber)
    if(!verifyNumber){
      err.phonenumber = 'Digite um numero de telefone';
      err.hasErrors = true;
    }
  }


  setErrors(err);
  return !Object.keys(err).length;
}
