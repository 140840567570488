import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useContext,
  useMemo,
} from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import { FaExclamationCircle } from 'react-icons/fa';
import { groupBy } from 'v3/utils/array';
import { dataTravel, validatePermission } from 'actions/index';
import api, { cooperplaceApi } from 'services/api';
import ApplicationLayout from 'v3/layouts/Application';
import Card, { DefaultLoadingBodyComponent } from 'v3/components/Card';
import Text from 'v3/components/Text';
import Button from 'v3/components/Button';
import { useModal, usePermission } from 'hooks';
import { formatDate } from 'utils/formatter';
import { useSnackbar } from 'v3/components/Snackbar';
import { BlockedTravelContext } from 'contexts/BlockedTravelProvider';
import moment from 'moment';
import { UserContext } from 'contexts/UserContext';
import { UNDER_GRO_ANALYSIS_SLUG } from 'v3/utils/constants';
import Header from './Header';
import MapTravel from './Map';
import Anchor from './Anchor';
import Documents from './Documents';
import Feed from './Feed';
import Attachments from './Attachments';
import DriverJourney from './DriverJourney';
import { ChangeRequest } from './ChangeRequest';
import { TravelProvider } from '..';
import * as Shared from '../shared';
import CteModal from './Modals/Cte';
import IssuingModal from './Modals/Issuing';
import { TravelShippingCompanyAttachments } from './TravelShippingCompany';
import { TravelVehicleAttachments } from './TravelVehicle';
import { TravelDriverAttachments } from '../DriverAttachment';
import { TravelOccurrences } from './Occurrences';
import { FreightContracts } from './FreightContracts';
import TravelChecklist from './Checklist';
import CheckListDetailModal from './Checklist/CheckListDetailModal';

export default function TravelDetails() {
  usePermission('VISUALIZAR_VIAGEM_DETALHES', { redirect: true });
  const { setEnabledToSelect, changedCompany } = useContext(UserContext);
  const {
    setLastMarginLiberation,
    setMargins,
    fetchBusinessMarginsValues,
    shippingServiceLevelMapped,
    isMarginAbove,
    setItensToFetchMargin,
  } = useContext(BlockedTravelContext);
  const history = useHistory();
  const params = useParams();
  const dispatch = useDispatch();
  const [data, setData] = useState({});
  const [isLoading, setLoading] = useState(true);
  const [isUnauthorized, setUnauthorized] = useState({
    unauthorized: false,
    message: '',
  });
  const [statuses, setStatuses] = useState([]);
  const [canUpdate, setCanUpdate] = useState(false);
  const [prevTravel, setPrevTravel] = useState([]);
  const [preSM, setPreSM] = useState(null);
  const [travelOccurrences, setTravelOccurrences] = useState([]);
  const [feed, setFeed] = useState([]);
  const [notFoundTravel, setNotFoundTravel] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [checklists, setChecklists] = useState([]);
  const [travelFreightContracts, setTravelFreightContracts] = useState([]);
  const [groAnalysisStatus, setGroAnalysisStatus] = useState(null);
  const [transhipmentChecklist, setTranshipmentChecklist] = useState(null);
  const [status, setStatus] = useState({});
  const [prevStatus, setPrevStatus] = useState({});
  const [steps, setSteps] = useState([]);
  const mapCard = useRef(null);
  const travelDetailsCard = useRef(null);
  const loadDataCard = useRef(null);
  const travelDocumentsCard = useRef(null);
  const valuesCard = useRef(null);
  const bennerCard = useRef(null);
  const windowRef = useRef(null);
  const mercosulStatusCard = useRef(null);
  usePermission('VISUALIZAR_VIAGENS', { redirect: true });
  usePermission('VISUALIZAR_VIAGEM_DETALHES', { redirect: true });
  const snackbar = useSnackbar();

  const issuingModal = useModal();
  const cteModal = useModal();
  const transhipmentChecklistModal = useModal();

  const hasPermissionToEditGROAnalysis = validatePermission(
    'EDITAR_CAMPOS_EM_VALIDACAO_GR'
  );
  const isDisabledGROAnalysis = useMemo(() => {
    if (!data?.policy) {
      return false;
    }

    if (hasPermissionToEditGROAnalysis) {
      return false;
    }

    return groAnalysisStatus === UNDER_GRO_ANALYSIS_SLUG;
  }, [groAnalysisStatus]);

  async function fetchFeed() {
    try {
      const response = await api.get('travel-logs', {
        params: { attended_id: params.id },
      });

      const grouped = groupBy(response.data, item => formatDate(item.log_time));
      setFeed(grouped);
      dispatch(dataTravel(response.data));
    } catch (ex) {
      // Handle exception
    }
  }

  async function fetchTravel() {
    try {
      setLoading(true);
      const response = await cooperplaceApi.get(`/travels/travel/${params.id}`);
      const travel = response.data;

      const [marginValue, advanceValue] = await shippingServiceLevelMapped(
        travel?.shipper?.shippingCompany?.descriptionServiceLevel
      );

      const itsOwnVehicle =
        travel?.vehicleAttended?.vehicle?.fleetType?.name === 'Próprio';

      setMargins({
        advance_money_percentage_external: itsOwnVehicle
          ? 0
          : travel?.loads?.advanceMoneyPercentage || advanceValue,
        benner_margin: itsOwnVehicle ? 0 : travel?.benner_margin || marginValue,
      });
      if (travel.travelMarginLiberation) {
        const [lastMarginLiberation] = travel?.travelMarginLiberation;
        setLastMarginLiberation(lastMarginLiberation || null);
      }
      setData(travel);
      setPrevTravel(travel);
      setGroAnalysisStatus(travel?.riskApproved);
      await fetchBusinessMarginsValues(travel?.costCenterData?.businessId);
      setItensToFetchMargin(prevState => ({
        ...prevState,
        loadId: travel?.loads?.id,
        mainVehicle: travel?.vehicle[0]?.vehicles,
        origin: travel?.loads?.loadOrigins[0],
        destinations: travel?.loads?.loadDestinations,
        shippingCompany: travel?.shipper?.shippingCompany,
        actualMargin: travel?.margin,
        clientId: travel?.loads?.taker?.id,
        costCenterId: travel?.loads?.costCenter?.externalId,
      }));
    } catch (ex) {
      if (ex.response?.status === 401) {
        setUnauthorized({
          unauthorized: true,
          message: ex?.response?.data?.message || '',
        });
      }

      if (ex.response?.status === 404) {
        setNotFoundTravel(true);
      }
    } finally {
      setLoading(false);
      fetchFeed();
    }
  }

  async function fetchPreSM() {
    try {
      const response = await cooperplaceApi.get(`presm/${params.id}`);
      setPreSM(response.data);
    } catch (err) {
      setPreSM(null);
    }
  }
  async function retrieveOccurrences() {
    try {
      const response = await cooperplaceApi.get(
        `occurrences/logs/${params.id}`
      );
      setTravelOccurrences(response.data.message);
    } catch (error) {
      setTravelOccurrences([]);
    }
  }

  async function fetchTravelChecklists() {
    try {
      setIsFetching(true);
      const response = await cooperplaceApi.get(
        `travel-checklist/travel/${params.id}`
      );
      if (response?.data?.data) {
        setChecklists(response.data.data);
      }
      await retrieveOccurrences();
    } catch (error) {
      snackbar.show(<Text>Erro ao buscar checklists da viagem</Text>, {
        type: 'error',
      });
    } finally {
      setIsFetching(false);
    }
  }

  const getTravelFreightContracts = useCallback(async () => {
    try {
      const travelId = params?.id;
      if (travelId) {
        const response = await cooperplaceApi.get(
          `freight/byTravelId/${params?.id}`
        );
        setTravelFreightContracts(response.data);
      }
    } catch (error) {
      //
    }
  }, [params]);

  function handleCancel() {
    setData(prevTravel);
  }

  function openIssuingModal() {
    const canIssue = localStorage.getItem('issue_permission');

    if (canIssue === 'true') {
      issuingModal.open();
    } else {
      cteModal.open();
    }
  }

  async function fetchSteps() {
    const response = await api.get(`travel-status/steps/${params.id}`);
    setSteps(response.data);
  }

  async function changeStatus() {
    const IMPORT_PAYMENTS_VALID_TRAVEL_STATUS = [3, 5]; // em viagem e em análise
    try {
      await cooperplaceApi.put(`travels/${data.id}/status`, {
        statusId: status.id,
      });

      if (IMPORT_PAYMENTS_VALID_TRAVEL_STATUS.includes(status.id)) {
        await cooperplaceApi.post(`payments/importFromBenner/${data.id}`);
      }

      const EM_VIAGEM = 3;
      if (status.id === EM_VIAGEM) {
        await cooperplaceApi.post(`freight/importContracts/${data.id}`, {
          travelNumber: data?.load?.id_externo,
        });
      }

      await fetchSteps();
      fetchPreSM();
      fetchFeed();
      snackbar.show(<Text>Você alterou o status da viagem com sucesso!</Text>, {
        type: 'success',
      });
      await fetchTravel();
    } catch (error) {
      let message = 'Não foi possível alterar o status';
      message = error.response.data;
      if (
        typeof error?.response?.data === 'object' &&
        typeof error?.response?.data?.message === 'string'
      ) {
        message = error.response.data.message;
      }
      snackbar.show(<Text>{message}</Text>, {
        type: 'error',
      });
      setStatus(prevStatus);
    }
  }

  useEffect(() => {
    async function fetchTravelStatuses() {
      try {
        const response = await api.get('travel-status');
        setStatuses(response.data.filter(item => item.code !== 'ST4'));
      } catch (error) {
        // Handle exception
      }
    }

    fetchTravelStatuses();
  }, []);

  useEffect(() => {
    setUnauthorized({
      unauthorized: false,
      message: '',
    });
  }, [changedCompany]);

  useEffect(() => {
    fetchTravel();
    fetchPreSM();
  }, [params.id, changedCompany]);

  useEffect(() => {
    if (canUpdate) {
      fetchTravel();
      setCanUpdate(false);
    }
  }, [canUpdate]);

  useEffect(() => {
    if (params?.id) {
      getTravelFreightContracts();
      fetchTravelChecklists();
    }
  }, [params]);

  function handleIssueCte() {
    issuingModal.close();

    window.open(
      `${process.env.REACT_APP_ISSUING_FRONTEND_URL}auth/${localStorage.getItem(
        'token'
      )}?redirect_to=/emitir/${data.id}`
    );
  }

  if (notFoundTravel) {
    return (
      <ApplicationLayout>
        <Card>
          <div
            style={{ display: 'flex', flexDirection: 'column', gap: 6 }}
            className="text-center"
          >
            <Text type="header" color="error">
              <FaExclamationCircle
                size={26}
                color="#F64E60"
                className="mr-2 mb-1"
              />
              Essa viagem não existe
            </Text>
          </div>
        </Card>
      </ApplicationLayout>
    );
  }

  if (isUnauthorized.unauthorized) {
    setEnabledToSelect(true);
    return (
      <ApplicationLayout>
        <Card>
          <div
            style={{ display: 'flex', flexDirection: 'column', gap: 6 }}
            className="text-center"
          >
            <Text type="header" color="error">
              <FaExclamationCircle
                size={26}
                color="#F64E60"
                className="mr-2 mb-1"
              />
              Você não tem permissão para acessar essa viagem!
            </Text>
            <Text type="header" color="error">
              {isUnauthorized.message}
            </Text>
          </div>
        </Card>
      </ApplicationLayout>
    );
  }
  return (
    <TravelProvider
      value={{
        data,
        preSM,
        fetchPreSM,
        setData,
        isLoading,
        fetchTravel,
        statuses,
        openIssuingModal,
        cteModal,
        handleIssueCte,
        handleCancel,
        travelOccurrences,
        retrieveOccurrences,
        travelFreightContracts,
        setTravelFreightContracts,
        getTravelFreightContracts,
        feed,
        fetchFeed,
        fetchTravelChecklists,
        setIsFetching,
        isFetching,
        checklists,
        setTranshipmentChecklist,
        transhipmentChecklistModal,
        status: {
          changeStatus,
          setStatus,
          prevStatus,
          setPrevStatus,
          status,
          fetchSteps,
          steps,
        },
        isMarginAbove,
        groAnalysisStatus,
        isDisabledGROAnalysis,
      }}
    >
      <IssuingModal
        open={issuingModal.isOpen}
        handleHide={() => {
          issuingModal.close();
        }}
        handleFillCte={() => {
          issuingModal.close();
          cteModal.open();
        }}
        handleIssueCte={handleIssueCte}
      />
      <CteModal
        showModal={cteModal.isOpen}
        handleClose={() => cteModal.close()}
        status={data?.travelStatus}
      />
      {transhipmentChecklist?.id && (
        <CheckListDetailModal
          checklist={transhipmentChecklist}
          modalHook={transhipmentChecklistModal}
          travelData={data}
          fetchTravelChecklists={fetchTravelChecklists}
          onConfirmResponse={changeStatus}
          handleClose={() => setStatus(prevStatus)}
          fetchFeed={fetchFeed}
        />
      )}
      <ApplicationLayout
        title="Detalhes da viagem"
        RightComponent={
          <>
            <Button
              variant="secondary"
              onClick={() => history.goBack()}
              className="ml-2 py-2"
            >
              <Text weight={500} type="regular">
                Voltar
              </Text>
            </Button>
            {(![
              'ST2',
              'BLOCKED',
              'ST7'].includes(data?.travelStatus?.code) || data?.loads?.costCenter?.showLoadingOrder) && <Shared.PDF />}
          </>
        }
      >
        <Anchor
          window={windowRef}
          map={mapCard}
          travelDetails={travelDetailsCard}
          loadData={loadDataCard}
          travelDocuments={travelDocumentsCard}
          values={valuesCard}
          benner={bennerCard}
          mercosul={mercosulStatusCard}
        />

        <Card
          ref={mapCard}
          loading={isLoading}
          LoadingBodyComponent={() => (
            <DefaultLoadingBodyComponent linesCount={7} perLine={2} />
          )}
          header={
            <>
              <Text type="header" color="dark">
                Viagem
                {data.travelNumber && ` - ${data.travelNumber}`}
              </Text>
              <Text type="label" color="gray">
                Atendido por {data?.user?.username} -{' '}
                {moment.utc(data?.createdAt).format('DD/MM/YYYY')}
              </Text>
            </>
          }
        >
          <Header />
          <MapTravel />
        </Card>
        <Shared.Locations travelDetailsRef={travelDetailsCard} />
        <Row>
          <Col md={6} xs={12}>
            <Shared.Load loadDataRef={loadDataCard} />
            <Shared.MercosulStatus mercosulRef={mercosulStatusCard} />
            <Shared.BennerStatus bennerRef={bennerCard} />
            <Shared.Negotiator />
            <TravelOccurrences />
            <Shared.Approved />
          </Col>

          <Col md={6} xs={12}>
            <Shared.VehicleAndDriver />
            <Shared.Data />
            <Shared.SalesApproval />
            <TravelChecklist />
            <Shared.PreSMStatus />
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Shared.LoadFreight valuesRef={valuesCard} />
          </Col>
          <Col xs={12}>
            <Shared.PolicyRiskManagerCard />
          </Col>
          <Col xs={12}>
            <ChangeRequest />
          </Col>
          <Col xs={12}>
            <FreightContracts />
          </Col>
          <Col xs={12}>
            <TravelVehicleAttachments />
            <TravelShippingCompanyAttachments />
            <TravelDriverAttachments />
          </Col>
          <Col xs={12}>
            <Attachments />
            <Documents
              travelDocumentsRef={travelDocumentsCard}
              setCanUpdate={setCanUpdate}
            />
          </Col>
          <Col md={6} xs={12}>
            <Feed />
          </Col>

          <Col md={6} xs={12}>
            <DriverJourney />
          </Col>
        </Row>
      </ApplicationLayout>
    </TravelProvider>
  );
}
