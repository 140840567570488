import React from 'react';

import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import AnimationCircle from 'v3/components/AnimationCircle';

export function StatusSelector({
  handleStatusSelected,
  setSelected,
  selected,
  loading,
  setPage,
  alertMessageNotAttend,
  alertMessageAttend
}) {
  function handleSelected(event, value) {
    if (value !== null) {

      if (value === 0) {
        handleStatusSelected({ selected_only_closed: true });
      } else if (value === 2) {
        handleStatusSelected({ selected_not_attended: true });
      } else if (value === 1) {
        handleStatusSelected({ selected_in_service: true });
      }
      setPage(0);
      setSelected(value);
    };
  }

  return (
    <ToggleButtonGroup
      value={selected}
      onChange={handleSelected}
      size="large"
      exclusive={true}
      className="toggleDeal"
      style={{ gap: '3px', }}
    >

      <ToggleButton
        value={2}
        style={{ backgroundColor: '#FCFEA8', color: '#000000', fontWeight: 'bold', fontSize: '14px' }}
        disabled={loading}>
        {alertMessageNotAttend && <AnimationCircle top={8} left={8} animation={false} />}
        NÃO ATENDIDAS
      </ToggleButton>

      <ToggleButton
        value={1}
        style={{ backgroundColor: '#00BC97', color: '#ffffff', fontWeight: 'bold', fontSize: '14px' }}
        disabled={loading}>
        {alertMessageAttend && <AnimationCircle top={8} left={8} />}
        EM ATENDIMENTO
      </ToggleButton>

      <ToggleButton
        value={0}
        style={{ backgroundColor: '#B5B5C3', color: '#ffffff', fontWeight: 'bold', fontSize: '14px' }}
        disabled={loading}
      >
        ENCERRADAS
      </ToggleButton>
    </ToggleButtonGroup>
  );
}

