import api from 'services/api';
import {
  AnullSchema,
  AttachmentDescriptionSchema,
  ChangeResponsibleSchema,
  DescriptionSchema,
  NewOccurrencesSchema,
} from './validator';

export async function validateNewOccurrence(
  occurrenceType,
  responsible,
  startDate,
  endDate,
  description,
  setErrors
) {
  const errorList = {};
  const data = {
    occurrenceType,
    responsible,
    startDate,
    endDate,
    description,
  };
  try {
    await NewOccurrencesSchema.validate(data, {
      abortEarly: false,
    });
    setErrors({ ...errorList });
    return true;
  } catch (err) {
    err.inner.forEach(error => {
      errorList[error.path] = error.message;
    });

    setErrors({ ...errorList });

    return false;
  }
}

export async function validateUpdateOccurrence({
  description,
  startDate,
  endDate,
  setErrors,
}) {
  const errorList = {};
  const data = {
    description,
    startDate,
    endDate,
  };
  try {
    await DescriptionSchema.validate(data, {
      abortEarly: false,
    });
    setErrors({ ...errorList });
    return true;
  } catch (err) {
    err.inner.forEach(error => {
      errorList[error.path] = error.message;
    });
    setErrors({ ...errorList });
    return false;
  }
}

export async function validateAnullOcorrence({
  description,
  setErrors,
}) {
  const errorList = {};
  const data = {
    description,
  };
  try {
    await AnullSchema.validate(data, {
      abortEarly: false,
    });
    setErrors({ ...errorList });
    return true;
  } catch (err) {
    err.inner.forEach(error => {
      errorList[error.path] = error.message;
    });
    setErrors({ ...errorList });
    return false;
  }
}

export async function validateChangeResponsible({
  description,
  responsible,
  setErrors,
}) {
  const errorList = {};
  const data = {
    description,
    responsible,
  };
  try {
    await ChangeResponsibleSchema.validate(data, {
      abortEarly: false,
    });
    setErrors({ ...errorList });
    return true;
  } catch (err) {
    err.inner.forEach(error => {
      errorList[error.path] = error.message;
    });

    setErrors({ ...errorList });

    return false;
  }
}

export async function validateAttachmentDescription(
  fileDescription,
  setErrors
) {
  const errorList = {};
  const data = {
    description: fileDescription,
  };
  try {
    await AttachmentDescriptionSchema.validate(data, {
      abortEarly: false,
    });
    setErrors({ ...errorList });
    return true;
  } catch (err) {
    err.inner.forEach(error => {
      errorList[error.path] = error.message;
    });

    setErrors({ ...errorList });

    return false;
  }
}

export async function onSearchUserResponsible(search) {
  try {
    const response = await api.get('user/all-except-drivers/name', {
      params: { search },
    });
    return response.data;
  } catch (error) {
    return [];
  }
}

export async function onSearchGroups(search) {
  try {
    const { data } = await api.get('groups', {
      params: { search, onlyActive: true },
    });
    return data;
  } catch (error) {
    return [];
  }
}
