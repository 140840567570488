import React, { useCallback } from 'react';
import debounce from 'lodash.debounce';

import Input from 'components/Input';
import Text from 'v3/components/Text';

import { Col, Row } from 'react-bootstrap';

function ConversationSearch({
  setLoadNumber,
  totalDeals,
  loading,
  setPage
}) {

  const handleInputChange = useCallback(
    debounce((value) => {
      setLoadNumber(value);
      setPage(0);
    }, 2000),
    []
  );

  const onChange = (e) => {
    handleInputChange(e.target.value);
  };

  return (
    <>
      <Row style={{ display: 'flex', flexDirection: 'column', gap: '10px', margin: '15px 0px' }}>
        <Col>
          <Input
            label="Número da Carga"
            onChange={onChange}
            disabled={loading}
          />
        </Col>
        <Col>
          {
            loading ? null : <Text color={'#747A87'} weight={500}>{totalDeals || 0} Cargas</Text>
          }
        </Col>
      </Row>
    </>
  );
}

export default ConversationSearch;
