import React, { useState, useEffect } from 'react';

import MaterialReactTable from 'material-react-table';
import { MRT_Localization_PT_BR } from 'material-react-table/locales/pt-BR';

import { TablePagination } from '@mui/material';

import theme from 'v3/theme';
import { useTableStyles } from './styles';
import { avaliableColumnsWithDefinitions } from './utils';
import { ColumnContextMenu } from '../ContextMenu';

export function MuiTable({
  travels,
  fetchTravels,
  pagination,
  setPagination,
  perPage,
  mappedColumns,
  setFilterTravelVehicle,
  scrollView,
  setChecked,
  userColumnsSlugs,
}) {
  const [showColumnContextMenu, setColumnContextMenu] = useState(false);
  const [activeColumn, setActiveColumn] = useState({});
  const classes = useTableStyles();
  const [columnSizing, setColumnSizing] = useState(() =>
    initializeColumnSizing()
  );
  const [columnOrder, setColumnOrder] = useState(() => initializeColumnOrder());

  function insertNonSelectedColumnsInColumnOrder(
    userColumnOrderPreferences,
    nonSelectedColumns
  ) {
    const newColumnOrder = [...userColumnOrderPreferences];
    nonSelectedColumns.map(item => {
      newColumnOrder.push(item);
    });

    return newColumnOrder;
  }

  function initializeColumnOrder() {
    const userColumnOrderPreference = JSON.parse(
      localStorage.getItem('travelsControlTowerUserColumnOrderPreference')
    );

    const nonSelectedColumns = [];
    avaliableColumnsWithDefinitions.forEach(item => {
      if (!userColumnsSlugs.includes(item.slug)) {
        nonSelectedColumns.push(item.slug);
      }
    });

    if (userColumnOrderPreference && nonSelectedColumns.length === 0) {
      return userColumnOrderPreference;
    } else if (userColumnOrderPreference && nonSelectedColumns.length > 0) {
      const newOrder = insertNonSelectedColumnsInColumnOrder(
        userColumnOrderPreference,
        nonSelectedColumns
      );

      return newOrder;
    } else {
      return avaliableColumnsWithDefinitions
        .map(c => c.slug)
        .sort(function (a, b) {
          return a - b;
        });
    }
  }

  const handleChangePage = (_, newPage) => {
    setPagination({ ...pagination, currentPage: newPage + 1 });
    fetchTravels(newPage + 1);
  };

  const paginationLabel = ({ from, to, count }) => {
    return `${from}-${to} de ${count}`;
  };

  function initializeColumnSizing() {
    const userColumnSizingPreference = JSON.parse(
      localStorage.getItem('travelsControlTowerUserColumnSizingPreference')
    );
    if (userColumnSizingPreference) {
      return userColumnSizingPreference;
    } else {
      return {};
    }
  }

  function handlePerPageChange(event) {
    const parsePerPage = parseInt(event.target.value, 10);
    localStorage.setItem(
      'travelsControlTowerUserPerPage',
      JSON.stringify(parsePerPage)
    );
    fetchTravels(1);
  }

  useEffect(() => {
    localStorage.setItem(
      'travelsControlTowerUserColumnOrderPreference',
      JSON.stringify(columnOrder)
    );
  }, [columnOrder]);

  useEffect(() => {
    localStorage.setItem(
      'travelsControlTowerUserColumnSizingPreference',
      JSON.stringify(columnSizing)
    );
  }, [columnSizing]);

  return (
    <>
      <MaterialReactTable
        columns={mappedColumns ?? []}
        data={travels}
        state={{ columnSizing, columnOrder }}
        enableStickyHeader={true}
        enableColumnResizing={true}
        onColumnSizingChange={setColumnSizing}
        onColumnOrderChange={setColumnOrder}
        enableColumnOrdering={true}
        enableColumnDragging={true}
        enableGlobalFilter={false}
        enableFilters={false}
        muiTablePaperProps={{
          sx: {
            boxShadow: 'none',
          },
        }}
        muiTableContainerProps={{
          sx: {
            maxHeight: '500px',
            '&:hover::-webkit-scrollbar': {
              width: 10,
              height: 10,
            },
            '&:hover::-webkit-scrollbar-track': {
              backgroundColor: 'transparent',
            },
            '&:hover::-webkit-scrollbar-thumb': {
              backgroundColor: theme.colors.medium_gray,
              borderRadius: 2,
            },
            '&:not(:hover)::-webkit-scrollbar': {
              opacity: 0,
              width: 10,
              height: 10,
            },
            '&:not(:hover)::-webkit-scrollbar-thumb': {
              opacity: 0,
            },
          },
        }}
        initialState={{
          density: 'compact',
        }}
        enablePagination={false}
        enableColumnActions={false}
        enableHiding={false}
        enableTopToolbar={false}
        enableBottomToolbar={false}
        muiTableBodyCellProps={({ cell }) => ({
          onContextMenu: event => {
            event.preventDefault();
            setActiveColumn(cell.row.original);
            setColumnContextMenu(true);
          },
        })}
        localization={MRT_Localization_PT_BR}
        columnFilterModeOptions={null}
        muiTableHeadCellDragHandleProps={{
          sx: {
            marginRight: '0.75rem',
          },
        }}
      />
      <TablePagination
        className={classes.pagination}
        rowsPerPageOptions={[
          { label: "10", value: 10 },
          { label: "30", value: 30 },
          { label: "50", value: 50 },
        ]}
        labelRowsPerPage='Viagens por página:'
        rowsPerPage={perPage}
        component="div"
        count={pagination?.total}
        page={pagination?.currentPage - 1}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handlePerPageChange}
        labelDisplayedRows={paginationLabel}
        showFirstButton
        showLastButton
      />
      <ColumnContextMenu
        open={showColumnContextMenu}
        onClose={() => setColumnContextMenu(false)}
        activeColumn={activeColumn}
        setFilterTravelVehicle={setFilterTravelVehicle}
        scrollView={scrollView}
        setChecked={setChecked}
      />
    </>
  );
}
