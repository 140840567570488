import { TablePagination } from '@mui/material';
import styled from 'styled-components';

export const StyledCell = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  color: gray;
  font-size: 0.75rem;
  white-space: pre-wrap;
`;

export const StyledCellSpan = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  color: gray;
  font-size: 0.80rem;
  white-space: pre-wrap;
  cursor: ${props => (props.clickable ? 'pointer' : 'text')};
`;


export const StyledTablePagination = styled(TablePagination)`
  padding: 0 30px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: sticky;
  bottom: 0; 
  z-index: 2;
  border-top: 0.5px solid rgba(0, 0, 0, 0.1); ; 
  background-color: white; 
  p {
    margin: 0;
  }
`;
