import React from "react"
import { Row, Col } from "react-bootstrap"
import { Text, Radio } from 'v3/components';

export function Filters({
  setFilters,
  filters,
  selectedDealList,
  loading,
  setPage
}) {

  if (selectedDealList?.selected_only_closed) {

    const setValues = (value) => {
      if (value?.my_attended) {
        return 3
      } else if (value?.attended) {
        return 1
      } else if (value?.not_attended) {
        return 2
      } else {
        return 0
      }
    }

    return (
      <Row>
        <Col lg={4} md={4} className="my-1 mt-10">
          <Radio.Group
            onChange={({ target }) => {
              if (target.value === 1) {
                setPage(0)
                setFilters(old => ({
                  ...old,
                  my_attended: false,
                  attended: true,
                  not_attended: false,
                }));
              } else if (target.value === 2) {
                setPage(0)
                setFilters(old => ({
                  ...old,
                  my_attended: false,
                  attended: false,
                  not_attended: true,
                }));
              } else if (target.value === 3) {
                setPage(0)
                setFilters(old => ({
                  ...old,
                  my_attended: true,
                  attended: false,
                  not_attended: false,
                }));
              } else {
                setPage(0)
                setFilters(old => ({
                  ...old,
                  my_attended: false,
                  attended: false,
                  not_attended: false,
                }));
              }
            }
            }
            value={setValues(filters)}
            horizontal
          >
            <Row style={{ display: 'flex', marginTop: '10px' }}>
              <Col style={{ display: 'flex', alignItems: 'center' }}>
                <Radio value={1} disabled={loading}>
                  <Text color="dark" type="label">
                    ATENDIDAS
                  </Text>
                </Radio>
                <Col style={{ minWidth: '150px' }}>
                  <Radio value={2} disabled={loading}>
                    <Text color="dark" type="label">
                      NÃO ATENDIDAS
                    </Text>
                  </Radio>
                </Col>
                <Radio value={0} disabled={loading}>
                  <Text color="dark" type="label">
                    TODAS
                  </Text>
                </Radio>
              </Col>
              <Col style={{ minWidth: '150px' }}>
                <Radio value={3} disabled={loading}>
                  <Text color="dark" type="label">
                    MEUS ATENDIMENTOS
                  </Text>
                </Radio>
              </Col>
            </Row>
          </Radio.Group>
        </Col>
      </Row>
    )
  } else if (selectedDealList?.selected_in_service) {
    return (
      <Row>
        <Col style={{ display: 'flex', marginTop: '10px' }}>
          <Radio.Group
            onChange={({ target }) => {
              if (target.value === 1) {
                setFilters(old => ({
                  ...old,
                  my_attended: true,
                }));
              } else {
                setFilters(old => ({
                  ...old,
                  my_attended: false,
                }));
              }
            }}
            value={filters?.my_attended ? 1 : 0}
            horizontal
          >
            <Col style={{ minWidth: '150px' }}>
              <Radio value={1} disabled={loading}>
                <Text color="dark" type="label">
                  MEUS ATENDIMENTOS
                </Text>
              </Radio>
            </Col>
            <Radio value={0} disabled={loading}>
              <Text color="dark" type="label">
                TODAS
              </Text>
            </Radio>
          </Radio.Group>
        </Col>
      </Row>
    )
  } else {
    return null
  }
}
