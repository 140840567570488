import styled, { css } from 'styled-components';

import BootstrapModal from 'react-bootstrap/Modal';

export const StyledModal = styled(BootstrapModal)`
  //background-color: rgba(51, 51, 51, 0.6);
  z-index: 999 !important;
  width: 500px;
  position: fixed;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -20%);
  margin: 0 auto;
`;

export const StyledModalBody = styled(BootstrapModal.Body)``;

