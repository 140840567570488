import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import api from 'services/api';
import { useSnackbar } from 'v3/components/Snackbar';
import { validatePermission } from 'actions/index';
import { Input, Select, SwitchOption, Text } from 'v3/components';
import { useUser } from './context';
import {
  fetchCompanies,
  fetchSelectOptions,
  fetchCostCenters,
  fetchBranchOffices,
} from './controller';

export default function UserData() {
  const user = useUser();
  const params = useParams();
  const snackbar = useSnackbar();

  const [profileOptions, setProfileOptions] = useState([]);
  const [groupsOptions, setGroupsOptions] = useState([]);
  const hasPermissionToEnableOrDisableUser =
    validatePermission('ATIVAR_USUARIO');
  const [isFetching, setIsFetching] = useState(false);
  const tagRegisterPermitted = validatePermission('CRIAR_TAGS');

  const isEditing = !!params.id;

  useEffect(() => {
    async function fetchProfiles() {
      try {
        const response = await api.get('users-profiles');
        const optionsUserProfiles = response.data.filter(
          profile =>
            !(
              profile.name === 'Administrador' &&
              !validatePermission('SUPER_ADM')
            )
        );
        setProfileOptions(optionsUserProfiles);
      } catch (error) {
        // handle exception
      }
    }
    fetchProfiles();
  }, []);

  useEffect(() => {
    async function fetchUserGroups() {
      try {
        const response = await api.get('groups?onlyActive=true', {});
        setGroupsOptions(response.data);
      } catch (error) {
        // Handle exception
      }
    }

    fetchUserGroups();
  }, []);

  useEffect(() => {
    if (!params.id)
      user.setData({
        profile: null,
        cpf: null,
        name: null,
        companies: [],
        costCenters: [],
        groups: [],
        tags: null,
        phonenumber: null,
        branchOffices: [],
      });
  }, []);

  async function handleTagCreate(value) {
    let tagValue = value;

    if (tagRegisterPermitted) {
      if (tagValue) {
        tagValue = tagValue.trim();
        if (tagValue.length >= 3) {
          try {
            const response = await api.post('tags', { name: tagValue });
            if (response.status === 200) {
              const newTag = {
                id: response.data.id,
                name: tagValue,
              };
              if (user.data.tags !== null)
                user.setData({ tags: [...user.data.tags, newTag] });
              else {
                user.setData({ tags: [newTag] });
              }
            }
          } catch (err) {
            // Handle exception
          }
        }
      }
    } else {
      snackbar.show(<Text>Usuário sem permissão para criar tags</Text>, {
        type: 'error',
      });
    }
  }

  const availableGroups = useMemo(
    () =>
      groupsOptions.filter(
        group => !user.data.groups?.some(userGroup => userGroup.id === group.id)
      ),
    [groupsOptions, user.data.groups]
  );

  const selectAllBranches = useCallback(async () => {
    try {
      setIsFetching(true);
      const allBranchOffices = await fetchBranchOffices({
        search: '',
        userCompaniesId: user?.data?.companies.map(company => company.id),
      });
      if (allBranchOffices.length === 0) {
        snackbar.show(<Text>Nenhuma filial encontrada</Text>, {
          type: 'error',
        });
      }
      user.setData({ branchOffices: allBranchOffices });
    } catch (error) {
      // catcher
    } finally {
      setIsFetching(false);
    }
  }, [user?.data?.company?.id, user?.data?.branchOffices]);

  const handleCompanies = value => {
    const removedCompany =
      (value?.length &&
        user?.data?.companies &&
        user?.data?.companies?.length > value?.length) ||
      !value;

    let branchOffices = [];
    let costCenters = [];

    if (value) {
      const selectedCompaniesIds = value.map(({ id }) => id);

      branchOffices = user?.data?.branchOffices.filter(branchOffice => {
        if (!removedCompany) return true;
        if (selectedCompaniesIds.includes(branchOffice.company_id)) return true;
        return false;
      });
      costCenters = user?.data?.costCenters.filter(costCenter => {
        if (!removedCompany) return true;
        if (selectedCompaniesIds.includes(costCenter.company)) return true;
        return false;
      });
    }

    user.setData({
      branchOffices,
      costCenters,
      companies: value || null,
    });
  };

  useEffect(() => {
    if (user?.data?.branchOffices?.length) {
      const branchOfficesIdsSelected = user.data.branchOffices.reduce(
        (acc, curr) => {
          acc.push(curr.id);
          return acc;
        },
        []
      );
      if (branchOfficesIdsSelected.includes('default')) {
        selectAllBranches();
      }
    }
  }, [user.data.branchOffices]);

  return (
    <Row>
      <Col xs={12} className="mb-3">
        <Select
          isDisabled={!params.id}
          label="Perfil do Usuário *"
          options={profileOptions}
          getOptionLabel={option => option.name}
          getOptionValue={option => option.id}
          value={user.data?.profile}
          onChange={value => user.setData({ profile: value })}
          error={user.errors?.['data.profile_id']}
        />
      </Col>
      <Col xs={12} className="mb-3">
        <Input
          label="Nome *"
          value={user.data?.username}
          onChange={e => user.setData({ username: e.target.value })}
          error={user.errors?.['data.username']}
        />
      </Col>
      {user.data?.profile?.id === 2 && (
        <Col xs={12} className="mb-3">
          <Input
            label="Telefone *"
            value={user.data?.phonenumber}
            onChange={e => user.setData({ phonenumber: e.target.value })}
            masked
            mask={[
              '(',
              /[0-9]/,
              /\d/,
              ')',
              /\d/,
              /\d/,
              /\d/,
              /\d/,
              /\d/,
              '-',
              /\d/,
              /\d/,
              /\d/,
              /\d/,
            ]}
            error={user.errors?.['data.phonenumber']}
          />
        </Col>
      )}
      {user.data?.profile?.id === 2 && (
        <Col xs={12} className="mb-3">
          <Input
            label="CPF *"
            value={user.data?.cpf}
            onChange={e => user.setData({ cpf: e.target.value })}
            masked
            mask={[
              /[0-9]/,
              /\d/,
              /\d/,
              '.',
              /\d/,
              /\d/,
              /\d/,
              '.',
              /\d/,
              /\d/,
              /\d/,
              '-',
              /\d/,
              /\d/,
            ]}
            error={user.errors?.['data.cpf']}
          />
        </Col>
      )}
      <Col xs={12} className="mb-3">
        <Select.Async
          label="Empresas *"
          onSearch={search => fetchCompanies(search)}
          multiple
          value={user.data?.companies}
          onChange={handleCompanies}
          getOptionLabel={option => option?.name}
          getOptionValue={option => option?.id}
          error={user.errors?.['data.companies']}
        />
      </Col>
      {user?.data?.profile?.name !== 'Motorista' && (
        <Col xs={12} className="mb-3">
          <Select.Async
            isDisabled={isFetching || !user.data?.companies?.length}
            label="Filiais *"
            onSearch={search =>
              fetchBranchOffices({
                search,
                userCompaniesId: user?.data?.companies.map(
                  company => company.id
                ),
              })
            }
            options={[{ name: 'Selecionar todas as filiais', id: 'default' }]}
            multiple
            value={user.data?.branchOffices}
            onChange={values => {
              user.setData({ branchOffices: values || [] });
            }}
            getOptionLabel={option => option.name}
            getOptionValue={option => option.id}
            error={user?.errors?.['data.branchOffices']}
          />
        </Col>
      )}
      <Col xs={12} className="mb-3">
      {user?.data?.profile?.name !== 'Motorista' && <Select.Async
          label="Centro de custo *"
          onSearch={search =>
            fetchCostCenters({
              search,
              userCompaniesId: user?.data?.companies.map(company => company.id),
            })
          }
          value={user.data?.costCenters}
          multiple
          onChange={value => user.setData({ costCenters: value })}
          getOptionLabel={option => option.name}
          getOptionValue={option => option.id}
          error={user.errors?.['data.costCenters']}
        />}
      </Col>
      {user.data?.profile?.name !== 'Motorista' && (
        <>
          <Col xs={12} className="mb-3">
            <Select
              label="Grupos de usuário"
              options={availableGroups}
              getOptionLabel={option => option.name}
              getOptionValue={option => option.id}
              value={user.data?.groups}
              onChange={value => user.setData({ groups: value })}
              error={user.errors?.groups}
              multiple
              horizontal
              modalHeading="Adicione um grupo de usuário"
              modalBodyTitle="Grupos de Usuário:"
            />
          </Col>
        </>
      )}
      <Col xs={12} className="mb-3">
        <Select.Async
          label="Tags"
          creatable
          onCreateOption={handleTagCreate}
          multiple
          value={user.data?.tags}
          horizontal
          modalHeading="Adicione uma tag"
          modalBodyTitle="Tags:"
          getOptionLabel={option => option.name}
          getOptionValue={option => option.id}
          onChange={event => user.setData({ tags: event })}
          onSearch={search =>
            fetchSelectOptions('tags', {
              search,
              not_paginated: true,
            })
          }
          error={user.errors?.tags}
        />
      </Col>
      {isEditing && (
        <>
          <Col xs={6}>
            <Text type="label" color="dark">
              Status
            </Text>
            <SwitchOption
              className="mt-1"
              value={user.data.active}
              onClick={() => user.setData({ active: !user.data.active })}
              disabled={!hasPermissionToEnableOrDisableUser}
            />
          </Col>
          <Col xs={6}>
            <Text type="label" color="dark">
              Bloqueado
            </Text>
            <SwitchOption
              inactiveText={'Não'}
              activeText={'Sim'}
              className="mt-1"
              value={user.data.blocked}
              onClick={() => user.setData({ blocked: !user.data.blocked })}
              disabled={!hasPermissionToEnableOrDisableUser}
            />
          </Col>
        </>
      )}
    </Row>
  );
}
