/* eslint-disable import/no-unresolved */
import React from 'react';
import api from 'services/api';
import dealApi from 'services/apis/deal';
import Text from 'v3/components/Text';

import * as CancelTruckpad from '../Integrations/Truckpad/controller';
import * as CancelFretebras from '../Integrations/Fretebras/controller';
import * as CancelCargon from '../Integrations/Cargon/controller';

export async function confirmOption({
  setLoading,
  setConfirmButtonDisabled,
  reasonDescription,
  reasonSelected,
  snackbar,
  history,
  loadId,
}) {
  if (!loadId) {
    return;
  }
  setConfirmButtonDisabled(true);

  if (reasonSelected.value === undefined) {
    snackbar.show(<Text>Por favor, selecione um motivo.</Text>, {
      type: 'error',
    });
    setConfirmButtonDisabled(false);
    return;
  }

  if (!reasonDescription) {
    snackbar.show(
      <Text>Por favor, escreva uma descrição sobre o motivo.</Text>,
      { type: 'error' }
    );
    setConfirmButtonDisabled(false);
    return;
  }

  if (reasonDescription.length < 30) {
    snackbar.show(
      <Text>Por favor, a descrição precisa ter pelo menos 30 caracteres.</Text>,
      { type: 'error' }
    );
    setConfirmButtonDisabled(false);
    return;
  }

  const motivo = reasonSelected.value;
  const descricao = reasonDescription;

  try {
    setLoading(true);
    const response = await api.put(`loads/disableLoad/${loadId}`, {
      deleted_reason: motivo,
      deleted_reason_description: descricao,
    });

    CancelTruckpad.cancel(loadId);
    CancelFretebras.cancel(loadId);
    CancelCargon.cancel(loadId);

    if (response.status === 200) {
      snackbar.show(
        <Text>
          Carga cancelada com sucesso! Você será redirecionado para a listagem
        </Text>,
        { type: 'success' }
      );
      setLoading(false);
      setTimeout(() => {
        history.push('/cargas');
      }, 3000);
      return;
    }
    snackbar.show(<Text>{response.message}</Text>, { type: 'error' });
    setLoading(false);
  } catch (ex) {
    if (ex.response?.data?.message) {
      snackbar.show(<Text>{ex.response?.data?.message}</Text>, {
        type: 'error',
      });
    } else
      snackbar.show(<Text>Não foi possível cancelar a carga.</Text>, {
        type: 'error',
      });
    setLoading(false);
    setConfirmButtonDisabled(false);
  }
}
