import React from 'react';
import { formatPhone } from 'v3/utils/formatter';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';
import Text from 'v3/components/Text';
import moment from 'moment';

export default function RenderTitle({ deal }) {
  const RenderTitle = ({ deal }) => {
    if (deal?.driver_id_carrier_web) {
      return (
        <>
          <Typography>
            Negociação com{' '}
            {deal?.carrier_web_user_id ? (
              <Link
                to={`/usuarios/${deal?.carrier_web_user_id}`}
                key={deal?.carrier_web_user_id}
                target="_blank"
              >
                {deal?.carrier_web_username}
              </Link>
            ) : (
              deal?.carrier_web_username
            )}{' '}
            {`${formatPhone(deal?.carrier_web_phonenumber)}`}
          </Typography>
          <Text> Sinalização de interesse: {moment(deal?.created_at).format('DD/MM/YYYY HH:MM')}</Text>
        </>
      );
    } else {
      return (
        <>
          <Typography>
            Motorista:{' '}
            {deal?.person_id ? (
              <Link
                to={`/motoristas/${deal?.person_id}`}
                key={deal?.person_id}
                target="_blank"
              >
                {deal?.driver_name}
              </Link>
            ) : (
              deal?.driver_name
            )}{' '}
            {`${formatPhone(deal?.driver_phone)}`}
          </Typography>
          <Text> Sinalização de interesse: {moment(deal?.created_at).format('DD/MM/YYYY HH:MM')}</Text>
        </>
      );
    }
  }

  return (
    <RenderTitle deal={deal} />
  )
}
