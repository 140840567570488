/* eslint-disable import/no-unresolved */
import React, { useState, useEffect } from 'react';

import { Row, Col } from 'react-bootstrap';

import Text from 'v3/components/Text';
import Input from 'v3/components/Input';
import Radio from 'v3/components/Radio';
import Button from 'v3/components/Button';
import Card, { DefaultLoadingBodyComponent } from 'v3/components/Card';
import { useSnackbar } from 'v3/components/Snackbar';

import { validatePermission } from 'actions/index';

import * as Controller from './controller';

export default function UserData({ driver, loadingCard, refetch }) {
  const snackbar = useSnackbar();
  const [confirmPassword, setConfirmPassword] = useState();
  const [isEditing, setEditing] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [verified, setVerified] = useState(false);
  const [active, setActive] = useState(false);
  const [password, setPassword] = useState();
  const [isEditingPassword, setIsEditingPassword] = useState(false);
  const [email, setEmail] = useState();
  const [isEditingEmail, setIsEditingEmail] = useState(false);
  const [confirmEditingEmail, setConfirmEditignEmail] = useState();
  const [phonenumber, setPhonenumber] = useState();
  const [isEditingPhonenumber, setIsEditingPhonenumber] = useState(false);
  const [errors, setErrors] = useState({});
  const [token, setToken] = useState();

  const [hasPermissionToEdit, setPermissionToEdit] = useState();
  
  useState(() => {
    setPermissionToEdit(validatePermission('ALTERAR_USUARIO'));
  }, []);

  useEffect(() => {
    setLoading(true)
    if (driver && !isEditing) {
      setVerified(driver?.user?.verified);
      setActive(driver?.user?.active)
      setToken(driver.user_token);
      setPassword(driver.user_password);
      setEmail(driver?.user?.email)
      setPhonenumber(driver?.user?.phonenumber)
      setIsEditingEmail(false)
      setIsEditingPassword(false)
      setIsEditingPhonenumber(false)
      setActive(driver?.user?.active)
    } else if(driver && isEditing) {
      setErrors(false)
      setPassword(null)
      setEmail(email)
      setPhonenumber(phonenumber)
    }
    setLoading(false)
  }, [driver, isEditing]);

  async function save() {
    try {
      setLoading(true);
      const validated = Controller.validate({ password, confirmPassword, isEditingPassword, email, confirmEditingEmail, isEditingEmail,  phonenumber, isEditingPhonenumber, setErrors })
      if (!validated){
        throw new Error('Verifique os dados do aplicativo')
      }

      const response = await Controller.save({
        phone: phonenumber,
        name: driver.social_name,
        setEditing,
        setLoading,
        password: password || driver.user_password,
        verified,
        driver,
        email,
        active,
      });

      await refetch()
      setToken(response.data.token);
      setEditing(false);
    } catch (err) {
      const message = err?.message || 'Erro ao atualizar os dados do aplicativo';
      snackbar.show(<Text>{message}</Text>, {
        type: 'error',
      });
    } finally {
      setLoading(false);
    }
  }

  return (
    <Card
      loading={loadingCard}
      LoadingBodyComponent={() => (
        <DefaultLoadingBodyComponent linesCount={2} perLine={2} />
      )}
      header={
        <Text color="#464E5F" type="header">
          Dados do aplicativo
        </Text>
      }
      HeaderRightComponent={
        <div>
          {isEditing && (
            <Button
              onClick={() => setEditing(false)}
              variant="secondary"
              loading={isLoading}
              className="mr-2"
            >
              <Text color="dark" type="regular">
                Cancelar
              </Text>
            </Button>
          )}
          <Button
            variant={isEditing ? 'success' : 'secondary'}
            disabled={!hasPermissionToEdit}
            loading={isLoading}
            onClick={isEditing ? () => save() : () => setEditing(true)}
          >
            <Text weight={500} type="regular">
              {isEditing ? 'Salvar' : 'Editar'}
            </Text>
          </Button>
        </div>
      }
    >
      {isEditing && (
        <Row className="form">
          <Col xs={6} className="mb-3">
            <Input
              label='E-mail'
              type="text"
              value={email}
              onChange={event => {setEmail(event.target.value); setIsEditingEmail(true)}}
              error={errors.email}
            />
          </Col>
          <Col xs={6} className="mb-3">
            <Input
              label='Cofirmar e-mail'
              type="text"
              placeholder={ !isEditingEmail ? email : 'digite a confirmação de email'}
              disabled={!isEditingEmail}
              onChange={event => setConfirmEditignEmail(event.target.value)}
              error={errors.confirmEditingEmail}
            />
          </Col> 
          <Col lg={6} md={6} xs={12}>
            <Input
              label="Senha"
              type="password"
              name="password"
              placeholder='******'
              value={password}
              password
              onChange={event => {setPassword(event.target.value); setIsEditingPassword(true)}}
              error={errors.password}
            />
          </Col>
          <Col lg={6} md={6} xs={12}>
            <Input
              label="Confirmar senha"
              type="password"
              name="password"
              placeholder='******'
              password
              disabled={!isEditingPassword}
              onChange={event => setConfirmPassword(event.target.value)}
              error={errors.confirmPassword}
            />
          </Col>
          <Col xs={6} className="mb-3">
            <Input
              label='Telefone'
              type="text"
              value={phonenumber}
              onChange={event => {setPhonenumber(event.target.value); setIsEditingPhonenumber(true)}}
              masked
              mask={[
                '(',
                /[0-9]/,
                /\d/,
                ')',
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                '-',
                /\d/,
                /\d/,
                /\d/,
                /\d/,
              ]}
              error={errors.phonenumber}
            />
          </Col>
          <Col lg={6} md={6} xs={6} className="mb-3">
            <Radio.Group
              label="Ativo"
              onChange={({ target }) => setActive(target.value)}
              value={active}
              horizontal
            >
              <Radio value>
                <Text type="label">Ativo</Text>
              </Radio>
              <Radio value={false}>
                <Text color="dark" type="label">
                  Inativo
                </Text>
              </Radio>
            </Radio.Group>
          </Col>
          <Col lg={6} md={6} xs={6} className="mb-3">
            <Radio.Group
              label="Token verificado"
              onChange={({ target }) => setVerified(target.value)}
              value={verified}
              horizontal
            >
              <Radio value>
                <Text type="label">Sim</Text>
              </Radio>
              <Radio value={false}>
                <Text color="dark" type="label">
                  Não
                </Text>
              </Radio>
            </Radio.Group>
          </Col>
        </Row>
      )}
      {!isEditing && (
        <Row>
          <Col xs={6}>
            <Text type="label" color="#464E5F">
              Senha
            </Text>
            <Text type="password" color="gray" as="p" className="mt-2">
              {token ? '*****' : ''}
            </Text>
          </Col>
          <Col xs={6}>
            <Text type="label" color="#464E5F">
              Token
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {token || '-'}
            </Text>
          </Col>
          <Col xs={6}>
            <Text type="label" color="#464E5F">
              Telefone
            </Text>
            <Text type="password" color="gray" as="p" className="mt-2">
              {phonenumber || '-'}
            </Text>
          </Col>
          <Col xs={6}>
            <Text type="label" color="#464E5F">
              E-mail
            </Text>
            <Text type="password" color="gray" as="p" className="mt-2">
              {email || '-'}
            </Text>
          </Col>
          <Col xs={6}>
            <Text type="label" color="#464E5F">
              Token verificado
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {verified ? 'Sim' : 'Não'}
            </Text>
          </Col>
          <Col xs={6}>
            <Text type="label" color="#464E5F">
              Status
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {active ? 'Ativo' : 'Não Ativo'}
            </Text>
          </Col>
        </Row>
      )}
    </Card>
  );
}
