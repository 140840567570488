import React, { useEffect, useState } from 'react';
import { Button, Card, Fallback, Text, Tooltip } from 'v3/components';
import { Col } from 'react-bootstrap';
import theme from 'v3/theme';
import { RiAlertFill } from 'react-icons/ri';
import { FaCheck } from 'react-icons/fa';
import { Divider } from '@mui/material';
import { useModal, usePermission } from 'hooks';
import { cooperplaceApi } from 'services/api';
import { useSnackbar } from 'v3/components/Snackbar';
import { useTravel } from '../..';
import { ChecklistContainer, ChecklistItem } from './styles';
import CheckListDetailModal from './CheckListDetailModal';
import NewChecklistModal from './NewChecklistModal';

function InteractChecklistButton({
  isFetching,
  hasPermissionToView,
  checklist: originalChecklist,
  setSelectedChecklistId,
  travelData,
}) {
  const [tooltipState, setTooltipState] = useState({
    needTooltip: false,
    content: '',
  });
  const hasPermissionToRespond = usePermission('RESPONDER_CHECKLIST');

  const validatePermissionToInteract = checklist => {
    const [vehicleStatus, colectStatus, deliveryStatus] = travelData.statuses
      .filter(
        stats =>
          stats.code === 'ST2' || stats.code === 'ST8' || stats.code === 'ST5'
      )
      .sort((a, b) => a.order - b.order);
    const travelStatusOrder = travelData.data.status.order;
    const checklistType = checklist.checklistType.slug;
    const travelStatus = travelData.data.status.code;

    if (checklist.status.slug === 'NOT_ANSWERED' && !hasPermissionToRespond) {
      setTooltipState({
        needTooltip: true,
        content: 'Necessário permissão para responder checklist',
      });
      return;
    }
    if (checklist.status.slug === 'ANSWERED' && !hasPermissionToView) {
      setTooltipState({
        needTooltip: true,
        content: 'Necessário permissão para visualizar checklist',
      });
      return;
    }
    if (checklist.status.slug === 'NOT_ANSWERED') {
      let message;

      if (
        checklistType === 'vehicle' &&
        travelStatusOrder < vehicleStatus.order
      ) {
        message =
          'Checklists de veículos só podem ser respondidas em viagem com status ordem de coleta';
      }

      if (
        checklistType === 'colect' &&
        travelStatusOrder < colectStatus.order
      ) {
        message =
          'Checklists de carga coleta só podem ser respondidas em viagem com status aguardando carregamento';
      }

      if (
        checklistType === 'delivery' &&
        travelStatusOrder < deliveryStatus.order
      ) {
        message =
          'Checklists de carga entrega só podem ser respondidas em viagem com status em análise';
      }

      if (travelStatus === 'BLOCKED') {
        message = 'A viagem está bloqueada!';
      }

      if (travelStatus === 'ST7') {
        message = 'A viagem está cancelada!';
      }

      if (message) {
        setTooltipState({
          needTooltip: true,
          content: message,
        });
        return;
      }
    }

    setTooltipState({ needTooltip: false, content: '' });
  };

  useEffect(() => {
    if (originalChecklist) {
      validatePermissionToInteract(originalChecklist);
    }
  }, [originalChecklist]);

  return tooltipState.needTooltip ? (
    <Tooltip content={<Text>{tooltipState.content}</Text>} placement="bottom">
      <Button style={{ minWidth: 150 }} loading={isFetching} disabled>
        {originalChecklist?.status?.slug === 'NOT_ANSWERED'
          ? 'Responder'
          : 'Visualizar'}
      </Button>
    </Tooltip>
  ) : (
    <Button
      style={{ minWidth: 150 }}
      loading={isFetching}
      onClick={() => setSelectedChecklistId(originalChecklist?.id)}
    >
      {originalChecklist?.status?.slug === 'NOT_ANSWERED'
        ? 'Responder'
        : 'Visualizar'}
    </Button>
  );
}

export default function TravelChecklist() {
  const travel = useTravel();
  const checklistDetailModal = useModal();
  const newChecklistModal = useModal();
  const snackbar = useSnackbar();
  const statusCodesToDisableChecklist = ['ST7', 'BLOCKED'];

  const [selectedChecklistId, setSelectedChecklistId] = useState(null);
  const [selectedChecklist, setSelectedChecklist] = useState(null);
  const [checkListTypes, setCheckListTypes] = useState([]);

  const hasPermissionToView = usePermission('VISUALIZAR_CHECKLIST');

  async function fetchChecklistTypes() {
    try {
      travel.setIsFetching(true);
      const { data } = await cooperplaceApi.get('checklists-types');
      setCheckListTypes(data);
    } catch (error) {
      // catcher
    } finally {
      travel.setIsFetching(false);
    }
  }

  const fetchDetailChecklist = async () => {
    if (selectedChecklistId) {
      try {
        travel.setIsFetching(true);
        const { data } = await cooperplaceApi.get(
          `travel-checklist/checklist/${selectedChecklistId}`
        );
        setSelectedChecklist(data.data);
        checklistDetailModal.open();
        setSelectedChecklistId(null);
      } catch (error) {
        snackbar.show(<Text>Erro ao detalhar checklist da viagem</Text>, {
          type: 'error',
        });
      } finally {
        travel.setIsFetching(false);
      }
    }
  };

  useEffect(() => {
    if (selectedChecklistId) {
      fetchDetailChecklist();
    }
  }, [selectedChecklistId]);

  useEffect(() => {
    if (travel?.data?.id) {
      travel.fetchTravelChecklists();
    }
  }, [travel?.data?.id]);

  useEffect(() => {
    fetchChecklistTypes();
  }, []);

  return (
    <>
      <Card
        loading={travel.isLoading || travel.isFetching}
        header={
          <Text color="dark" type="header">
            Checklist da viagem
          </Text>
        }
        HeaderRightComponent={
          hasPermissionToView ? (
            <Button
              loading={travel.isLoading || travel.isFetching}
              disabled={statusCodesToDisableChecklist.includes(
                travel?.data?.travelStatus?.code
              )}
              onClick={newChecklistModal.open}
            >
              <Text color="white">Solicitar novo checklist</Text>
            </Button>
          ) : (
            <Tooltip
              content={
                <Text>Necessário permissão para visualizar checklists</Text>
              }
            >
              <Button loading={travel.isLoading || travel.isFetching} disabled>
                <Text color="white">Solicitar novo checklist</Text>
              </Button>
            </Tooltip>
          )
        }
      >
        <Fallback
          fall={travel.checklists.length === 0}
          component={
            <Col className="pt-2 d-flex justify-content-center align-items-center">
              <Button colors={theme.colors.primary} size="md" disabled>
                Não há itens a serem exibidos
              </Button>
            </Col>
          }
        >
          <ChecklistContainer>
            {travel.checklists.map(checklist => {
              if (
                checklist?.checklistType?.slug === 'transhipment' &&
                travel?.data?.status?.code !== 'ST10'
              ) {
                return null;
              }
              return (
                <>
                  <ChecklistItem key={checklist.name}>
                    <div>
                      {checklist?.status?.slug === 'ANSWERED' ? (
                        <>
                          <Tooltip
                            placement="bottom"
                            content={<Text>Checklist respondido.</Text>}
                          >
                            <FaCheck color={theme.colors.success} />
                          </Tooltip>
                          {((checklist?.requiredDriverSign &&
                            !checklist?.signDriverId) ||
                            (checklist?.requiredInspectSign &&
                              !checklist?.signInspectId)) && (
                            <Tooltip
                              placement="bottom"
                              content={
                                <div
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                  }}
                                >
                                  {checklist?.requiredDriverSign &&
                                    !checklist?.signDriverId && (
                                      <Text>
                                        Assinatura motorista pendente.
                                      </Text>
                                    )}
                                  {checklist?.requiredInspectSign &&
                                    !checklist?.signInspectId && (
                                      <Text>Assinatura inspetor pendente.</Text>
                                    )}
                                </div>
                              }
                            >
                              <RiAlertFill color={theme.colors.orange} />
                            </Tooltip>
                          )}
                        </>
                      ) : (
                        <Tooltip
                          placement="bottom"
                          content={<Text>Checklist não respondido.</Text>}
                        >
                          <RiAlertFill color={theme.colors.orange} />
                        </Tooltip>
                      )}
                      <Text>{checklist.id}</Text>
                      <Text>{checklist.checklistType.name}</Text>
                    </div>
                    <div>
                      {checklist?.questions.find(
                        question => !!question.occurrenceId
                      ) && (
                        <Tooltip
                          placement="bottom"
                          content={
                            <Text>
                              Existem ocorrências vinculadas a este checklist.
                            </Text>
                          }
                        >
                          <RiAlertFill color={theme.colors.orange} />
                        </Tooltip>
                      )}
                      <InteractChecklistButton
                        checklist={checklist}
                        hasPermissionToView={hasPermissionToView}
                        isFetching={travel.isFetching}
                        setSelectedChecklistId={setSelectedChecklistId}
                        travelData={travel}
                      />
                    </div>
                  </ChecklistItem>
                  <Divider />
                </>
              );
            })}
          </ChecklistContainer>
        </Fallback>
      </Card>
      <CheckListDetailModal
        checklist={selectedChecklist}
        modalHook={checklistDetailModal}
        travelData={travel?.data}
        fetchTravelChecklists={travel.fetchTravelChecklists}
        setSelectedChecklistId={setSelectedChecklistId}
        fetchFeed={travel?.fetchFeed}
      />
      <NewChecklistModal
        travelData={travel?.data}
        checkListTypesOptions={checkListTypes}
        modalHook={newChecklistModal}
        fetchTravelChecklists={travel.fetchTravelChecklists}
      />
    </>
  );
}
