import React from 'react';
import moment from 'moment';

import './style.css';

function Message({
  data,
  isMine,
  startsSequence,
  endsSequence,
  showTimestamp,
}) {
  const friendlyTimestamp = moment(data.created_at).format('DD/MM/YYYY');
  const formatDeliveredAt = moment(data.delivered_at).format('HH:mm');

  return (
    <div
      className={[
        'message',
        `${isMine ? 'mine' : ''}`,
        `${startsSequence ? 'start' : ''}`,
        `${endsSequence ? 'end' : ''}`,
      ].join(' ')}
    >
      {showTimestamp && <div className="timestamp">{friendlyTimestamp}</div>}
      <div className="bubble-container">

        {
          data.message_type && ['SYSTEM_MESSAGE', 'ALERT_MESSAGE', 'ALERT_MESSAGE_INACTIVITY'].includes(data.message_type) ? (
            <div className='info-init-message-container'>
              <div className="info-init-message" title={friendlyTimestamp}>
                {data.message}
                <p className='time-message-config'>{formatDeliveredAt}</p>
              </div>

            </div>
          ) : (
            <div className="bubble" title={friendlyTimestamp}>
              {<p className='user-name-message'>{data.sender_name}</p>}
              {data.message}
              <p className='time-message'>{formatDeliveredAt}</p>
            </div>
          )
        }
      </div>
    </div>
  );
}

export default Message;
