import React, { useContext } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import PanelRegister from 'v3/pages/PowerBi/PanelRegister';
import { UserContext } from 'contexts/UserContext';
import { LoadingPage } from 'v3/pages/LoadingPage';
import { UpdatePasswordPage } from 'v3/pages/UpdatePassword';
import { isAuthenticated } from './services/auth';
import routes from './config/MenuNavConstants';
import ForgotPassword from './pages/forgotPassword';
import Login from './pages/login/Login';
import ApplicationLayoutV3 from './v3/layouts/Application';
import PrivateRouteV3 from './v3/routes/PrivateRoute';
import DevComponents from './v3/pages/dev/index';
import AppRedirectPage from './v3/pages/AppRedirectPage';


const PrivateRoute = ({ title, component, path, ...rest }) => {
  const dispatch = useDispatch();
  const {
    isUserActive,
    isLoading,
    shouldUserChangePassword,
    lastPasswordUpdate,
    isUserBlocked
  } = useContext(UserContext);

  const authenticated = isAuthenticated();
  const currentDate = new Date();
  const sixMonthsAgo = new Date(currentDate);
  sixMonthsAgo.setMonth(currentDate.getMonth() - 6);
  const lastPasswordUpdateInDate = new Date(lastPasswordUpdate);
  const isLastUpdateLessThanSixMonths = lastPasswordUpdateInDate < sixMonthsAgo;
  const shouldRedirectToChangePasswordModal =
    isUserActive &&
    authenticated &&
    !isUserBlocked &&
    (shouldUserChangePassword || isLastUpdateLessThanSixMonths);

  if (isLoading) {
    return <LoadingPage isLoading={isLoading} />;
  }

  if (shouldRedirectToChangePasswordModal) {
    return (
      <Route {...rest} path="conta/atualizar" component={UpdatePasswordPage} />
    );
  }

  if (isUserActive && authenticated && !isUserBlocked) {
    dispatch({ type: 'CURRENT_PATH', path });

    return (
      <ApplicationLayoutV3 title={title} isOld>
        <div className="w-100 pt-3">
          <Route {...rest} path={path} component={component} />
        </div>
      </ApplicationLayoutV3>
    );
  }
  localStorage.removeItem('token');
  localStorage.removeItem('usuario');
  localStorage.removeItem('updatePushUserId');
  localStorage.removeItem('permissions');
  localStorage.removeItem('bot');
  localStorage.removeItem('issue_permission');

  return <Route {...rest} component={Login} />;
};

const allRoutes = routes
  .filter(route => route.children)
  .reduce(
    (acc, route) => {
      route.children.forEach(children => acc.push(children));
      return acc;
    },
    [...routes]
  )
  .filter(route => route.path);

function Routes() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/login" component={Login} />
        <Route path="/forgot-password/:token" component={ForgotPassword} />
        <Route path="/panel-register-edit" component={PanelRegister} />
        <Route path="/load-offer/:loadCode" component={AppRedirectPage} />
        <Route path="/aggregation-invite/:aggregationCode" component={AppRedirectPage} />
        {process.env.REACT_APP_STAGE === 'dev' && (
          <PrivateRouteV3
            exact
            path="/dev/components"
            component={DevComponents}
          />
        )}

        {allRoutes.map((route, index) => {
          if (route.new) {
            return <PrivateRouteV3 key={route.path} exact {...route} />;
          }

          return (
            <PrivateRoute
              key={String(index)}
              exact
              path={route.path}
              component={route.component}
              title={route.title}
            />
          );
        })}
      </Switch>
    </BrowserRouter>
  );
}

export default Routes;
