/* eslint-disable import/no-unresolved */
/* eslint-disable consistent-return */
import React, { useState, useEffect, useMemo, useContext } from 'react';
import { useParams } from 'react-router-dom';
import api from 'services/api';
import { conformWeight } from 'components/InputMask/Weight';
import { validatePermission } from 'actions/index';
import { Row, Col } from 'react-bootstrap';
import Text from 'v3/components/Text';
import Button from 'v3/components/Button';
import Input from 'v3/components/Input';
import Select from 'v3/components/Select';
import Radio from 'v3/components/Radio';
import { useSnackbar } from 'v3/components/Snackbar';
import Card, { DefaultLoadingBodyComponent } from 'v3/components/Card';
import { convertNumberToDatabase } from 'v3/utils/formatter';
import { BlockedTravelContext } from 'contexts/BlockedTravelProvider';
import { vehicleSchema } from '../validator';

function Vehicle({
  load,
  getLoad,
  loadingCard,
  onGoingTravelStatusId,
  isDisabledGROAnalysis,
}) {
  const { isTravelBlocked } = useContext(BlockedTravelContext);
  const snackbar = useSnackbar();
  const params = useParams();

  const [editing, setEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [bodyType, setBodyType] = useState();
  const [vehicleType, setVehicleType] = useState();
  const [vehicleImplement, setVehicleImplement] = useState();
  const [palletsQty, setPalletsQty] = useState();
  const [weight, setWeight] = useState();
  const [netWeight, setNetWeight] = useState();
  const [volume, setVolume] = useState();
  const [mopp, setMopp] = useState(false);
  const [dispositions, setDispositions] = useState([]);
  const [selectedDisposition, setSelectedDisposition] = useState('');

  const [bodyTypeList, setBodyTypeList] = useState();
  const [vehicleTypeList, setVehicleTypeList] = useState();
  const [vehicleImplementList, setVehicleImplementList] = useState();

  const [isTractor, setIsTractor] = useState(false);

  const [hasPermissionToEdit, setPermissionToEdit] = useState();
  useState(() => {
    setPermissionToEdit(validatePermission('EDITAR_CARGAS'));
  }, []);

  async function setLoad() {
    try {
      setLoading(true);

      setBodyType(
        load?.vehicleBodyTypes?.map(type => ({
          ...type,
          value: type.id,
          label: type.name,
        }))
      );

      setVehicleType(
        load?.vehicleTypes?.map(type => ({
          ...type,
          value: type.id,
          label: type.name,
        }))
      );

      setVehicleImplement(
        load?.vehicleImplementTypes?.map(implement => ({
          ...implement,
          value: implement.id,
          label: implement.name,
        }))
      );

      setPalletsQty(load.palletNumber);

      setSelectedDisposition(
        load.disposition
          ? { name: load.disposition.name, id: load.disposition.id }
          : dispositions.find(({ name }) => name.toLowerCase() === 'paletizada')
      );

      setNetWeight(load.netWeight);
      setWeight(load.loadWeight);
      setVolume(load.loadVolume);

      setMopp(load.mopp);
    } catch (error) {
      snackbar.show(<Text>Algo deu errado, tente novamente.</Text>, {
        type: 'error',
      });
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (load) setLoad();
  }, [load]);

  async function fetchBodyType() {
    try {
      const response = await api.get('body-types');

      setBodyTypeList(
        response.data.map(type => ({
          label: type.name,
          value: type.id,
        }))
      );
    } catch (error) {
      //
    }
  }

  async function fetchVehicleType() {
    try {
      const response = await api.get('vehicle-types?app=true');

      setVehicleTypeList(
        response.data.map(type => ({
          label: type.name,
          value: type.id,
        }))
      );
    } catch (error) {
      //
    }
  }

  async function fetchVehicleImplement() {
    try {
      const response = await api.get('vehicle-implement-types?app=true');

      setVehicleImplementList(
        response.data.map(type => ({
          value: type.id,
          label: type.name,
        }))
      );
    } catch (error) {
      //
    }
  }

  async function fetchDispositionData() {
    try {
      const response = await api.get('load-disposition');

      setDispositions(response.data);
    } catch (ex) {
      return [];
    }
  }

  useEffect(() => {
    fetchBodyType();
    fetchVehicleType();
    fetchVehicleImplement();
    fetchDispositionData();
  }, []);

  const visiblePallet = useMemo(
    () =>
      selectedDisposition &&
      selectedDisposition?.name?.toLowerCase() === 'paletizada',
    [selectedDisposition]
  );

  useEffect(() => {
    if (
      selectedDisposition &&
      selectedDisposition?.name?.toLowerCase() !== 'paletizada'
    ) {
      setPalletsQty('');
    }
  }, [selectedDisposition]);


  async function validate(data, setErrors) {
    const errorList = {};
    try {
      await vehicleSchema.validate(data, { abortEarly: false });
      setErrors({ ...errorList });
      return true;
    } catch (err) {
      err.inner.forEach(error => {
        errorList[error.path] = error.message;
      });

      setErrors({ ...errorList });
      return false;
    }
  }

  useEffect(() => {
    if (
      vehicleType &&
      vehicleType.some(type => type.id === 18 || type.value === 18)
    ) {
      setIsTractor(true);
    } else {
      setIsTractor(false);
    }
  }, [vehicleType]);

  async function handleSubmit() {
    const isValid = await validate({
      bodyType: bodyType ? bodyType : [],
      vehicleType: vehicleType ? vehicleType : [],
      vehicleImplement: vehicleImplement ? vehicleImplement : [],
      selectedDisposition,
      palletsQty,
      weight: convertNumberToDatabase(weight),
      netWeight: convertNumberToDatabase(netWeight),
      volume,
      isTractor,
    }, setErrors);

    if (!isValid) {
      snackbar.show(<Text>Verifique os campos obrigatórios</Text>, {
        type: 'error',
      });
      return;
    }

    try {
      setLoading(true);

      const data = {
        vehicleTypes: vehicleType.map(el => el.value),
        bodyTypes: bodyType.map(el => el.value),
        vehicle_implement: vehicleImplement.map(el => el.value),
        load_weight: convertNumberToDatabase(weight),
        net_weight: convertNumberToDatabase(netWeight),
        pallet_number: palletsQty || null,
        load_volume: volume,
        mopp,
        load_disposition_id: selectedDisposition.id,
      };

      await api.put(`v3/load/vehicle/${params.id}`, data);
    } catch (error) {
      if (error) {
        snackbar.show(<Text>{error.message}</Text>, {
          type: 'error',
        });
        setEditing(true);
        return;
      }
    } finally {
      snackbar.show(<Text>Carga editada com sucesso!</Text>, {
        type: 'success',
      });
      getLoad();
      setLoading(false);
      setEditing(false);
    }
  }

  return (
    <Card
      loading={loadingCard}
      LoadingBodyComponent={() => (
        <DefaultLoadingBodyComponent linesCount={7} perLine={2} />
      )}
      header={
        <Text color="#464E5F" type="header">
          Veículo
        </Text>
      }
      HeaderRightComponent={
        hasPermissionToEdit && (
          <div>
            {editing && (
              <Button
                variant="secondary"
                loading={loading}
                className="mr-2"
                onClick={() => setEditing(false)}
                id="button-cancelar-card-veiculo"
              >
                <Text color="dark" type="regular">
                  Cancelar
                </Text>
              </Button>
            )}
            <Button
              onClick={editing ? () => handleSubmit() : () => setEditing(true)}
              variant={editing ? 'primary' : 'secondary'}
              disabled={
                (load && load.emitted) ||
                onGoingTravelStatusId(load?.attended?.status_id) ||
                isTravelBlocked
              }
              loading={loading}
              id={`button-${editing ? 'salvar' : 'editar'}-card-veiculo`}
            >
              <Text color={editing ? 'white' : 'dark'} type="regular">
                {editing ? 'Salvar' : 'Editar'}
              </Text>
            </Button>
          </div>
        )
      }
    >
      {editing ? (
        <Row className="form">
          <Col xs={12}>
            <Select
              options={vehicleTypeList}
              label="Veículos *"
              value={vehicleType}
              onChange={value => setVehicleType(value)}
              getOptionLabel={option => option.label}
              getOptionValue={option => option.value}
              multiple
              error={errors.vehicleType}
              isDisabled={load?.loadPolicy?.id || isDisabledGROAnalysis}
              tooltip={
                isDisabledGROAnalysis &&
                'Não é possível edição durante período de análise GRO'
              }
              id="select-veiculos"
            />
          </Col>
          {isTractor && (
            <Col xs={12}>
              <Select
                options={vehicleImplementList}
                label="Tipo de implemento *"
                value={vehicleImplement}
                onChange={value => setVehicleImplement(value)}
                getOptionLabel={option => option.label}
                getOptionValue={option => option.value}
                multiple
                error={errors.vehicleImplement}
                isDisabled={load?.loadPolicy?.id || isDisabledGROAnalysis}
                tooltip={
                  isDisabledGROAnalysis &&
                  'Não é possível edição durante período de análise GRO'
                }
                id="select-implemento"
              />
            </Col>
          )}

          <Col xs={12}>
            <Select
              options={bodyTypeList}
              label="Tipo da carroceria  *"
              value={bodyType}
              onChange={value => setBodyType(value)}
              getOptionLabel={option => option.label}
              getOptionValue={option => option.value}
              multiple
              error={errors.bodyType}
              isDisabled={load?.loadPolicy?.id || isDisabledGROAnalysis}
              tooltip={
                isDisabledGROAnalysis &&
                'Não é possível edição durante período de análise GRO'
              }
              id="select-carroceria"
            />
          </Col>
          <Col md={6} xs={12}>
            <Select
              label="Disposição da carga *"
              options={dispositions}
              getOptionLabel={option => option.name}
              getOptionValue={option => option.id}
              value={selectedDisposition}
              onChange={value => setSelectedDisposition(value)}
              error={errors.selectedDisposition}
              id="select-disposicao-carga"
            />
          </Col>
          <Col md={6} xs={12}>
            <Input
              label={`Pallets${visiblePallet ? ' *' : ''}`}
              type="number"
              value={palletsQty}
              onChange={({ target }) => setPalletsQty(target.value)}
              error={errors.palletsQty}
              disabled={!visiblePallet}
              id="input-pallets"
            />
          </Col>
          <Col md={6} xs={12}>
            <Input
              label="Peso bruto em Kg *"
              type="number"
              value={weight}
              onChange={e => setWeight(e.target.value)}
              error={errors.weight}
              guide={false}
              id="input-peso-bruto"
            />
          </Col>
          <Col md={6} xs={12}>
            <Input
              label="Peso líquido em Kg *"
              type="number"
              value={netWeight}
              onChange={e => setNetWeight(e.target.value)}
              error={errors.netWeight}
              guide={false}
              id="input-peso-liquido"
            />
          </Col>
          <Col md={6} xs={12}>
            <Input
              label="Volume em M³"
              type="number"
              value={volume}
              onChange={e => setVolume(e.target.value)}
              guide={false}
              id="input-volume"
            />
          </Col>

          <Col md={6} xs={12}>
            <Radio.Group
              label="Necessita de MOPP?"
              onChange={({ target }) => setMopp(target.value)}
              value={mopp}
              horizontal
            >
              <Radio value={1} id="radio-necessita-mopp-sim">
                <Text color="dark" type="label">
                  Sim
                </Text>
              </Radio>
              <Radio value={false} id="radio-necessita-mopp-nao">
                <Text color="dark" type="label">
                  Não
                </Text>
              </Radio>
            </Radio.Group>
          </Col>
        </Row>
      ) : (
        <Row>
          <Col xs={12}>
            <Text type="label" color="#464E5F">
              Veículos
            </Text>

            <Text type="regular" color="gray" as="p" className="mt-2">
              {load?.vehicleTypes?.length > 0
                ? load.vehicleTypes.map(type => type.name).join(', ')
                : 'Não informado'}
            </Text>
          </Col>
          {isTractor && (
            <Col xs={12}>
              <Text type="label" color="#464E5F">
                Tipo de implemento
              </Text>
              <Text type="regular" color="gray" as="p" className="mt-2">
                {load?.vehicleImplementTypes?.length > 0
                  ? load.vehicleImplementTypes.map(type => type.name).join(', ')
                  : 'Não informado'}
              </Text>
            </Col>
          )}
          <Col xs={12}>
            <Text type="label" color="#464E5F">
              Tipo de carroceria
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {load?.vehicleBodyTypes?.length > 0
                ? load.vehicleBodyTypes.map(type => type.name).join(', ')
                : 'Não informado'}
            </Text>
          </Col>
          <Col md={6} xs={12}>
            <Text type="label" color="#464E5F">
              Disposição da carga
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {load?.disposition ? load.disposition.name : 'Não informado'}
            </Text>
          </Col>
          <Col md={6} xs={12}>
            <Text type="label" color="#464E5F">
              Pallets
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {load?.palletNumber ? load.palletNumber : 'Não informado'}
            </Text>
          </Col>
          <Col md={6} xs={12}>
            <Text type="label" color="#464E5F">
              Peso bruto
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {load?.loadWeight
                ? conformWeight(load.loadWeight.toString().split('.').join(','))
                : 'Não informado'}
            </Text>
          </Col>
          <Col md={6} xs={12}>
            <Text type="label" color="#464E5F">
              Peso líquido
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {load?.netWeight
                ? conformWeight(load.netWeight.toString().split('.').join(','))
                : 'Não informado'}
            </Text>
          </Col>
          <Col md={6} xs={12}>
            <Text type="label" color="#464E5F">
              Volume em M³
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {load?.loadVolume > 0
                ? conformWeight(load.loadVolume.toString().split('.').join(','))
                : 'Não informado'}
            </Text>
          </Col>
          <Col md={6} xs={12}>
            <Text type="label" color="#464E5F">
              Necessita de MOPP
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {load?.mopp ? 'Sim' : 'Não'}
            </Text>
          </Col>
        </Row>
      )}
    </Card>
  );
}

export default Vehicle;
