import React, { useState, useCallback, useEffect } from 'react';

import dashboardApi from 'services/apis/dashboard';
import api, { cooperplaceApi } from 'services/api';

import { useSnackbar } from 'v3/components/Snackbar';
import { useQuery } from 'hooks';
import { Table, Text, Link, Button, Modal } from 'v3/components';
import { formatDateTime, formatPlate } from 'v3/utils/formatter';
import moment from 'moment';
import { CgArrowsExchange } from 'react-icons/cg';
import Card, { SeeAll, NumericList } from './Card';
import { fetchUserById } from 'utils/fetches';

export default function TravelsPendingLoading({ dashboard, name, id }) {
  const [isFetching, setFetching] = useState(false);
  const [data, setData] = useState({});
  const [filter, setFilter] = useState({});
  const [currentPage, setCurrentPage] = useState({});
  const [pagination, setPagination] = useState({});
  const [loading, setLoading] = useState(false);
  const [daysFromNow, setDaysFromNow] = useState(3);
  const [formattedData, setFormattedData] = useState([]);
  const [groupUser, setGroupUser] = useState();

  const [showModal, setShowModal] = useState(false);
  const [currentTravel, setCurrentTravel] = useState();

  const snackbar = useSnackbar();
  const queryParams = useQuery();

  useEffect(() => {
    setDaysFromNow(moment(new Date()).diff(filter.start_date, 'days'));
  }, [filter.start_date]);

  useEffect(() => {
    fetchUserById().then((response) => {
      const groupMercosul = response?.groups?.find(group => group.slug === 'MERCOSUL');
      setGroupUser(groupMercosul);
    })
  }, [])

  function setQueryParams(filters) {
    const { tags = [], start_date, end_date } = filters;

    queryParams.delete('tags');

    if (tags) {
      tags.forEach(tag => {
        queryParams.append('tags', tag.id);
      });
    }

    if (start_date) {
      queryParams.set('start_date', start_date);
    }

    if (end_date) {
      queryParams.set('end_date', end_date);
    }

    queryParams.set('status', 2);

    if (tags?.length === 0) {
      queryParams.set('no-tags', true);
    } else {
      queryParams.delete('no-tags');
    }
  }

  const fetchTravels = useCallback(async (filters = {}) => {
    try {
      setLoading(true);
      const {
        tags = [],
        clients = [],
        negotiators = [],
        costCenters = [],
        recuseCostCenters = [],
        pcps = [],
        companies = [],
        page = 1,
        start_date,
        end_date,
      } = filters;

      if (page === 1) {
        setFetching(true);
      }

      setQueryParams(filters);
      setFilter(filters);
      setCurrentPage(+page);

      const response = await dashboardApi.get(`/travels-pending-loading`, {
        params: {
          tags: tags?.map(tag => tag.id),
          clients: clients?.map(client => client.id),
          start_date,
          negotiators: negotiators?.map(negotiator => negotiator?.id),
          costCenters: costCenters?.map(costCenter => costCenter?.id),
          recuseCostCenters: recuseCostCenters?.map(costCenters => costCenters?.id),
          pcps: pcps?.map(pcp => pcp?.id),
          companies: companies?.map(company => company?.id),
          end_date: end_date || undefined,
          page,
        },
      });

      setPagination(response.data?.last?.pagination);

      if (page === 1) {
        setData(response.data);
      } else {
        setData(oldData => ({
          ...oldData,
          last: {
            ...oldData.last,
            data: [...oldData.last.data, ...response.data.last.data],
          },
        }));
      }
    } catch (ex) {
      snackbar.show(<Text>Erro ao carregar últimas viagens</Text>, {
        type: 'error',
      });
    } finally {
      setFetching(false);
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    const { total, total_above_three_hours } = data;
    setFormattedData([
      {
        total,
        color: 'warning',
        description: 'pendentes',
      },
      {
        total: total_above_three_hours,
        color: 'error',
        description: 'pendentes há mais de 3 horas',
      },
    ]);
  }, [data]);

  async function handleStatusChange(travel) {
    if (travel?.risk_manager_status !== 'APPROVED') {
      snackbar.show(
        <Text>
          Não é possível atualizar o status da viagem para "Aguardando emissão"
          enquanto a GR não liberar a viagem!
        </Text>,
        {
          type: 'error',
          duration: 80000,
        }
      );

      return;
    }
    try {
      await cooperplaceApi.put(`travels/${travel.attended_id}/status`, {
        statusId: 9,
      });
      snackbar.show(<Text>Status atualizado</Text>, { type: 'success' });
      setShowModal(false);
      fetchTravels();
    } catch (error) {
      let message = 'Erro ao atualizar status';
      if (error.response?.data?.message) {
        message = error.response.data.message;
      }

      snackbar.show(message, { type: 'error' });
    }
  }

  function getModalBody() {
    return (
      <>
        <div className="text-center pt-4">
          <Text color="gray">
            {`Você tem certeza que gostaria de mudar o status da viagem ${currentTravel?.attended_id} para`}
          </Text>
          <Text color="dark" weight="600">
            {' '}
            aguardando emissão?
          </Text>
        </div>
        <div className="text-center pt-4">
          <Button
            className="mr-1"
            variant="error"
            onClick={() => {
              setCurrentTravel(null);
              setShowModal(false);
            }}
          >
            Não
          </Button>
          <Button
            className="ml-1"
            variant="success"
            onClick={() => handleStatusChange(currentTravel)}
          >
            Sim
          </Button>
        </div>
      </>
    );
  }

  return (
    <>
      <Modal
        show={showModal}
        handleClose={() => setShowModal(false)}
        heading={
          <Text type="header" color="dark" weight="500">
            Alterar status
          </Text>
        }
        body={getModalBody()}
      />

      <Card
        title="Viagens aguardando carregamento"
        isLoading={isFetching}
        fetchFn={fetchTravels}
        onFiltersChanged={fetchTravels}
        dashboard={dashboard}
        name={name}
        id={id}
        excludeFilterByBranchOffice={true}
        excludeFilterByRecuseCostCenters={false}
        ListComponent={
          <>
            <Table striped>
              <thead>
                <tr>
                  {
                    groupUser && (
                      <th>
                        <Text weight="500" color="dark">
                          Doc.MERCOSUL
                        </Text>
                      </th>
                    )
                  }
                  <th>
                    <Text weight="500" color="dark">
                      Centro de custo
                    </Text>
                  </th>
                  <th>
                    <Text weight="500" color="dark">
                      Empresa
                    </Text>
                  </th>
                  <th>
                    <Text weight="500" color="dark">
                      Tags da carga
                    </Text>
                  </th>
                  <th>
                    <Text weight="500" color="dark">
                      Nº Pedido Cliente
                    </Text>
                  </th>
                  <th>
                    <Text weight="500" color="dark">
                      Viagem
                    </Text>
                  </th>
                  <th>
                    <Text weight="500" color="dark">
                      Motorista
                    </Text>
                  </th>
                  <th>
                    <Text weight="500" color="dark">
                      Veículo
                    </Text>
                  </th>
                  <th>
                    <Text weight="500" color="dark">
                      Carregamento
                    </Text>
                  </th>
                  <th>
                    <Text weight="500" color="dark">
                      Chegada
                    </Text>
                  </th>
                  <th>
                    <Text weight="500" color="dark">
                      Negociador
                    </Text>
                  </th>
                  <th>
                    <Text weight="500" color="dark">
                      Origem
                    </Text>
                  </th>
                  <th>
                    <Text weight="500" color="dark">
                      Destino
                    </Text>
                  </th>
                  <th>
                    <Text weight="500" color="dark">
                      Cliente
                    </Text>
                  </th>
                  <th>
                    <Text weight="500" color="dark">
                      Placas
                    </Text>
                  </th>
                  <th>
                    <Text weight="500" color="dark">
                      Último Evento
                    </Text>
                  </th>
                  <th>
                    <Text weight="500" color="dark">
                      Alterar status
                    </Text>
                  </th>
                </tr>
              </thead>
              <tbody>
                {data.last?.data?.map(load => {
                  const title = [
                    `Cliente ${load.social_name} - ${load.cgccpf}`,
                    `Motorista ${load.driver_name}`,
                    `Placa veículo tração ${formatPlate(load.plate)}`,
                  ].join('\n');
                  return (
                    <tr>
                      {
                        groupUser && (
                          <td>
                            <Text type="regular" color="gray">
                              {load?.status_mercosul || '-'}
                            </Text>
                          </td>
                        )
                      }
                      <td>
                        <Text type="regular" color="gray">
                          {load?.cost_center || '-'}
                        </Text>
                      </td>
                      <td>
                        <Text type="regular" color="gray">
                          {load?.company_name || '-'}
                        </Text>
                      </td>
                      <td>
                        <Text type="regular" color="gray">
                          {load?.load_tags || '-'}
                        </Text>
                      </td>
                      <td>
                        <Text type="regular" color="gray">
                          {load?.client_order_number || '-'}
                        </Text>
                      </td>
                      <td>
                        <Link
                          to={`/viagens/${load.attended_id}`}
                          title={title}
                          target="_blank"
                        >
                          {load.attended_id}
                        </Link>
                      </td>
                      <td>
                        <Link
                          to={`/motoristas/${load.driver_id}`}
                          title={title}
                          target="_blank"
                        >
                          {load.driver_name}
                        </Link>
                      </td>
                      <td>
                        <Link
                          to={`/veiculos/${load.vehicle_id}`}
                          target="_blank"
                        >
                          {load.vehicle_id}
                        </Link>
                      </td>
                      <td>
                        <Text
                          type="regular"
                          color={load?.above_three_hours ? 'red' : 'gray'}
                          title={
                            load?.above_three_hours
                              ? 'Viagem pendente há mais de 3 horas'
                              : ''
                          }
                        >
                          {formatDateTime(load.scheduled_origin)}
                        </Text>
                      </td>
                      <td>
                        <Text type="regular" color="gray">
                          {formatDateTime(load.arrival_time)}
                        </Text>
                      </td>
                      <td>
                        <Text type="regular" color="gray">
                          {load.user_created}
                        </Text>
                      </td>
                      <td>
                        <Text type="regular" color="dark" truncate>
                          {`${load?.city_origin} - ${load.uf_origin}`}
                        </Text>
                      </td>
                      <td>
                        <Text type="regular" color="dark" truncate>
                          {`${load?.city_destination} - ${load.uf_destination}`}
                        </Text>
                      </td>
                      <td>
                        <Text type="regular" color="gray" truncate>
                          {load.social_name}
                        </Text>
                      </td>
                      <td>
                        <Text type="regular" color="gray">
                          {formatPlate(load.plates)}
                        </Text>
                      </td>
                      <td>
                        <Text type="regular" color="gray">
                          {load.last_log}
                        </Text>
                      </td>

                      <td className="text-center">
                        <Button
                          variant="secondary"
                          size="sm"
                          onClick={() => {
                            setCurrentTravel(load);
                            setShowModal(true);
                          }}
                        >
                          <CgArrowsExchange size={26} />
                        </Button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
            {pagination?.lastPage > 1 && pagination.to < pagination?.total && (
              <div className="text-center mt-3 col-12">
                <Button
                  className="mt-2 ml-auto align-item-center"
                  onClick={() => {
                    fetchTravels({
                      page: currentPage + 1,
                      tags: filter?.tags,
                      clients: filter?.clients,
                      start_date: filter?.start_date,
                      end_date: filter?.end_date,
                      costCenters: filter?.costCenters,
                      recuseCostCenters: filter?.recuseCostCenters,
                      pcps: filter?.pcps,
                      negotiators: filter?.negotiators,
                      companies: filter?.companies,
                    });
                  }}
                  loading={loading}
                >
                  <Text type="regular" color="white" truncate>
                    Ver mais
                  </Text>
                </Button>
              </div>
            )}
            {!!data?.total && (
              <SeeAll
                to={{
                  pathname: '/viagens',
                  search: queryParams.toString(),
                }}
              >
                ver todas as {data?.total} viagens
              </SeeAll>
            )}
          </>
        }
        NumericComponent={
          <NumericList
            data={formattedData}
            description={
              <Text>
                {daysFromNow > 1 && daysFromNow < 61
                ? ` nos últimos ${daysFromNow} dias`
                : ` nos últimos ${filter.start_date} dias`}
              </Text>
            }
          />
        }
      />
    </>
  );
}
