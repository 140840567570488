const Yup = require('yup')

export const vehicleSchema = Yup.object().shape({
  bodyType: Yup.array()
    .of(Yup.object())
    .min(1, 'Informe a carroceria')
    .transform((value) => {
      if (!value) {
        return undefined;
      }
      return value;
    })
    .required('Informe a carroceria'),
  vehicleType: Yup.array()
    .of(Yup.object())
    .min(1, 'Informe a carroceria')
    .transform((value) => {
      if (!value) {
        return undefined;
      }
      return value;
    })
    .required('Informe a carroceria'),
  isTractor: Yup.boolean(),
  vehicleImplement: Yup.array()
    .min(1, 'Informe o implemento')
    .when('isTractor', {
      is: true,
      then: Yup.array().required('Informe o implemento'),
      otherwise: Yup.array().nullable(),
    }),
  selectedDisposition: Yup.object()
    .shape({
      name: Yup.string().required(),
    })
    .nullable()
    .required('Informe a disposição da carga'),
  palletsQty: Yup.number()
    .transform((value, originalValue) => originalValue === "" ? null : value)
    .nullable()
    .when('selectedDisposition.name', (name, schema) =>
      name?.toLowerCase() === 'paletizada'
        ? schema.required('Informe a quantidade de pallets').max(128, 'Máximo de pallet é 128')
        : schema
    ),
  netWeight: Yup
    .number()
    .transform((value, originalValue) => originalValue === 0 ? null : value)
    .nullable()
    .required('Informe o peso líquido'),
  weight: Yup
    .number()
    .transform((value, originalValue) => originalValue === 0 ? null : value)
    .nullable()
    .required('Informe o peso'),

})
