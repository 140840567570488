import React, { useState, useCallback, useEffect } from 'react';
import moment from 'moment';

import dashboardApi from 'services/apis/dashboard';
import { Table, Text, Link, Button } from 'v3/components';
import { formatDateTime } from 'v3/utils/formatter';

import { useQuery } from 'hooks';
import Card, { Number, Description, SeeAll } from './Card';

export default function AvailableLoadsCards({ dashboard, name, id }) {
  const [isFetching, setFetching] = useState(false);
  const [data, setData] = useState({});
  const [filter, setFilter] = useState({});
  const [currentPage, setCurrentPage] = useState({});
  const [pagination, setPagination] = useState({});
  const [loading, setLoading] = useState(false);
  const [daysFromNow, setDaysFromNow] = useState(3);

  const queryParams = useQuery();

  useEffect(() => {
    setDaysFromNow(moment(new Date()).diff(filter.start_date, 'days'));
  }, [filter.start_date]);

  function setQueryParams(filters) {
    const { tags = [] } = filters;
    queryParams.delete('tags');

    if (tags) {
      tags.forEach(tag => {
        queryParams.append('tags', tag.id);
      });
    }

    if (tags?.length === 0) {
      queryParams.set('no-tags', true);
    } else {
      queryParams.delete('no-tags');
    }
  }

  const fetchAvailableLoads = useCallback(async (filters = {}) => {
    try {
      setLoading(true);

      const {
        tags = [],
        clients = [],
        negotiators = [],
        costCenters = [],
        vehicleTypes = [],
        vehicleBodies = [],
        pcps = [],
        companies = [],
        page = 1,
        start_date,
        end_date,
      } = filters;

      if (page === 1) {
        setFetching(true);
      }

      setQueryParams(filters);
      setFilter(filters);
      setCurrentPage(+page);

      const response = await dashboardApi.get('loads-available', {
        params: {
          tags: tags?.map(tag => tag.id),
          clients: clients?.map(client => client.id),
          start_date,
          negotiators: negotiators?.map(negotiator => negotiator?.id),
          costCenters: costCenters?.map(costCenter => costCenter?.id),
          vehicleTypes: vehicleTypes?.map(vehicleType => vehicleType?.id),
          vehicleBodies: vehicleBodies?.map(vehicleBody => vehicleBody?.id),
          pcps: pcps?.map(pcp => pcp?.id),
          companies: companies?.map(company => company?.id),
          end_date: end_date || undefined,
          page,
        },
      });
      setPagination(response.data.last.pagination);

      if (page === 1) {
        setData(response.data);
      } else {
        setData(oldData => ({
          ...oldData,
          last: {
            ...oldData.last,
            data: [...oldData.last.data, ...response.data.last.data],
          },
        }));
      }
    } catch (ex) {
      // Handle exception
    } finally {
      setFetching(false);
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    const startDate = moment().subtract(1, 'months').format('YYYY-MM-DD');
    queryParams.set('startDate', startDate);
  }, []);

  return (
    <Card
      title="Cargas não atendidas"
      isLoading={isFetching}
      fetchFn={fetchAvailableLoads}
      onFiltersChanged={fetchAvailableLoads}
      dashboard={dashboard}
      name={name}
      id={id}
      excludeFilterByBranchOffice={true}
      excludeFilterByVehicleTypes={false}
      excludeFilterByVehicleBodies={false}
      ListComponent={
        <>
          <Table striped>
            <thead>
              <tr>
                <th>
                  <Text weight="500" color="dark">
                    Centro de custo
                  </Text>
                </th>
                <th>
                  <Text weight="500" color="dark">
                    Empresa
                  </Text>
                </th>
                <th>
                  <Text weight="500" color="dark">
                    Tags da carga
                  </Text>
                </th>
                <th>
                  <Text weight="500" color="dark">
                    Nº Pedido Cliente
                  </Text>
                </th>
                <th>
                  <Text weight="500" color="dark">
                    Carga
                  </Text>
                </th>
                <th>
                  <Text weight="500" color="dark">
                    Data máxima agendada
                  </Text>
                </th>
                <th>
                  <Text weight="500" color="dark">
                    Veiculo Implemento Carroceria
                  </Text>
                </th>
                <th>
                  <Text weight="500" color="dark">
                    Origem
                  </Text>
                </th>
                <th>
                  <Text weight="500" color="dark">
                    Destino
                  </Text>
                </th>
                <th>
                  <Text weight="500" color="dark">
                    Cliente
                  </Text>
                </th>
              </tr>
            </thead>
            <tbody>
              {data.last?.data?.map(load => {
                const title = `Cliente ${load.social_name} - ${load.cgccpf}`;
                return (
                  <tr>
                    <td>
                      <Text type="regular" color="gray">
                        {load?.cost_center || '-'}
                      </Text>
                    </td>
                    <td>
                      <Text type="regular" color="gray">
                        {load?.company_name || '-'}
                      </Text>
                    </td>
                    <td>
                      <Text type="regular" color="gray">
                        {load?.load_tags || '-'}
                      </Text>
                    </td>
                    <td>
                      <Text type="regular" color="gray">
                        {load?.client_order_number || '-'}
                      </Text>
                    </td>
                    <td>
                      <Link
                        to={`/cargas/${load.id}`}
                        title={title}
                        target="_blank"
                      >
                        {load.id}
                      </Link>
                    </td>
                    <td>
                      <Text type="regular" color="gray">
                        {formatDateTime(load.scheduled_origin)}
                      </Text>
                    </td>
                    <td>
                      <Text type="regular" color="dark" truncate>
                        {load.vehicle_type}
                      </Text>
                      <Text type="regular" color="dark" truncate>
                        {load.implement_type}
                      </Text>
                      <Text type="regular" color="dark" truncate>
                        {load.vehicle_body}
                      </Text>
                    </td>
                    <td>
                      <Text type="regular" color="gray" truncate>
                        {load.city_origin} - {load.uf_origin}
                      </Text>
                    </td>
                    <td>
                      <Text type="regular" color="gray" truncate>
                        {load.city_destination} - {load.uf_destination}
                      </Text>
                    </td>
                    <td>
                      <Text type="regular" color="gray" truncate>
                        {load.social_name}
                      </Text>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          {pagination?.lastPage > 1 && pagination?.to < pagination?.total && (
            <div className="text-center mt-3 col-12">
              <Button
                className="mt-2 ml-auto align-item-center"
                onClick={() => {
                  fetchAvailableLoads({
                    page: currentPage + 1,
                    tags: filter?.tags,
                    clients: filter?.clients,
                    start_date: filter?.start_date,
                    end_date: filter?.end_date,
                    costCenters: filter?.costCenters,
                    vehicleTypes: filter?.vehicleTypes,
                    vehicleBodies: filter?.vehicleBodies,
                    pcps: filter?.pcps,
                    companies: filter?.companies,
                    negotiators: filter?.negotiators,
                  });
                }}
                loading={loading}
              >
                <Text type="regular" color="white" truncate>
                  Ver mais
                </Text>
              </Button>
            </div>
          )}

          {!!data?.total && (
            <SeeAll
              to={{ pathname: '/cargas', search: queryParams.toString() }}
            >
              ver todas as {data?.total} cargas
            </SeeAll>
          )}
        </>
      }
      NumericComponent={
        <div className="d-flex justify-content-center text-center flex-column">
          <Number color="error">{data?.total}</Number>
          <Description>
          {daysFromNow > 1 && daysFromNow < 61
                ? ` nos últimos ${daysFromNow} dias`
                : ` nos últimos ${filter.start_date} dias`}
          </Description>
        </div>
      }
    />
  );
}
