import React from 'react';

import L from 'leaflet';
import 'leaflet.awesome-markers/dist/leaflet.awesome-markers.css';
import 'leaflet.awesome-markers';

import theme from 'v3/theme';
import Text from '../../Text';
import { Marker } from 'react-leaflet';

import ReactDOMServer from 'react-dom/server';


import { formatDateTime, formatNumber } from 'v3/utils/formatter';
import { Col, Row } from 'react-bootstrap';

import MarkerContainer from './styles';

function MarkerTemperature({ paramsTemperature, background = theme.colors.primary, position }) {

  const customIcon = L.divIcon({
    className: '',
    html: ReactDOMServer.renderToString(
      <MarkerContainer background={background}>
        <Row>
          <Col lg={12} md={12} xs={12} style={{ marginBottom: '5px', marginTop: '5px' }}>
            {paramsTemperature?.Temperatura1 && (
              <Text color="#fff" type="label">
                Temperatura 1: {formatNumber(paramsTemperature?.Temperatura1) || 0} °C  {formatDateTime(paramsTemperature?.datetimeTemperature1)}
              </Text>
            )}
          </Col>
          <Col lg={12} md={12} xs={12} style={{ marginBottom: '5px' }}>
            {paramsTemperature?.Temperatura2 && (
              <Text color="#fff" type="label">
                Temperatura 2: {formatNumber(paramsTemperature?.Temperatura2) || 0} °C  {formatDateTime(paramsTemperature?.datetimeTemperature2)}
              </Text>
            )}
          </Col>
          <Col lg={12} md={12} xs={12} style={{ marginBottom: '5px' }}>
            {paramsTemperature?.Temperatura3 && (
              <Text color="#fff" type="label">
                Temperatura 3: {formatNumber(paramsTemperature?.Temperatura3) || 0} °C  {formatDateTime(paramsTemperature?.datetimeTemperature3)}
              </Text>
            )}
          </Col>
          <Col lg={12} md={12} xs={12} style={{ marginBottom: '5px' }}>
            {paramsTemperature?.Temperatura4 && (
              <Text color="#fff" type="label">
                Temperatura 4: {formatNumber(paramsTemperature?.Temperatura4) || 0} °C  {formatDateTime(paramsTemperature?.datetimeTemperature4)}
              </Text>
            )}
          </Col>
        </Row>
      </MarkerContainer>
    )
  });

  return (
    <Marker position={position} icon={customIcon} />
  );
}

export default MarkerTemperature;
