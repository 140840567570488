import axios from 'axios';
import { getToken } from '../auth';

const notificationApi = axios.create({
  baseURL: process.env.REACT_APP_NOTIFICATION_API_URL,
});

notificationApi.interceptors.request.use(config => {
  const outputConfig = config;

  const token = getToken();
  if (token) {
    outputConfig.headers.Authorization = `Bearer ${token}`;
  }

  return outputConfig;
});

export default notificationApi;
