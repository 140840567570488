import React, { useContext } from 'react';
import { Route, Redirect, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { UserContext } from 'contexts/UserContext';
import { LoadingPage } from 'v3/pages/LoadingPage';
import { UpdatePasswordPage } from 'v3/pages/UpdatePassword';
import { isAuthenticated } from '../../services/auth';

const PrivateRoute = ({ title, component, path, ...rest }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const {
    isUserActive,
    isLoading,
    shouldUserChangePassword,
    lastPasswordUpdate,
    isUserBlocked
  } = useContext(UserContext);
  const authenticated = isAuthenticated();
  const currentDate = new Date();
  const sixMonthsAgo = new Date(currentDate);
  sixMonthsAgo.setMonth(currentDate.getMonth() - 6);
  const lastPasswordUpdateInDate = new Date(lastPasswordUpdate);
  const isLastUpdateLessThanSixMonths = lastPasswordUpdateInDate < sixMonthsAgo;
  const shouldRedirectToChangePasswordModal =
    isUserActive &&
    authenticated &&
    !isUserBlocked &&
    (shouldUserChangePassword || isLastUpdateLessThanSixMonths);

  if (isLoading) {
    return <LoadingPage isLoading={isLoading} />;
  }

  if (shouldRedirectToChangePasswordModal) {
    return (
      <Route {...rest} path="conta/atualizar" component={UpdatePasswordPage} />
    );
  }

  if (isUserActive && authenticated && !isUserBlocked) {
    dispatch({ type: 'CURRENT_PATH', path });

    return <Route {...rest} path={path} component={component} />;
  }
  localStorage.removeItem('token');
  localStorage.removeItem('usuario');
  localStorage.removeItem('updatePushUserId');
  localStorage.removeItem('permissions');
  localStorage.removeItem('bot');
  localStorage.removeItem('issue_permission');

  return (
    <Redirect to={{ pathname: '/login', state: { from: location.pathname } }} />
  );
};

export default PrivateRoute;
