import React, { useState, useCallback, useEffect } from 'react';

import dashboardApi from 'services/apis/dashboard';

import { useSnackbar } from 'v3/components/Snackbar';
import moment from 'moment';
import { useQuery } from 'hooks';
import { Table, Text, Link, Button } from 'v3/components';
import Card, { Number, Description } from './Card';
import { TravelsChangesRequestsModal } from '../Modals/TravelsChangesRequestsModal';
import { cooperplaceApi } from 'services/api';
import { ReproveRequestModal } from '../Modals/TravelsChangesRequestsModal/ReproveRequest';

export default function TravelsChangesRequests({ dashboard, name, id }) {
  const [isFetching, setFetching] = useState(false);
  const [data, setData] = useState({});
  const [filter, setFilter] = useState({});
  const [currentPage, setCurrentPage] = useState({});
  const [pagination, setPagination] = useState({});
  const [daysFromNow, setDaysFromNow] = useState(3);
  const [loading, setLoading] = useState(false);
  const [approveLoading, setApproveLoading] = useState(false);
  const [showTravelsChangesRequestsModal, setShowTravelsChangesRequestsModal] =
    useState(false);
  const [showReproveRequestModal, setShowReproveRequestModal] = useState(false);
  const [travelRequestInfo, setTravelRequestInfo] = useState({
    requestId: null,
    requestType: null,
    requestIdentifier: null,
  });
  const snackbar = useSnackbar();

  const queryParams = useQuery();

  useEffect(() => {
    setDaysFromNow(moment(new Date()).diff(filter.start_date, 'days'));
  }, [filter.start_date]);

  function setQueryParams(filters) {
    const { tags = [], start_date } = filters;

    queryParams.delete('tags');

    if (tags) {
      tags.forEach(tag => {
        queryParams.append('tags', tag.id);
      });
    }

    queryParams.set('start_date', start_date);

    queryParams.set('status', 2);

    if (tags?.length === 0) {
      queryParams.set('no-tags', true);
    } else {
      queryParams.delete('no-tags');
    }
  }

  const fetchTravels = useCallback(async (filters = {}) => {
    try {
      setLoading(true);

      const {
        tags = [],
        clients = [],
        negotiators = [],
        costCenters = [],
        recuseCostCenters = [],
        pcps = [],
        page = 1,
      } = filters;

      if (page === 1) {
        setFetching(true);
      }

      setQueryParams(filters);
      setFilter(filters);
      setCurrentPage(+page);

      const response = await dashboardApi.get(`/travels-request-change`, {
        params: {
          tags: tags?.map(tag => tag.id),
          clients: clients?.map(client => client.id),
          negotiators: negotiators?.map(negotiator => negotiator?.id),
          costCenters: costCenters?.map(costCenter => costCenter?.id),
          recuseCostCenters: recuseCostCenters?.map(costCenters => costCenters?.id),
          pcps: pcps?.map(pcp => pcp?.id),
          page,
        },
      });


      setPagination(response.data.pagination);
      if (page === 1) {
        setData(response.data);
      } else {
        setData(oldData => ({
          ...oldData,
          last: {
            ...oldData.last,
            data: [...oldData.data, ...response.data.data],
          },
        }));
      }
    } catch (ex) {
      snackbar.show(<Text>Erro ao carregar últimas solicitações</Text>, {
        type: 'error',
      });
    } finally {
      setFetching(false);
      setLoading(false);
    }
  }, []);

  function handleCloseTravelsChangesRequestsModal() {
    setShowTravelsChangesRequestsModal(false);
    setTravelRequestInfo({
      requestId: null,
      requestType: null,
      requestIdentifier: null,
    });
  }

  function handleShowRequest(travel) {
    setTravelRequestInfo({
      requestId: travel?.id,
      requestIdentifier: travel?.identifier,
      requestType: travel?.type_description,
    });
    setShowTravelsChangesRequestsModal(true);
  }

  async function handleApproveRequest(id) {
    try {
      setApproveLoading(true);
      await cooperplaceApi.put(`load-request-change/${id}`, {
        status: 'aprovado',
        obs: null,
      });
      snackbar.show(
        <Text color="dark">Solicitação aprovada com sucesso.</Text>,
        { type: 'success' }
      );
    } catch (error) {
      let message =
        error?.response?.data?.message ||
        'Não foi possível aprovar a solicitação. Tente novamente mais tarde.';
      snackbar.show(<Text color="dark">{message}</Text>, { type: 'error' });
    } finally {
      setApproveLoading(false);
      handleCloseTravelsChangesRequestsModal();
      fetchTravels();
    }
  }

  function reproveRequestOpen() {
    setShowTravelsChangesRequestsModal(false);
    setShowReproveRequestModal(true);
  }

  async function handleReproveRequest(obs, id) {
    try {
      setApproveLoading(true);
      await cooperplaceApi.put(`load-request-change/${id}`, {
        status: 'reprovado',
        obs: obs,
      });
      snackbar.show(
        <Text color="dark">Solicitação reprovada com sucesso.</Text>,
        { type: 'success' }
      );
    } catch (error) {
      let message =
        error?.response?.data?.message ||
        'Não foi possível reprovar a solicitação. Tente novamente mais tarde.';
      snackbar.show(<Text color="dark">{message}</Text>, { type: 'error' });
    } finally {
      setApproveLoading(false);
      handleCloseTravelsChangesRequestsModal();
      setShowReproveRequestModal(false);
      fetchTravels();
    }
  }

  return (
    <>
      <Card
        title="Solicitação de cadastros pendentes (Da viagem)"
        isLoading={isFetching}
        fetchFn={fetchTravels}
        onFiltersChanged={fetchTravels}
        excludeFilterByRecuseCostCenters={false}
        dashboard={dashboard}
        name={name}
        id={id}
        excludeFilterByBranchOffice={true}
        excludeFilterByDate
        ListComponent={
          <>
            <Table striped>
              <thead>
                <tr>
                  <th>
                    <Text weight="500" color="dark">
                      Viagem
                    </Text>
                  </th>
                  <th>
                    <Text weight="500" color="dark">
                      Carga
                    </Text>
                  </th>
                  <th>
                    <Text weight="500" color="dark">
                      Negociador
                    </Text>
                  </th>
                  <th>
                    <Text weight="500" color="dark">
                      Origem
                    </Text>
                  </th>
                  <th>
                    <Text weight="500" color="dark">
                      Destino
                    </Text>
                  </th>
                  <th>
                    <Text weight="500" color="dark">
                      Cliente
                    </Text>
                  </th>
                  <th>
                    <Text weight="500" color="dark">
                      Motorista
                    </Text>
                  </th>
                  <th>
                    <Text weight="500" color="dark">
                      Detalhes solicitação
                    </Text>
                  </th>
                </tr>
              </thead>
              <tbody>
                {data?.data?.map(travel => {
                  return (
                    <tr key={travel?.id}>
                      <td>
                        <Link
                          to={`/viagens/${travel.attended_id}`}
                          target="_blank"
                        >
                          {travel.attended_id}
                        </Link>
                      </td>
                      <td>
                        <Link to={`/cargas/${travel.load_id}`} target="_blank">
                          {travel.load_id}
                        </Link>
                      </td>
                      <td>
                        <Text type="regular" color="gray">
                          {travel.negoatiator_name}
                        </Text>
                      </td>
                      <td>
                        <Text type="regular" color="gray" truncate>
                          {travel.city_origin?.toString('utf8')} -{' '}
                          {travel.uf_origin}
                        </Text>
                      </td>
                      <td>
                        <Text type="regular" color="gray" truncate>
                          {travel.city_destination} - {travel.uf_destination}
                        </Text>
                      </td>
                      <td>
                        <Text type="regular" color="gray" truncate>
                          {travel.social_name}
                        </Text>
                      </td>
                      <td>
                        <Text type="regular" color="gray" truncate>
                          {travel.driver_name}
                        </Text>
                      </td>
                      <td className="d-flex justify-content-center align-items-center">
                        <Button
                          onClick={() => {
                            handleShowRequest(travel);
                          }}
                        >
                          Ver
                        </Button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
            {pagination?.lastPage > 1 && pagination?.to < pagination?.total && (
              <div className="text-center mt-3 col-12">
                <Button
                  className="mt-2 ml-auto align-item-center"
                  onClick={() => {
                    fetchTravels({
                      page: currentPage + 1,
                      tags: filter?.tags,
                      clients: filter?.clients,
                    });
                  }}
                  loading={loading}
                >
                  <Text type="regular" color="white" truncate>
                    Ver mais
                  </Text>
                </Button>
              </div>
            )}
          </>
        }
        NumericComponent={
          <div className="d-flex justify-content-center text-center flex-column">
            <Number color="warning">{data.total}</Number>
            <Description>
            {daysFromNow > 1 && daysFromNow < 61
                ? ` nos últimos ${daysFromNow} dias`
                : ` nos últimos ${filter.start_date} dias`}
            </Description>
          </div>
        }
      />
      <TravelsChangesRequestsModal
        isOpen={showTravelsChangesRequestsModal}
        onClose={handleCloseTravelsChangesRequestsModal}
        requestId={travelRequestInfo?.requestId}
        requestType={travelRequestInfo?.requestType}
        requestIdentifier={travelRequestInfo?.requestIdentifier}
        approveRequest={handleApproveRequest}
        openReproveRequestModal={reproveRequestOpen}
        loading={approveLoading}
      />
      <ReproveRequestModal
        isOpen={showReproveRequestModal}
        requestId={travelRequestInfo?.requestId}
        onClose={() => setShowReproveRequestModal(false)}
        reproveRequest={handleReproveRequest}
        loading={approveLoading}
      />
    </>
  );
}
