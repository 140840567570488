import React, { useState, useEffect } from 'react';
import { Button, DataTable, Input, Link, Modal, Text } from 'v3/components';
import api, { cooperplaceApi } from 'services/api';
import { useSnackbar } from 'v3/components/Snackbar';
import { Col, Row } from 'react-bootstrap';
import { Divider } from 'antd';
import { DestinationContainer } from './styles';
import ModalConfirmation from '../ModalConfirmation';

export default function LoadOfferModal({ showModal, setShowModal, driverId }) {
  const snackbar = useSnackbar();
  const [loadData, setLoadData] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingOffer, setLoadingOffer] = useState(false);
  const [loadCodeInput, setLoadCodeInput] = useState('');
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [selectedLoadId, setSelectedLoadId] = useState(null);
  const [filterTypeText, setFilterTypeText] = useState('');

  useEffect(() => {
    let isMounted = true;

    const fetchLoadsOffer = async () => {
      setLoading(true);
      try {
        const response = await cooperplaceApi.get(`/easyFleet/${driverId}?limit=10`);
        if (isMounted) {
          setLoadData(response?.data?.loads || []);
          setFilterTypeText(response?.data.wasLocationUsed ? 'Cargas disponíveis com base no veículo, implemento e localização atual do motorista' : 'Cargas disponíveis com base apenas no veículo e implemento')
        }
      } catch (error) {
        if (isMounted) {
          setLoadData([]);
          const errorMessage = error?.response?.data?.message || 'Erro ao buscar cargas para o motorista';
          snackbar.show(<Text>{errorMessage}</Text>, { type: 'error' });
        }
      } finally {
        if (isMounted) {
          setLoading(false);
        }
      }
    };

    if (showModal) {
      fetchLoadsOffer();
    }

    return () => {
      isMounted = false;
    };
  }, [driverId, showModal]);

  async function offerLoad(loadId) {
    setLoadingOffer(true);
    try {
      const offer = await cooperplaceApi.post('/load-offer', {
        person_id: driverId,
        load_id: loadId,
      });
      setLoadCodeInput('');
      snackbar.show(<Text>{`${offer?.data?.offer?.type_id === 5 ? 'Carga ofertada via SMS com sucesso!' : 'Carga ofertada via whatsapp com sucesso!'}`}</Text>, { type: 'success' });
    } catch (error) {
      snackbar.show(
        <Text>{error?.response?.data?.message || 'Erro ao ofertar a carga'}</Text>,
        {
          type: 'error',
        }
      );
    } finally {
      setLoadingOffer(false);
    }
  }

  async function onChangeLimit(limit) {
    try {
      setLoading(true);
      const response = await cooperplaceApi.get(
        `/easyFleet/${driverId}?limit=${limit}&page=1`
      );
      setLoadData(response?.data?.loads || { data: [], meta: { current_page: 1, last_page: 1, total: 0 } });
      setFilterTypeText(response?.data.wasLocationUsed ? 'Cargas disponíveis com base no veículo, implemento e localização atual do motorista' : 'Cargas disponíveis com base apenas no veículo e implemento')
    } catch (error) {
      snackbar.show(
        <Text>{error?.response?.data?.message || 'Erro ao buscar cargas para o motorista'}</Text>,
        {
          type: 'error',
        }
      );
    } finally {
      setLoading(false);
    }
  }

  async function onChangePage(page) {
    try {
      setLoading(true);
      const response = await cooperplaceApi.get(
        `/easyFleet/${driverId}?limit=${loadData?.meta?.per_page}&page=${page}`
      );
      setLoadData(response?.data?.loads);
      setFilterTypeText(response?.data.wasLocationUsed ? 'Cargas disponíveis com base no veículo, implemento e localização atual do motorista' : 'Cargas disponíveis com base apenas no veículo e implemento')
    } catch (error) {
      snackbar.show(
        <Text>{error?.response?.data?.message || 'Erro ao buscar cargas para o motorista'}</Text>,
        {
          type: 'error',
        }
      );
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <Modal
        show={showModal}
        handleClose={() => setShowModal(false)}
        heading="Oferta de Carga"
        body={
          <>
            <Row className="justify-content-center align-items-end">
              <Col md={2} sm={2}>
                <Input
                  label="Ofertar pelo código da carga"
                  value={loadCodeInput}
                  onChange={(e) => {
                    setLoadCodeInput(e.target.value);
                  }}
                />
              </Col>
              <Col md={1} sm={2}>
                <Button
                  title="Ofertar Carga"
                  style={{ marginRight: '10px' }}
                  disabled={!loadCodeInput}
                  loading={loading || loadingOffer}
                  onClick={() => {
                    setSelectedLoadId(loadCodeInput);
                    setShowConfirmationModal(true);
                  }}
                >
                  <Text>Ofertar</Text>
                </Button>
              </Col>
            </Row>
            {!loading && <Row className="justify-content-start align-items-end ml-1 mt-2 mb-2">
              <Text fontSize={16} type="label" weight={500} color="dark">
                {filterTypeText}
              </Text>
            </Row>}
            <DataTable
              data={loadData?.data || []}
              loading={loading}
              withFilterOption={false}
              defaultPerPage={10}
              page={loadData?.meta?.current_page}
              pages={loadData?.meta?.last_page}
              total={loadData?.meta?.total}
              onPageChanged={onChangePage}
              onPerPageChanged={onChangeLimit}
              perPageOptions={[{ v: 10 }, { v: 20 }, { v: 30 }]}
              tableStyles={{
                table: {
                  fontSize: '8px',
                  width: '100%',
                },
                td: {
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  padding: '5px 8px',
                },
                th: {
                  padding: '5px 8px',
                  fontWeight: 'bold',
                  fontSize: '12px',
                },
              }}
              columns={[
                {
                  name: 'Ação',
                  selector: (row) => (
                    <Button
                      title="Ofertar Carga"
                      style={{ marginRight: '10px' }}
                      loading={loading || loadingOffer}
                      onClick={() => {
                        setSelectedLoadId(row?.id);
                        setShowConfirmationModal(true);
                      }}
                    >
                      <Text>Ofertar</Text>
                    </Button>
                  ),
                },
                {
                  name: 'Carga',
                  selector: (row) => (
                    <Link to={`/cargas/${row?.id}`}>{row?.id || '-'}</Link>
                  ),
                },
                {
                  name: 'Cliente',
                  selector: (row) => (
                    <Text type="label" weight={500} color="dark">
                      {row?.taker?.label || '-'}
                    </Text>
                  ),
                },
                {
                  name: 'Origem',
                  selector: (row) => (
                    <Text type="label" weight={500} color="dark">
                      {row?.loadOrigins[0]?.formattedAddress || '-'}
                    </Text>
                  ),
                },
                {
                  name: 'Destino',
                  selector: (row) => (
                    <DestinationContainer>
                      {row?.loadDestinations.map(
                        ({ formattedAddress, id: destinationId }, index, arr) => (
                          <React.Fragment key={destinationId}>
                            <Text type="label" weight={500} color="dark">
                              {formattedAddress}
                            </Text>
                            {arr.length - 1 !== index && <Divider />}
                          </React.Fragment>
                        )
                      )}
                    </DestinationContainer>
                  ),
                },
                {
                  name: 'Produto',
                  selector: (row) => (
                    <Text type="label" weight={500} color="dark">
                      {`${row?.product?.name || 'Sem nome'} - ${row?.product?.ncm || 'Sem NCM'
                        }`}
                    </Text>
                  ),
                },
                {
                  name: 'Base de frete (Tomador)',
                  selector: (row) => (
                    <Text type="label" weight={500} color="dark">
                      {new Intl.NumberFormat('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                      }).format(row?.takerValue || 0)}
                    </Text>
                  ),
                },
              ]}
            />
          </>
        }
      />

      {showConfirmationModal && (
        <ModalConfirmation
          showModal={showConfirmationModal}
          setShowModal={setShowConfirmationModal}
          text={`Você tem certeza que deseja ofertar esta carga? (código: ${selectedLoadId})`}
          onConfirm={() => {
            offerLoad(selectedLoadId);
            setShowConfirmationModal(false);
          }}
        />
      )}
    </>
  );
}
