import React, { useContext, useEffect } from 'react';
import api, { cooperplaceApi } from 'services/api';

import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FaExternalLinkAlt } from 'react-icons/fa';


import Text from 'v3/components/Text';
import Select from 'v3/components/Select';

import { useTravel } from 'v3/pages/Travel';
import { useCurrentUser } from 'hooks';
import { useSnackbar } from 'v3/components/Snackbar';
import { BlockedTravelContext } from 'contexts/BlockedTravelProvider';

import { ceilDecimals } from 'v3/utils/functions';

import {
  clientLoadFieldsToSum,
  inputsLoadConditionsToShow,
} from 'v3/pages/Load/Register/PriceData/utils';

function ShippingCompany({ errors, editing, shippingCompany, setShippingCompany }) {

  const travel = useTravel();
  const snackbar = useSnackbar();
  const user = useCurrentUser();

  const {
    setIsFetchingBenner,
  } = useContext(BlockedTravelContext);

  const updateShippingCompanyFromBenner = async cnpj => {
    if (!cnpj) return;
    try {
      setIsFetchingBenner(true);
      const url = `shipping-company/importFromBenner?cpfCnpj=${cnpj}`;
      const { data } = await cooperplaceApi.get(url);
      snackbar.show(<Text>{data?.message}</Text>, {
        type: 'success',
      });
    } catch (error) {
      snackbar.show(<Text>Transportadora não encontrada no Benner!</Text>, {
        type: 'error',
      });
    } finally {
      setIsFetchingBenner(false);
    }
  };

  async function handleChangeShippingCompany(value) {

    await updateShippingCompanyFromBenner(value?.cgccpf);

    const isGoldAggregateLevel =
      value?.shippingCompanies?.description_service_level?.toLowerCase() ===
      'agregado ouro';
    let updatedFareCompany = travel?.data?.loads?.fareCompany;
    let updatedTotalInputs = travel?.data?.loads?.totalInputs;
    const result = recalculateFreightValues();
    updatedFareCompany = result?.receiveClientValues - result?.inputsValues;

    if (isGoldAggregateLevel) {
      updatedTotalInputs =
        result?.inputsValues - Number(travel?.data?.loads?.adValorem);
      updatedFareCompany = result?.receiveClientValues - updatedTotalInputs;
    } else {
      updatedFareCompany = result?.receiveClientValues - result?.inputsValues;
      updatedTotalInputs = result?.inputsValues;
    }

    const travelMargin = ceilDecimals(
      ((updatedFareCompany - travel?.data?.balance) / updatedFareCompany) * 100,
      2
    );

    travel.setData(old => ({
      ...old,
      margin: travelMargin,
      load: {
        ...old.load,
        fareCompany: updatedFareCompany,
        totalInputs: updatedTotalInputs,
      },
    }));

    setShippingCompany(value)
  }

  async function fetchShippingCompanies({ search, companyId }) {
    try {
      const response = await api.get(`persons/shipping-company`, {
        params: { search, company_id: companyId },
      });

      return response.data;
    } catch (ex) {
      return [];
    }
  }

  function recalculateFreightValues() {
    let receiveClientValues = 0;
    clientLoadFieldsToSum.forEach(field => {
      const fieldToCamelCase = field?.includes('_')
        ? field
          .split('_')
          .map((word, idx) =>
            idx === 0
              ? word
              : word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
          )
          .join('')
        : field?.toLowerCase();
      receiveClientValues += Number(travel?.data?.loads[fieldToCamelCase]);
    });

    let inputsValues = 0;
    if (travel?.data?.loads?.cargInputs) {
      travel.data.loads.cargInputs.forEach(item => {
        inputsValues += Number(item?.value);
      });
    }
    if (
      inputsLoadConditionsToShow.includes(
        travel?.data?.loads?.tollObservation?.code
      ) &&
      travel?.data?.loads?.tollValue > 0
    ) {
      inputsValues += Number(travel?.data?.loads?.tollValue);
    }
    if (
      inputsLoadConditionsToShow.includes(
        travel?.data?.loads?.dischargeObservation?.code
      ) &&
      travel?.data?.loads?.dischargeValue > 0
    ) {
      inputsValues += Number(travel?.data?.loads?.dischargeValue);
    }
    if (
      inputsLoadConditionsToShow.includes(
        travel?.data?.loads?.grissAdObservation?.code
      )
    ) {
      inputsValues +=
        Number(travel?.data?.loads?.gris) +
        Number(travel?.data?.loads?.adValorem);
    }

    if (
      inputsLoadConditionsToShow.includes(
        travel?.data?.loads?.chargeObservation?.code
      ) &&
      travel?.data?.loads?.chargeValue > 0
    ) {
      inputsValues += Number(travel?.data?.loads?.chargeValue);
    }
    if (
      inputsLoadConditionsToShow.includes(
        travel?.data?.loads?.ferryObservation?.code
      )
    ) {
      inputsValues += Number(travel?.data?.loads?.ferry);
    }
    if (
      inputsLoadConditionsToShow.includes(
        travel?.data?.loads?.icmsObservation?.code
      ) &&
      travel?.data?.loads?.ICMS > 0
    ) {
      inputsValues += Number(travel?.data?.loads?.icms);
    }

    return { receiveClientValues, inputsValues };
  }

  useEffect(() => {
    setShippingCompany(travel?.data?.shipper);
  }, [editing]);

  if (travel.isLoading) {
    return null;
  }

  return (
    <>
      {editing ? (
        <Row style={{ marginBottom: '1rem' }}>
          <Col xs={12} md={12}>
            <Text weight={500} >
              Transportadora
            </Text>
          </Col>
          <Col xs={12}>
            <Select.Async
              label={'Razão Social *'}
              value={shippingCompany}
              onSearch={search =>
                fetchShippingCompanies({
                  search,
                  companyId: user.company_id,
                })
              }
              getOptionLabel={option => {
                if (option.socialName && option.cpfCnpj) {
                  return `${option.socialName} - ${option.cpfCnpj}`;
                }
                return `${option.social_name} - ${option.cgccpf}`;
              }}
              getOptionValue={option => option.id}
              onChange={item => {
                handleChangeShippingCompany(item);
              }}
              error={errors}

            />
          </Col>
        </Row >
      ) : (
        <Row>
          <Text color="dark" type="header" weight={500}>
            Transportadora
          </Text>

          <Col xs={12}>
            <Text type="label" color="dark">
              Razão Social
            </Text>
            <Text>
              <Link
                target={travel.data.shipper?.id && '_blank'}
                to={
                  travel.data.shipper?.id &&
                  `/transportadoras/${travel.data.shipper?.id}`
                }
              >
                <Text type="regular" color="gray" as="p" className="mt-2">
                  {travel.data.shipper?.label || 'Não informado'}
                  <FaExternalLinkAlt
                    color="#B5B5C3"
                    size={12}
                    className="ml-2 mb-1"
                  />
                </Text>
              </Link>
            </Text>
          </Col>

          <Col xs={6}>
            <Text type="label" color="dark">
              Telefone
            </Text>

            <Text type="regular" color="gray" as="p" className="mt-2">
              {travel?.data?.shipper?.phone || 'Não informado'}
            </Text>

          </Col>
          <Col xs={6}>
            <Text type="label" color="dark">
              Nível de Serviço
            </Text>

            <Text type="regular" color="gray" as="p" className="mt-2">
              {travel?.data?.shipper?.shippingCompany?.descriptionServiceLevel || 'Não informado'}
            </Text>

          </Col>
        </Row>
      )}
    </>
  );
}

export default ShippingCompany;

