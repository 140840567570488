import React, { useState, useEffect, useCallback } from 'react';
import {
  fetchCities,
  getProvinces,
  fetchDriver,
} from 'pages/relatorios/acompanhamento-viagem/utils';
import { Col, Row } from 'react-bootstrap';
import { Select, Button, Text, Input } from 'v3/components';
import theme from 'v3/theme';
import api, { cooperplaceApi } from 'services/api';
import { usePermission } from 'hooks';
import { fetchCountries } from 'utils/fetches';
import { useSnackbar } from 'v3/components/Snackbar';
import { FaSearch } from 'react-icons/fa';

export function Filter({
  filters,
  setFilters,
  setLoading,
  loading,
  onFilter
}) {
  const snackbar = useSnackbar();
  const [provincesOptions, setProvincesOptions] = useState([]);
  const [countriesOptions, setCountriesOptions] = useState([]);
  const [vehiclesOptions, setVihiclesOptions] = useState([]);
  const [vehicleBodiesTypesOptions, setVehicleBodiesTypesOptions] = useState([]);
  const [vehicleImplementsTypesOptions, setVehicleImplementsTypesOptions] = useState([]);

  usePermission('VISUALIZAR_FROTA_FACIL', { redirect: true });

  async function fetchVehicle(search) {
    try {
      const response = await api.get('vehicle/license_plate', {
        params: { search },
      });
      return response.data;
    } catch (error) {
      return [];
    }
  }

  async function fetchVehicleTypes(search) {
    try {
      const response = await cooperplaceApi.get('/vehicle-types', {
        params: { search },
      });
      return response.data;
    } catch (error) {
      return [];
    }
  }

  async function fetchVehicleImplementTypes(search) {
    try {
      const response = await cooperplaceApi.get('/vehicle-implement-types', {
        params: { search },
      });
      return response.data;
    } catch (error) {
      return [];
    }
  }

  async function fetchVehicleBodiesTypes(search) {
    try {
      const response = await cooperplaceApi.get('/body-types', {
        params: { search },
      });
      return response.data;
    } catch (error) {
      return [];
    }
  }

  async function fetchOptions() {
    const provinces = await getProvinces();
    setProvincesOptions(provinces);
    const countries = await fetchCountries()
    setCountriesOptions(countries)
    const vehicles = await fetchVehicleTypes()
    setVihiclesOptions(vehicles.filter(vehicle => vehicle.appVisible))
    const vehicleBodiesTypes = await fetchVehicleBodiesTypes()
    setVehicleBodiesTypesOptions(vehicleBodiesTypes.filter(vehicle => vehicle.appVisible))
    const vehicleImplementsTypes = await fetchVehicleImplementTypes()
    setVehicleImplementsTypesOptions(vehicleImplementsTypes.filter(vehicle => vehicle.appVisible))
  }

  useEffect(() => {
    fetchOptions();
  }, []);

  const updateFilters = useCallback(
    async () => {
      try {
        await cooperplaceApi.post(`/user/definitions`, {
          type: 'easy_frota_filters',
          definition: JSON.stringify(filters),
        })
      } catch (error) {
        snackbar.show(<Text>Erro ao salvar preferencia de filtros</Text>, {
          type: 'error',
        });
      }
    },[filters]
  );

  function handleFilter() {
    setLoading(true);
    onFilter()
    updateFilters()
  }

  return (
    <>
      <Row>
        <Col sm={12} md={3} lg={2}>
          <Select.Async
            label="Motorista"
            onSearch={fetchDriver}
            value={filters?.driver}
            onChange={value => {
              setFilters(old => ({ ...old, driver: value }));
            }}
            getOptionLabel={option => option.social_name}
            getOptionValue={option => option.id}
            modalHeading="Selecione um motorista"
            modalBodyTitle="Motoristas:"
            multiple
            horizontal
          />
        </Col>
        <Col sm={12} md={3} lg={2}>
          <Select
            label="Perfil verificado"
            value={filters?.verify}
            onChange={value => {
              setFilters(old => ({ ...old, verify: value }));
            }}
            options={[
              { label: 'Sim', value: 'yes' },
              { label: 'Não', value: 'no' },
            ]}
            modalHeading="Selecione uma opção"
            modalBodyTitle="Perfil verificado:"
            horizontal
          />
        </Col>
        <Col sm={12} md={3} lg={2}>
          <Input
            label="Telefone"
            value={filters?.phoneNumber}
            type='number'
            onChange={event => {
              setFilters(old => ({
                ...old,
                phoneNumber: event.target.value,
              }));
            }}
            placeholder="Digite um telefone"
          />
        </Col>
        <Col sm={12} md={3} lg={2}>
          <Select.Async
            label="Município atual"
            onSearch={fetchCities}
            value={filters?.currentCity}
            onChange={value => {
              setFilters(old => ({ ...old, currentCity: value }));
            }}
            getOptionLabel={option => {
              if (option.province?.uf) {
                return `${`${option.name} - ${option.province?.uf}`}`;
              }
              return option.name;
            }}
            getOptionValue={option =>
              `${`${option.name}-${option.province?.uf}`}`
            }
            modalHeading="Selecione um município"
            modalBodyTitle="Municípios:"
            multiple
            horizontal
          />
        </Col>
        <Col sm={12} md={3} lg={2}>
          <Select
            value={filters?.currentProvince}
            onChange={value => {
              setFilters(old => ({ ...old, currentProvince: value }));
            }}
            placeholder="Selecione..."
            label="Estado Atual"
            labelTextColor={theme.colors.dark}
            options={provincesOptions}
            getOptionLabel={option => `${option.label}`}
            getOptionValue={option => option.label}
            modalHeading="Selecione um estado"
            modalBodyTitle="Estados:"
            multiple
            horizontal
          />
        </Col>
        <Col sm={12} md={3} lg={2}>
          <Select
            value={filters?.currentCountry}
            onChange={value => {
              setFilters(old => ({ ...old, currentCountry: value }));
            }}
            placeholder="Selecione..."
            label="País atual"
            labelTextColor={theme.colors.dark}
            options={countriesOptions}
            getOptionLabel={option => `${option.name}`}
            getOptionValue={option => option.name}
            modalHeading="Selecione um país"
            modalBodyTitle="País:"
            multiple
            horizontal
          />
        </Col>
        <Col sm={12} md={3} lg={2}>
          <Select
            label="Ativo no portal"
            value={filters?.activeInPortal}
            onChange={value => {
              setFilters(old => ({ ...old, activeInPortal: value }));
            }}
            options={[
              { label: 'Sim', value: 'yes' },
              { label: 'Não', value: 'no' },
            ]}
            modalHeading="Selecione uma opção"
            modalBodyTitle="Ativo no portal:"
            horizontal
          />
        </Col>
        <Col sm={12} md={3} lg={2}>
          <Select.Async
            onSearch={fetchVehicle}
            placeholder="Selecione..."
            label="Placa principal"
            value={filters?.plate}
            onChange={value => {
              setFilters(old => ({ ...old, plate: value }));
            }}
            getOptionLabel={option => option.plate}
            getOptionValue={option => option.id}
            modalHeading="Selecione uma placa"
            modalBodyTitle="Placas:"
            multiple
            horizontal
          />
        </Col>
        <Col sm={12} md={3} lg={2}>
          <Select
            value={filters?.vehicleType}
            onChange={value => {
              setFilters(old => ({ ...old, vehicleType: value }));
            }}
            placeholder="Selecione..."
            label="Tipo de veículo"
            labelTextColor={theme.colors.dark}
            options={vehiclesOptions}
            getOptionLabel={option => option.name}
            getOptionValue={option => option.id}
            modalHeading="Selecione um veículo"
            modalBodyTitle="Tipos de veículo:"
            multiple
            horizontal
          />
        </Col>
        <Col sm={12} md={3} lg={2}>
          <Select.Async
            label="Município moradia"
            onSearch={fetchCities}
            value={filters?.homeCity}
            onChange={value => {
              setFilters(old => ({ ...old, homeCity: value }));
            }}
            getOptionLabel={option => {
              if (option.province?.uf) {
                return `${`${option.name} - ${option.province?.uf}`}`;
              }
              return option.name;
            }}
            getOptionValue={option =>
              `${`${option.name}-${option.province?.uf}`}`
            }
            modalHeading="Selecione um município"
            modalBodyTitle="Municípios:"
            multiple
            horizontal
          />
        </Col>
        <Col sm={12} md={3} lg={2}>
          <Select
            value={filters?.homeProvince}
            onChange={value => {
              setFilters(old => ({ ...old, homeProvince: value }));
            }}
            placeholder="Selecione..."
            label="Estado moradia"
            labelTextColor={theme.colors.dark}
            options={provincesOptions}
            getOptionLabel={option => `${option.label}`}
            getOptionValue={option => option.label}
            modalHeading="Selecione um estado"
            modalBodyTitle="Estados:"
            multiple
            horizontal
          />
        </Col>
        <Col sm={12} md={3} lg={2}>
          <Select
            value={filters?.homeCountry}
            onChange={value => {
              setFilters(old => ({ ...old, homeCountry: value }));
            }}
            placeholder="Selecione..."
            label="País moradia"
            labelTextColor={theme.colors.dark}
            options={countriesOptions}
            getOptionLabel={option => `${option.name}`}
            getOptionValue={option => option.name}
            modalHeading="Selecione um país"
            modalBodyTitle="País:"
            multiple
            horizontal
          />
        </Col>
        <Col sm={12} md={3} lg={2}>
          <Select
            label="Ativo no App"
            value={filters?.activeInApp}
            onChange={value => {
              setFilters(old => ({ ...old, activeInApp: value }));
            }}
            options={[
              { label: 'Ativo', value: 'active' },
              { label: 'Inativo', value: 'inactive' },
              { label: 'Não acessado', value: 'notAccessed' },
            ]}
            modalHeading="Selecione uma opção"
            modalBodyTitle="Ativo no App:"
            horizontal
          />
        </Col>
        <Col sm={12} md={3} lg={2}>
          <Select
            value={filters?.bodyType}
            onChange={value => {
              setFilters(old => ({ ...old, bodyType: value }));
            }}
            placeholder="Selecione..."
            label="Tipo de carroceria"
            labelTextColor={theme.colors.dark}
            options={vehicleBodiesTypesOptions}
            getOptionLabel={option => option.name}
            getOptionValue={option => option.id}
            modalHeading="Selecione uma carroceria"
            modalBodyTitle="Tipos de carroceria:"
            multiple
            horizontal
          />
        </Col>
        <Col sm={12} md={3} lg={2}>
          <Select
            value={filters?.implementType}
            onChange={value => {
              setFilters(old => ({ ...old, implementType: value }));
            }}
            placeholder="Selecione..."
            label="Tipo de implemento"
            labelTextColor={theme.colors.dark}
            options={vehicleImplementsTypesOptions}
            getOptionLabel={option => option.name}
            getOptionValue={option => option.id}
            modalHeading="Selecione um implemento"
            modalBodyTitle="Tipos de implemento:"
            multiple
            horizontal
          />
        </Col>
        <Col sm={12} md={3} lg={2}>
          <Select
            label="Localizado até"
            value={filters?.locatedUpTo}
            onChange={value => {
              setFilters(old => ({ ...old, locatedUpTo: value }));
            }}
            options={[
              { label: 'Hoje', value: 'today' },
              { label: '3 dias', value: 'threeDays' },
              { label: '15 dias', value: 'fifteenDays' },
            ]}
            modalHeading="Selecione um tempo"
            modalBodyTitle="tempos:"
            horizontal
          />
        </Col>
        <Col sm={12} md={3} lg={2} style={{ display: 'flex', alignItems: 'end' }}>
          <Button
            variant="success"
            onClick={() => handleFilter()}
            loading={loading}
            style={{
              paddingLeft: '15px',
              paddingRight: '15px',
              minWidth: 'auto',
              flex: 1,
              maxWidth: '200px'
            }}
          >
              Filtrar
          </Button>
        </Col>
      </Row>
    </>
  );
}
