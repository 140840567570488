import React from 'react';

import L from 'leaflet';
import 'leaflet.awesome-markers/dist/leaflet.awesome-markers.css';
import 'leaflet.awesome-markers';

import { Marker } from 'react-leaflet';

import ReactDOMServer from 'react-dom/server';
import MarkerContainer from './styles';
import Text from '../../Text';
import theme from 'v3/theme';

export default function MarkerMap({ background = theme.colors.primary, position, text, color }) {

  const customIcon = L.divIcon({
    className: '',
    html: ReactDOMServer.renderToString(
      <MarkerContainer background={background}>
        {text && (
          <Text color={color} type="label">
            {text}
          </Text>
        )}
      </MarkerContainer>
    )
  });

  return (
    <Marker position={position} icon={customIcon} />
  )
}
