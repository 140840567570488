function isNullOrEmpty(value) {
  if (value === null || value === undefined) {
    return 'wwwwwwwwwwwww';
  } else return value;
}

function isNumberValid(numberValue) {
  if (numberValue === undefined || numberValue === null) {
    return Infinity;
  } else return numberValue;
}

const getValueOrDefault = (value, defaultValue) => (value === null || value === undefined ? defaultValue : value);

const sortArray = (rowA, rowB, sortColumn) => {
  const aPlates = getValueOrDefault(rowA.original[sortColumn], [])
    .map(item => item.plate?.toUpperCase() || item.name?.toUpperCase() || '')
    .sort()
    .join(',');

  const bPlates = getValueOrDefault(rowB.original[sortColumn], [])
    .map(item => item.plate?.toUpperCase() || item.name?.toUpperCase() || '')
    .sort()
    .join(',');

  if (!aPlates && bPlates) return 1;
  if (aPlates && !bPlates) return -1;
  if (!aPlates && !bPlates) return 0;

  return aPlates.localeCompare(bPlates);
}

const sortDate = (rowA, rowB, sortColumn) => {
  const dateA = rowA.original[sortColumn];
  const dateB = rowB.original[sortColumn];

  if (!dateA && dateB) return 1;
  if (dateA && !dateB) return -1;
  if (!dateA && !dateB) return 0;

  return new Date(dateA) - new Date(dateB);
}

export const getCustomSortFunction = (rowA, rowB, sortColumn) => {
  switch (sortColumn) {
    case 'plate':
      return (a = rowA, b = rowB) => {
        return isNullOrEmpty(a.original[sortColumn].plate)
          .trim()
          .localeCompare(isNullOrEmpty(b.original[sortColumn].plate).trim());
      }
    case 'plate_wagons':
    case 'type_wagons':
    case 'fleet_type_wagons':
      return (a = rowA, b = rowB) => {
        return sortArray(a, b, sortColumn);
      }
    case 'body_types':
      return (a = rowA, b = rowB) => {
        return getValueOrDefault(a.original[sortColumn], []).join(',').localeCompare(getValueOrDefault(b.original[sortColumn], []).join(','));
      }
    case 'config_automatic_availability': {
      return (a = rowA, b = rowB) => {
        return isNullOrEmpty(a.original[sortColumn] ? 'Sim' : 'Não')
          .trim()
          .localeCompare(isNullOrEmpty(b.original[sortColumn] ? 'Sim' : 'Não').trim());
      }
    }
    case 'local_availability':
    case 'shipment':
    case 'fleet':
    case 'operation':
    case 'driver':
    case 'status_operational':
    case 'operator':
    case 'branch_origin':
      return (a = rowA, b = rowB) => {
        return isNullOrEmpty(a.original[sortColumn]?.name)
          .trim()
          .localeCompare(isNullOrEmpty(b.original[sortColumn]?.name).trim());
      }
    case 'km_run':
    case 'occurrences':
    case 'travel_current':
    case 'gross_profit_travel':
    case 'time_travel_external':
    case 'time_maintenance':
    case 'time_travel':
    case 'time_last_travel':
    case 'time_available': {
      return (a = rowA, b = rowB) => {
        return (
          isNumberValid(a.original[sortColumn]) -
          isNumberValid(b.original[sortColumn])
        );
      }
    }
    case 'availability_date_travel_external':
    case 'date_origin_travel_external':
    case 'last_location_date':
    case 'next_maintenance_vehicle':
    case 'date_load_travel':
    case 'date_unload_travel':
    case 'date_forecast':
    case 'date_finish_travel': {
      return (a = rowA, b = rowB) => {
        return sortDate(a, b, sortColumn);
      }
    }
    case 'local_driver_residence':
    case 'local_availability_travel_external':
    case 'last_location_info':
    case 'local_driver_interest':
    case 'status_primary':
    case 'fleet_type_vehicle':
    case 'vehicle_type':
    case 'last_location':
    case 'approved_register':
    case 'approved_risk':
    case 'margin_status':
    case 'rate_vehicle':
    case 'observation':
    case 'aggregation': {
      return (a = rowA, b = rowB) => {
        return isNullOrEmpty(a.original[sortColumn])
          .trim()
          .localeCompare(isNullOrEmpty(b.original[sortColumn]).trim());
      };
    }
    default:
      return 'text'
  }
}
