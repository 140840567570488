import React, { useEffect, useState } from 'react';
import { Polyline } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet-polylinedecorator';

const PolylineWithArrow = ({ locations, polylineColor, mapRef }) => {
  const [zoomLevel, setZoomLevel] = useState(13);
  const [repeatValue, setRepeatValue] = useState('20%');
  const [offsetValue, setOffsetValue] = useState('5%');

  useEffect(() => {
    if (!mapRef.current || !locations || locations.length < 2) return;

    const map = mapRef.current.leafletElement;

    const updateZoom = () => {
      setZoomLevel(map.getZoom());
    };

    map.on('zoomend', updateZoom);

    if (zoomLevel <= 15) {
      setOffsetValue('20px');
      setRepeatValue('150px');
    }

    if (zoomLevel <= 11) {
      setOffsetValue('40px');
      setRepeatValue('100px');
    }

    if (zoomLevel <= 7 ) {
      setRepeatValue('10%');
    }

    if (zoomLevel <= 5) {
      setRepeatValue('100%');
      setOffsetValue('50%');
    }

    const polyline = L.polyline(locations, {
      color: polylineColor,
      weight: 6,
    }).addTo(map);

    const decorator = L.polylineDecorator(polyline, {
      patterns: [
        {
          offset: offsetValue,
          repeat: repeatValue,
          symbol: L.Symbol.arrowHead({
            pixelSize: 10,
            headAngle: 30,
            polygon: false,
            pathOptions: { color: '#1c4b75', weight: 1, fill: false },
          }),
        },
      ],
    }).addTo(map);

    return () => {
      map.removeLayer(polyline);
      map.removeLayer(decorator);
      map.off('zoomend', updateZoom); // Remove o evento ao desmontar
    };
  }, [locations, mapRef, zoomLevel]);

  return <Polyline positions={locations || []} />;
};

export default PolylineWithArrow;
