import React from 'react';

import L from 'leaflet';
import 'leaflet.awesome-markers/dist/leaflet.awesome-markers.css';
import 'leaflet.awesome-markers';

import { Marker } from 'react-leaflet';

import ReactDOMServer from 'react-dom/server';

import  MarkerContainer  from './styles';

export default function MarkerLetterMap({ position, background, letter, color, onClick, sizeIcon = 22 }) {


  const customIcon = L.divIcon({
    className: '',
    html: ReactDOMServer.renderToString(<MarkerContainer letter={letter} background={background} color={color} onClick={onClick} sizeIcon={sizeIcon} />)
  });

  return (
    <Marker position={position} icon={customIcon} />
  )

}
