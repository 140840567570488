const Yup = require('yup');

export const dataSchema = Yup.object().shape({
  profile: Yup.object()
    .shape({
      id: Yup.number().required(),
    })
    .typeError('Perfil obrigatório'),
  username: Yup.string()
    .required('Nome obrigatório')
    .min(3, 'Minimo de 3 caracteres'),
  cpf: Yup.string()
    .nullable()
    .when('profile', {
      is: value => value.name === 'Motorista',
      then: Yup.string()
        .transform(current => {
          if (!current) {
            return undefined;
          }
          return current;
        })
        .required('CPF obrigatório'),
    }),
  phonenumber: Yup.string()
    .nullable()
    .when('profile', {
      is: value => value.name === 'Motorista',
      then: Yup.string()
        .transform(current => {
          if (!current) {
            return undefined;
          }
          return current;
        })
        .required('Telefone obrigatório'),
    }),
  companies: Yup.array()
    .of(
      Yup.object().shape({
        id: Yup.number().required('Empresa obrigatória'),
      })
    )
    .typeError('Empresa obrigatória')
    .min(1, 'Empresa obrigatória'),
  branchOffices: Yup.array()
    .nullable()
    .when('$profile', {
      is: value => value.name !== 'Motorista',
      then: Yup.array()
        .of(
          Yup.object().shape({
            id: Yup.number().required('Filial obrigatória'),
          })
        )
        .typeError('Filial obrigatória')
        .min(1, 'Filial obrigatória'),
    }),

  groups: Yup.object()
    .shape({
      id: Yup.number().nullable,
    })
    .nullable()
    .notRequired(),
  tags: Yup.array()
    .of(
      Yup.object().shape({
        id: Yup.number(),
      })
    )
    .nullable()
    .notRequired(),

    costCenters: Yup.array().nullable(),
});

export const accessSchema = Yup.object().shape({
  email: Yup.string()
    .nullable()
    .when('$profile', {
      is: value => value.name !== 'Motorista',
      then: Yup.string().required('E-mail obrigatório'),
    }),
  password: Yup.string()
    .nullable()
    .when('$hasParams', {
      is: value => value === null,
      then: Yup.string().required('Senha obrigatória'),
    }),
  verified: Yup.boolean().nullable(),
});

export const shipperSchema = Yup.array().of(
  Yup.object().shape({
    id: Yup.number(),
    social_name: Yup.string(),
  })
);

export const beneficiarySchema = Yup.array().of(
  Yup.object().shape({
    id: Yup.number(),
    social_name: Yup.string(),
  })
);

export const clientsSchema = Yup.array().of(
  Yup.object().shape({
    id: Yup.number(),
    social_name: Yup.string(),
  })
);

export const establishmentsSchema = Yup.array().of(
  Yup.object().shape({
    id: Yup.number(),
    social_name: Yup.string(),
  })
);

export const userSchema = Yup.object().shape({
  access: accessSchema,
  data: dataSchema,
  clients: clientsSchema,
  establishments: establishmentsSchema,
  shippingCompanies: shipperSchema,
  beneficiary: beneficiarySchema
});

export async function validate(field, data, setErrors, context) {
  const errorList = {};
  try {
    if (field === 'allSchema')
      await userSchema.validate(data, {
        abortEarly: false,
        context: { profile: data.data.profile },
      });
    else
      await userSchema.validateAt(field, data, {
        abortEarly: false,
        context: { profile: data[field].profile, ...context },
      });
    return true;
  } catch (err) {
    err.inner.forEach(error => {
      errorList[error.path] = error?.message;
    });

    return false;
  } finally {
    setErrors(errorList);
  }
}
