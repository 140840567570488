import React from 'react';
import { SectionContainer } from '../styles';
import { OccurrenceGeneralInfo } from './OccurrenceGeneralInfo';
import { OccurrenceDescriptions } from './OccurrenceDescriptions';
import ShowOccurrenceAttachments from '../ShowAttachments';

export function OccurrenceInfo({
  selectedOccurrence,
  show,
  startDate,
  setStartDate,
  setEndDate,
  endDate,
  errors,
  loadingUpdate,
  savingNewOccurrence,
  occurrenceValue,
  setOccurrenceValue,
  handleAddAttchmentsDirectly,
}) {
  return (
    <SectionContainer>
      <OccurrenceGeneralInfo
        selectedOccurrence={selectedOccurrence}
        show={show}
        startDate={startDate}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        endDate={endDate}
        errors={errors}
        loadingUpdate={loadingUpdate}
        savingNewOccurrence={savingNewOccurrence}
        occurrenceValue={occurrenceValue}
        setOccurrenceValue={setOccurrenceValue}
      />
      <OccurrenceDescriptions descriptions={selectedOccurrence?.descriptions} />
      {!show.endOccurrence &&
      !show.addDescriptionToOccurrence &&
      !show.changeResponsible ? (
        <ShowOccurrenceAttachments
          occurrenceId={selectedOccurrence?.id}
          handleAddAttchmentsDirectly={handleAddAttchmentsDirectly}
          isOccurrenceConcluded={
            selectedOccurrence?.fullUserConclusion !== null
          }
          occurrenceIsAnnulleed={selectedOccurrence.annulled}
        />
      ) : null}
    </SectionContainer>
  );
}
