import React from 'react';
import { Button, Text } from 'v3/components';
import TextArea from 'v3/components/TextArea';
import { ButtonsContainer, SectionContainer } from '../styles';

export function AnnulOcurrence({
  description,
  setDescription,
  setShow,
  errors,
  setErrors,
  handleaAnnulOcorrence,
  loadingUpdate,
}) {
  return (
    <>
      <SectionContainer>
        <Text type="header" color="dark" weight={500}>
          Anular Ocorrência
        </Text>
        <TextArea
          placeholder="Mínimo 30 caracteres"
          value={description}
          onChange={e => setDescription(e.target.value)}
          resizable={false}
          error={errors?.description}
          disabled={loadingUpdate}
        />
        <ButtonsContainer>
          <Button
            variant="secondary"
            onClick={() => {
              setDescription('');
              setErrors({});
              setShow(old => ({ ...old, annulOcurrence: false }));
            }}
            disabled={loadingUpdate}
          >
            <Text weight={500} color="dark" type="regular">
              CANCELAR
            </Text>
          </Button>
          <Button
            onClick={() => {
              handleaAnnulOcorrence();
            }}
            loading={loadingUpdate}
          >
            <Text weight={500} color="white" type="regular">
              SALVAR
            </Text>
          </Button>
        </ButtonsContainer>
      </SectionContainer>
    </>
  );
}
