import React, { useRef, useState, useMemo } from 'react';
import GoogleMapReact from 'google-map-react';
import useSupercluster from 'use-supercluster';
import { Row, Col } from 'react-bootstrap';
import { MarkerLetter, Text} from 'v3/components';
import theme from 'v3/theme';
import { MapsContainer, StyledClusterMarker } from './styles';
import MapDetailCard from './MapDetailCard';
import apiReport from 'services/apis/report';
import { useSnackbar } from 'v3/components/Snackbar';

const ClusterMarker = ({ children }) => children;

export default function MapDrivers({
  driversData,
  showMap,
  loading,
  checked,
  setChecked,
  locatedUpTo,
}) {
  const snackbar = useSnackbar();
  const [detail, setDetail] = useState([]);
  const mapRef = useRef();
  const [bounds, setBounds] = useState(null);
  const [zoom, setZoom] = useState(0);
  const points = useMemo(() => {
    if (driversData) {
      const pDriver = driversData
        ?.filter(d => {
          return d?.latitude && d?.longitude;
        })
        ?.map(driver => ({
          type: 'Feature',
          properties: {
            cluster: false,
            id: driver.id,
            category: 'light_blue'
          },
          geometry: {
            type: 'Point',
            coordinates: [
              parseFloat(driver?.longitude),
              parseFloat(driver?.latitude),
            ],
          },
        }));
      return [...pDriver];
    } 
      return [];
    
  }, [driversData]);
  const { clusters, supercluster } = useSupercluster({
    points,
    bounds,
    zoom,
    options: { radius: 75, minZoom: 0, maxZoom: 20, minPoints: 2 },
  });

  async function retrieveDriver(id) {
    try {
      const response = await apiReport.get(
        `/drivers/${id}`
      );
      setDetail(response.data);
      setChecked(true);
    } catch(err) {
      snackbar.show(<Text>Erro ao recuperar motoristas.</Text>, {
        type: 'error',
      });
      setDetail(null);
    }
  }
  function handleChangeColor(category) {
    const colorMap = {
      light_blue: theme.colors.light_blue,
    };
    return colorMap[category] || '';
  }
  return (
    <MapsContainer>
      <>
        {showMap === true && loading === false ? (
          <>
            <Row>
              <Col>
                <Text type="regular" weight={500}>
                    {`Motoristas localizados ${ locatedUpTo === 'today' ? 'hoje' : 'até 3 dias'}`}
                </Text>
              </Col>
            </Row>
            <Row>
              <Col>
                <div style={{ height: '50vh', position: 'relative' }}>
                  <GoogleMapReact
                    bootstrapURLKeys={{
                      key: process.env.REACT_APP_GOOGLE_MAPS_KEY,
                    }}
                    defaultCenter={{ lat: -14.235, lng: -51.9253 }}
                    defaultZoom={0}
                    draggable
                    yesIWantToUseGoogleMapApiInternals
                    onGoogleApiLoaded={({ map }) => {
                      mapRef.current = map;
                    }}
                    onClick={() => setChecked(false)}
                    onChange={({ zoom, bounds }) => {
                      setZoom(zoom);
                      setBounds([
                        bounds.nw.lng,
                        bounds.se.lat,
                        bounds.se.lng,
                        bounds.nw.lat,
                      ]);
                    }}
                  >
                    {clusters.map(cluster => {
                      const [longitude, latitude] =
                        cluster.geometry.coordinates;
                      const {
                        cluster: isCluster,
                        point_count: pointCount,
                        category,
                      } = cluster.properties;
                      if (isCluster) {
                        return (
                          <ClusterMarker
                            key={`cluster-${cluster.id}`}
                            lat={latitude}
                            lng={longitude}
                          >
                            <StyledClusterMarker
                              style={{
                                width: `${
                                  10 + (pointCount / points?.length) * 20
                                }px`,
                                height: `${
                                  10 + (pointCount / points?.length) * 20
                                }px`,
                              }}
                              onClick={() => {
                                const expansionZoom = Math.min(
                                  supercluster.getClusterExpansionZoom(
                                    cluster.id
                                  ),
                                  20
                                );
                                mapRef.current.setZoom(expansionZoom);
                                mapRef.current.panTo({
                                  lat: latitude,
                                  lng: longitude,
                                });
                              }}
                            >
                              {pointCount}
                            </StyledClusterMarker>
                          </ClusterMarker>
                        );
                      }
                      return (
                        <MarkerLetter
                          key={cluster.properties.id}
                          background={handleChangeColor(category)}
                          lat={latitude}
                          lng={longitude}
                          color="#fff"
                          onClick={() => {
                            retrieveDriver(cluster.properties.id);
                          }}
                        />
                      );
                    })}
                  </GoogleMapReact>
                  <Col>
                    {checked ? (
                      <>
                        <MapDetailCard
                          driver={detail}
                          checked={checked}
                         />
                      </>
                    ) : null}
                  </Col>
                </div>
              </Col>
            </Row>
          </>
        ) : (
          <></>
        )}
      </>
    </MapsContainer>
  );
}
