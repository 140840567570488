import React, { useState, useCallback, useEffect } from 'react';

import dashboardApi from 'services/apis/dashboard';
import { Table, Text, Link, Button } from 'v3/components';
import { formatDateTime, formatPlate } from 'v3/utils/formatter';
import { useQuery } from 'hooks';
import moment from 'moment';
import Card, { Number, Description, SeeAll } from './Card';

export default function TravelsFinished({ dashboard, name, id }) {
  const [data, setData] = useState({});
  const [filter, setFilter] = useState({});
  const [currentPage, setCurrentPage] = useState({});
  const [pagination, setPagination] = useState({});
  const [isFetching, setFetching] = useState(false);
  const [loading, setLoading] = useState(false);
  const [daysFromNow, setDaysFromNow] = useState(3);

  useEffect(() => {
    setDaysFromNow(moment(new Date()).diff(filter.start_date, 'days'));
  }, [filter.start_date]);

  const queryParams = useQuery();

  function setQueryParams(filters) {
    const { tags = [], start_date, end_date } = filters;

    queryParams.delete('tags');

    if (tags) {
      tags.forEach(tag => {
        queryParams.append('tags', tag.id);
      });
    }

    queryParams.set('status', 7);

    if (start_date) {
      queryParams.set('start_date', start_date);
    }

    if (end_date) {
      queryParams.set('end_date', end_date);
    }

    if (tags?.length === 0) {
      queryParams.set('no-tags', true);
    } else {
      queryParams.delete('no-tags');
    }
  }

  const fetchAvailablesLoads = useCallback(async (filters = {}) => {
    try {
      setLoading(true);

      const {
        tags = [],
        clients = [],
        negotiators = [],
        costCenters = [],
        pcps = [],
        companies = [],
        page = 1,
        start_date,
        end_date,
      } = filters;

      if (page === 1) {
        setFetching(true);
      }

      setQueryParams(filters);
      setFilter(filters);
      setCurrentPage(+page);

      const response = await dashboardApi.get('/travels-finished', {
        params: {
          tags: tags?.map(tag => tag.id),
          clients: clients?.map(client => client.id),
          start_date,
          negotiators: negotiators?.map(negotiator => negotiator?.id),
          costCenters: costCenters?.map(costCenter => costCenter?.id),
          pcps: pcps?.map(pcp => pcp?.id),
          companies: companies?.map(company => company?.id),
          end_date: end_date || undefined,
          page,
        },
      });

      setPagination(response.data.last.pagination);

      if (page === 1) {
        setData(response.data);
      } else {
        setData(oldData => ({
          ...oldData,
          last: {
            ...oldData.last,
            data: [...oldData.last.data, ...response.data.last.data],
          },
        }));
      }
    } catch (error) {
      // comment
    } finally {
      setFetching(false);
      setLoading(false);
    }
  }, []);

  return (
    <>
      <Card
        title="Viagens finalizadas"
        isLoading={isFetching}
        fetchFn={fetchAvailablesLoads}
        onFiltersChanged={fetchAvailablesLoads}
        dashboard={dashboard}
        name={name}
        excludeFilterByBranchOffice={true}
        id={id}
        ListComponent={
          <>
            <Table striped>
              <thead>
                <tr>
                  <th>
                    <Text weight="500" color="dark">
                      Centro de custo
                    </Text>
                  </th>
                  <th>
                    <Text weight="500" color="dark">
                      Empresa
                    </Text>
                  </th>
                  <th>
                    <Text weight="500" color="dark">
                      Tags da carga
                    </Text>
                  </th>
                  <th>
                    <Text weight="500" color="dark">
                      Nº Pedido Cliente
                    </Text>
                  </th>
                  <th>
                    <Text weight="500" color="dark">
                      Viagem
                    </Text>
                  </th>
                  <th>
                    <Text weight="500" color="dark">
                      Carregamento
                    </Text>
                  </th>
                  <th>
                    <Text weight="500" color="dark">
                      Negociador
                    </Text>
                  </th>
                  <th>
                    <Text weight="500" color="dark">
                      Origem
                    </Text>
                  </th>
                  <th>
                    <Text weight="500" color="dark">
                      Destino
                    </Text>
                  </th>
                  <th>
                    <Text weight="500" color="dark">
                      Cliente
                    </Text>
                  </th>
                  <th>
                    <Text weight="500" color="dark">
                      Motorista
                    </Text>
                  </th>
                  <th>
                    <Text weight="500" color="dark">
                      Veículo
                    </Text>
                  </th>
                </tr>
              </thead>
              <tbody>
                {data.last?.data?.map(load => {
                  const title = [
                    `Cliente ${load.social_name} - ${load.cgccpf}`,
                    `Motorista ${load.driver_name}`,
                    `Placa veículo tração ${formatPlate(load.plates)}`,
                  ].join('\n');

                  return (
                    <tr>
                      <td>
                        <Text type="regular" color="gray">
                          {load?.cost_center || '-'}
                        </Text>
                      </td>
                      <td>
                        <Text type="regular" color="gray">
                          {load?.company_name || '-'}
                        </Text>
                      </td>
                      <td>
                        <Text type="regular" color="gray">
                          {load?.load_tags || '-'}
                        </Text>
                      </td>
                      <td>
                        <Text type="regular" color="gray">
                          {load?.client_order_number || '-'}
                        </Text>
                      </td>
                      <td>
                        <Link
                          to={`/viagens/${load.attended_id}`}
                          title={title}
                          target="_blank"
                        >
                          {load.attended_id}
                        </Link>
                      </td>
                      <td>
                        <Text type="regular" color="gray">
                          {formatDateTime(load.scheduled_origin)}
                        </Text>
                      </td>
                      <td>
                        <Text type="regular" color="gray">
                          {load.user_created}
                        </Text>
                      </td>
                      <td>
                        <Text type="regular" color="dark" truncate>
                          {`${load?.city_origin} - ${load.uf_origin}`}
                        </Text>
                      </td>
                      <td>
                        <Text type="regular" color="dark" truncate>
                          {`${load?.city_destination} - ${load.uf_destination}`}
                        </Text>
                      </td>
                      <td>
                        <Text type="regular" color="gray" truncate>
                          {load.social_name}
                        </Text>
                      </td>
                      <td>
                        <Text type="regular" color="gray" truncate>
                          {load.driver_name}
                        </Text>
                      </td>
                      <td>
                        <Text type="regular" color="gray">
                          {load.plates}
                        </Text>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
            {pagination.lastPage > 1 && pagination.to < pagination.total && (
              <div className="text-center mt-3 col-12">
                <Button
                  className="mt-2 ml-auto align-item-center"
                  onClick={() => {
                    fetchAvailablesLoads({
                      page: currentPage + 1,
                      tags: filter.tags,
                      clients: filter.clients,
                      start_date: filter?.start_date,
                      end_date: filter?.end_date,
                      costCenters: filter?.costCenters,
                      pcps: filter?.pcps,
                      negotiators: filter?.negotiators,
                      companies: filter?.companies,
                    });
                  }}
                  loading={loading}
                >
                  <Text type="regular" color="white" truncate>
                    Ver mais
                  </Text>
                </Button>
              </div>
            )}
            {!!data.total && (
              <SeeAll
                to={{
                  pathname: '/viagens',
                  search: queryParams.toString(),
                }}
              >
                ver todas as {data.total} viagens
              </SeeAll>
            )}
          </>
        }
        NumericComponent={
          <div className="d-flex justify-content-center text-center flex-column">
            <Number color="success">{data.total}</Number>
            <Description>
              viagens finalizadas
              {daysFromNow > 1 && daysFromNow < 61
                ? ` nos últimos ${daysFromNow} dias`
                : ` nos últimos ${filter.start_date} dias`}
            </Description>
          </div>
        }
      />
    </>
  );
}
