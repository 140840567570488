import React from 'react';
import Text from 'v3/components/Text';
import { FaLongArrowAltRight } from "react-icons/fa";
import { DealStatus } from '../shared';
import { Link } from 'react-router-dom';

import './style.css';
import moment from 'moment';

function ConversationItem({
  deal,
  click,
  active,
  selectedDealList
}) {

  const ExText = ({ data }) => {

    if (data?.negotiator_id && !data?.closed) {
      return (
        <div>
          {data?.last_message && <Text fontSize={'14px'} className="resume-message">{data.last_message}</Text>}
        </div>
      )
    } else if (!data?.negotiator_id && data?.last_message) {
      return (
        <div>
          <Text fontSize={'14px'} className="resume-message">{data.last_message}</Text>
        </div>
      )
    } else {
      return null
    }
  }

  const FormatLoadId = ({ data }) => {
    if (!data?.pre_load || (data?.pre_load && data?.pre_travel_id && data?.pre_travel_is_attended)) {
      return (
        <p className="conversation-snippet blue">Carga <Link
          to={`/cargas/${deal?.load_id}`}
          key={deal?.load_id}
          target="_blank"
        >#{deal.load_id}</Link>
        </p>
      )
    } else {
      return (
        <p className="conversation-snippet blue">Pré carga <Link
          to={`/pre-cargas/${deal?.load_id}`}
          key={deal?.load_id}
          target="_blank"
        >#{deal.load_id}</Link>
        </p>
      )
    }
  }

  return (
    <div
      className={`conversation-list-item ${active ? 'active' : ''}`}
      onClick={() => click(deal)}
    >
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
        <DealStatus deal={deal} />
      </div>
      <FormatLoadId data={deal} />
      <p className="conversation-snippet blue">
        Filial da Carga {deal?.branch_office_name || null}
      </p>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <p className="conversation-snippet">
          {deal.origin_city}-{deal.origin_province} <span style={{ marginLeft: '5px', marginRight: '5px' }}><FaLongArrowAltRight /></span>
          {deal.last_destination_city}-{deal.last_destination_province}
        </p>
        {deal?.negotiator_not_reads > 0 && selectedDealList?.selected_in_service ? (
          <div
            title={`${deal.negotiator_not_reads === 1
              ? `${deal.negotiator_not_reads} mensagem não lida`
              : `${deal.negotiator_not_reads} mensagens não lidas`
              }`}
            className="notification"
          >
            {deal.negotiator_not_reads}
          </div>
        ) : !deal?.messages_not_reads && selectedDealList?.selected_not_attended ? (
          <div className="notification" />
        ) : null}
      </div>
      {
        deal?.origin_scheduled_time && deal?.last_destination_scheduled_time ? (
          <p className='conversation-snippet'>
            {moment(deal.origin_scheduled_time).format('DD/MM/YYYY')} <span style={{ marginLeft: '5px', marginRight: '5px' }}><FaLongArrowAltRight /></span>
            {moment(deal.last_destination_scheduled_time).format('DD/MM/YYYY')}
          </p>
        ) : null
      }
      <p className="conversation-snippet">{`Motorista: ${deal.driver_name}`}</p>
      <ExText data={deal} />
    </div>
  );
}

export default ConversationItem;


