/* eslint-disable import/no-unresolved */
import React, { useEffect, useMemo, useState } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import { validatePermission } from 'actions/index';
import useModal from 'hooks/useModal';
import Text from 'v3/components/Text';
import Modal from 'v3/components/Modal';
import { useSnackbar } from 'v3/components/Snackbar';
import useWindowResize from 'hooks/useWindowResize';
import Button from 'v3/components/Button';
import ApplicationLayout from 'v3/layouts/Application';
import { usePermission } from 'hooks';
import { getRate } from 'v3/utils/rate';
import { cooperplaceApi } from 'services/api';
import moment from 'moment';
import { currencyOptions } from 'v3/utils/formatter';
import { DELIVERY_TYPE_OPTIONS } from 'v3/utils/constants';
import { RegisterLoadGRO } from 'v3/pages/LoadGRO/Register';
import LoadData from './LoadData';
import Monitoring from './Monitoring';
import Vehicle from './Vehicle';
import Origin from './Origin';
import Destination from './Destination';
import Automations from './Automations';
import RouteModal from './Route';

import { LoadContext, fetchCountries, handleCreation } from './controller';

import * as Styled from './styled';
import { FreightValues } from './PriceData/FreightValues';
import { AttemptRegisterLoad } from '../Modals/attemptRegisterLoad';

const [AddLoadProvider] = LoadContext;

export default function LoadRegister() {
  const hasPermissionToCreate = validatePermission('CADASTRAR_CARGAS');
  const snackbar = useSnackbar();
  const [loadingRate, setLoadingRate] = useState(false);
  const [rateResponse, setRateResponse] = useState();
  usePermission('CADASTRAR_CARGAS', { redirect: true });
  const history = useHistory();
  const location = useLocation();
  const routeModal = useModal();
  const attemptRegisterLoadModal = useModal();
  const [data, setData] = useState({
    showDailyRate: false,
    selectedVehicles: [],
    selectedBodies: [],
    negotiator: [],
    crts: [],
  });
  const [saveContainerOffset, setSaveContainerOffset] = useState(0);
  const [errors, setErrors] = useState({});
  const [clientCardToggle, setClientCardToggle] = useState(true);
  const [inputsCardToggle, setInputsCardToggle] = useState(false);
  const [negotiatedCardToggle, setNegotiatedCardToggle] = useState(false);
  const [discountsCardToggle, setDiscountsCardToggle] = useState(false);
  const [transferCardToggle, setTransferCardToggle] = useState(false);
  const [extraFields, setExtraFields] = useState([]);
  const [inputsExtraFields, setInputsExtraFields] = useState([]);
  const [discountsExtraFields, setDiscountsExtraFields] = useState([]);
  const [transferExtraFields, setTransferExtraFields] = useState([]);
  const [clientAutomations, setClientAutomations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingGro, setLoadingGro] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [registeredLoad, setRegisteredLoad] = useState('');
  const [countryList, setCountryList] = useState([]);
  const [retrievingLoadData, setRetrievingLoadData] = useState(false);
  const [isPreLoad, setIsPreLoad] = useState(false);
  const [dueDate, setDueDate] = useState(
    moment().add(1, 'day').format('DD/MM/YYYY HH:mm')
  );
  const [searchDriver, setSearchDriver] = useState(true);
  const [operationOptions, setOperationOptions] = useState([]);
  const params = useParams();

  const isPreloadWithGRO = useMemo(
    () => isPreLoad && operationOptions.length > 0,
    [isPreLoad, operationOptions]
  );

  const defaultCountry = {
    id: 1,
    name: 'Brasil',
    abbreviation: 'br',
    ddi: 55,
  };

  async function handleGetRate(currencyValue) {
    setLoadingRate(true);
    const response = await getRate(currencyValue);
    if (!response.error) {
      setRateResponse(response);
    } else {
      snackbar.show(<Text>{response.message}</Text>, {
        type: 'error',
      });
    }
    setLoadingRate(false);
  }

  async function checkPreLoadAttendPermission() {
    const permission = validatePermission('ATENDER_PRECARGA');
    return permission;
  }

  async function handleFetchLoad(loadId) {
    try {
      setIsPreLoad(true);
      setRetrievingLoadData(true);
      const hasPermission = await checkPreLoadAttendPermission();
      if (!hasPermission) {
        snackbar.show(
          <Text>Você não possui permissão para atender pré carga!</Text>,
          { type: 'error' }
        );
        history.push('/cargas');
      }
      const { data: loadData } = await cooperplaceApi.get(`loads/${loadId}`);
      if (!loadData?.preTravel?.id || loadData?.preTravel?.isAttended) {
        snackbar.show(<Text>Carga inválida!</Text>, { type: 'error' });
        history.push('/cargas');
      }
      const takerCurrency = currencyOptions.find(
        item => item.value === loadData?.currency
      );

      const processDestinations = loadData?.loadDestinations?.map(dest => {
        return {
          ...dest,
          zip_code: dest?.zipCode,
          formatted_address: dest?.formattedAddress,
          client: dest?.client?.id
            ? {
              id: dest?.client?.id,
              social_name: dest?.client?.socialName,
              cgccpf: dest?.client?.cpfCnpj,
              label: `${dest?.client?.socialName} - ${dest?.client?.cpfCnpj}`,
            }
            : null,
          date: moment(dest?.scheduledTime).format('DD/MM/YYYY HH:mm'),
          start_schedule: moment(dest?.startSchedule).format(
            'DD/MM/YYYY HH:mm'
          ),
          type: DELIVERY_TYPE_OPTIONS.find(item => item.id === dest?.action),
          country: countryList.find(
            item => item.abbreviation === dest?.countrySlug
          ),
        };
      });

      setData(old => ({
        ...old,
        preLoadId: loadData?.id,
        client: {
          id: loadData?.taker?.id,
          social_name: loadData?.taker?.socialName,
          cgccpf: loadData?.taker?.cpfCnpj,
          label: `${loadData?.taker?.socialName} - ${loadData?.taker?.cpfCnpj}`,
          type: loadData?.taker?.type,
        },
        negotiator: [
          {
            id: loadData?.preTravel?.userCreated?.id,
            username: loadData?.preTravel?.userCreated?.username,
            phonenumber: loadData?.preTravel?.userCreated?.phonenumber,
          },
        ],
        phone: loadData?.preTravel?.userCreated?.phonenumber,
        taker_value: loadData?.takerValue,
        total_taker_value: loadData?.totalTakerValue,
        branchOffice: loadData?.branchOffice,
        selectedVehicles: loadData?.vehicleTypes?.map(item => {
          return {
            id: item?.id,
            name: item?.name,
            qty_axes: item?.qtyAxes,
            qty_plates: item?.qtyPlates,
          };
        }),
        selectedBodies: loadData?.vehicleBodyTypes?.map(item => {
          return {
            id: item?.id,
            name: item?.name,
          };
        }),
        selectedImplements: loadData?.vehicleImplementTypes?.map(item => {
          return {
            id: item?.id,
            name: item?.name,
          };
        }),
        costCenter: {
          id: loadData?.costCenter?.id,
          name: loadData?.costCenter?.name,
          business_id: loadData?.costCenter?.businessId,
          external_id: loadData?.costCenter?.externalId,
        },
        dueDate: moment(loadData?.dueDate, 'YYYY/MM/DDTHH:mm').format(
          'DD/MM/YYYY HH:mm'
        ),
        product: loadData?.product,
        observations: loadData?.comments,
        externalInvoiceNumber: loadData?.externalInvoiceNumber,
        clientOrderNumber: loadData?.clientOrderNumber,
        searchDriver: loadData?.searchDriver ? 1 : 0,
        searchDriverRadius: loadData?.searchDriverRadius,
        currency: takerCurrency,
        selectedDisposition: loadData?.disposition,
        weight: loadData?.loadWeight,
        origin: {
          ...loadData?.loadOrigins[0],
          zip_code: loadData?.loadOrigins[0]?.zipCode,
          formatted_address: loadData?.loadOrigins[0]?.formattedAddress,
          client: loadData?.loadOrigins[0]?.client?.id
            ? {
              id: loadData?.loadOrigins[0]?.client?.id,
              social_name: loadData?.loadOrigins[0]?.client?.socialName,
              cgccpf: loadData?.loadOrigins[0]?.client?.cpfCnpj,
              label: `${loadData?.loadOrigins[0]?.client?.socialName} - ${loadData?.loadOrigins[0]?.client?.cpfCnpj}`,
            }
            : null,
          date: moment(loadData?.loadOrigins[0]?.scheduledTime).format(
            'DD/MM/YYYY HH:mm'
          ),
          start_schedule: moment(
            loadData?.loadOrigins[0]?.startSchedule
          ).format('DD/MM/YYYY HH:mm'),
          country: countryList.find(
            item => item.abbreviation === loadData?.loadOrigins[0]?.countrySlug
          ),
        },
        destinations: [...processDestinations],
      }));
      setDueDate(moment(loadData?.dueDate).format('DD/MM/YYYY HH:mm'));
      setSearchDriver(!!loadData?.searchDriver);
    } catch {
      setIsPreLoad(false);
      snackbar.show(<Text>Erro ao recuperar informações da pré carga</Text>, {
        type: 'error',
      });
    } finally {
      setRetrievingLoadData(false);
    }
  }

  function showSnackbar(text, options, id) {
    snackbar.show(<Text id={id}> {text} </Text>, options);
  }

  async function handleClientChange(client) {
    if (client) {
      setClientAutomations(
        client.automations.flatMap(item => item.automations)
      );
    } else {
      setClientAutomations([]);
    }
  }

  async function handleAttemptToRegisterLoad() {
    try {
      await handleCreation(
        {
          ...data,
          searchDriver: searchDriver,
          extraFields,
          inputsExtraFields,
          transferExtraFields,
          discountsExtraFields,
          isPreloadWithGRO,
        },
        setErrors,
        showSnackbar,
        setLoading,
        setShowModal,
        setRegisteredLoad,
        isPreLoad
      );
    } catch (error) {
      snackbar.show(
        <Text>
          {error?.response?.data?.message || 'Erro ao cadastrar carga!'}
        </Text>,
        {
          type: 'error',
        }
      );
    }
  }

  const getAvailablePolicies = async clientId => {
    try {
      const { data: policiesData } = await cooperplaceApi.get(
        `clients/${clientId}/policies`
      );
      const activePolicies = policiesData.filter(policy => policy.active);

      const { data: operationsData } = await cooperplaceApi.get(
        'policies/operations',
        {
          params: {
            policyIds: activePolicies.map(policy => policy.id),
          },
        }
      );

      const formatedOperations = operationsData.map(operation => {
        return {
          value: operation.id,
          label: `${operation.policy.name} - ${operation.name}`,
          data: {
            operation,
          },
        };
      });

      setOperationOptions(formatedOperations);
    } catch (error) {
      snackbar.show(
        <Text>Erro ao buscar apólices e operações do cliente!</Text>,
        {
          type: 'error',
        }
      );
    }
  };

  useWindowResize(() => {
    const header = document.getElementById('header-container');
    const originDistanceToRight =
      window.pageXOffset + header.getBoundingClientRect().right;
    const headerWidth = header.getBoundingClientRect().width;

    const SAVE_CONTAINER_PADDING = 0;
    const distanceToRight =
      originDistanceToRight - headerWidth + SAVE_CONTAINER_PADDING;
    setSaveContainerOffset(distanceToRight);
  }, []);

  const getProductOptionsByPolicy = async (policyId, operationId) => {
    try {
      const { data: productsData } = await cooperplaceApi.get(
        'products/forSelect',
        {
          params: {
            operationId,
            policyId,
          },
        }
      );

      setData(old => {
        return {
          ...old,
          preLoadPolicy: {
            ...old.preLoadPolicy,
            productsOptions: productsData,
            selectedProduct: old.preLoadPolicy?.selectedProduct || {
              name: old.product?.name,
              ncm: old.product?.ncm,
              id: old.product?.id,
            },
          },
        };
      });
    } catch (error) {
      //
    }
  };

  const isPreLoadWithGROProductDisabled = useMemo(
    () =>
      isPreloadWithGRO &&
      (!data?.preLoadPolicy?.productsOptions ||
        data?.preLoadPolicy?.productsOptions?.length === 0),
    [isPreloadWithGRO, data?.preLoadPolicy?.productsOptions]
  );

  const isPreLoadWithGROVehicleDisabled = useMemo(() => {
    if (!isPreloadWithGRO) return false;
    return (
      (isPreloadWithGRO && !data?.preLoadPolicy?.selectedProduct) ||
      data?.preLoadPolicy?.vehiclesOptionsId?.length === 0 ||
      !data?.preLoadPolicy?.vehiclesOptionsId
    );
  }, [
    isPreloadWithGRO,
    data?.preLoadPolicy?.selectedProduct,
    data?.preLoadPolicy?.vehiclesOptionsId,
  ]);

  const isPreLoadWithGROCargoValueDisabled = useMemo(() => {
    if (!isPreloadWithGRO) return false;
    const includesApolicyVehicle = data?.selectedVehicles?.find(vehicle =>
      data?.preLoadPolicy?.vehiclesOptionsId?.includes(vehicle.id)
    );

    return !(
      isPreloadWithGRO &&
      !isPreLoadWithGROProductDisabled &&
      !isPreLoadWithGROVehicleDisabled &&
      includesApolicyVehicle
    );
  }, [
    isPreLoadWithGROVehicleDisabled,
    isPreLoadWithGROProductDisabled,
    data?.preLoadPolicy?.vehiclesOptionsId,
    data?.selectedVehicles,
  ]);

  useEffect(() => {
    handleGetRate('USD');
    fetchCountries(setCountryList);
    setData(old => ({
      ...old,
      externalInvoiceNumber: '',
      dueDate: moment().add(1, 'day').format('DD/MM/YYYY HH:mm'),
      origin: {
        ...old?.origin,
        country: defaultCountry,
      },
      destinations: [
        {
          address: '',
          type: DELIVERY_TYPE_OPTIONS[1],
          date: '',
          complement: '',
          lat: '',
          lng: '',
          formatted_address: '',
          neighborhood: '',
          city: '',
          province: '',
          zip_code: '',
          country: defaultCountry,
          number: '',
          start_schedule: '',
          index: 0,
        },
      ],
      taker_value: null,
      toll_value: null,
      discharge_value: null,
      gris: null,
      ad_valorem: null,
      charge_value: null,
      ferry: null,
      ICMS: null,
      advance_money_percentage: 0,
    }));
  }, []);

  useEffect(() => {
    if (
      data?.operationPreLoad?.data?.operation?.id &&
      data?.preLoadPolicy?.id
    ) {
      getProductOptionsByPolicy(
        data.operationPreLoad.data.operation.policy.id,
        data.operationPreLoad.data.operation.id
      );
    }
  }, [data?.preLoadPolicy?.id, data?.operationPreLoad?.data?.operation?.id]);

  useEffect(() => {
    if (data.client?.id && data?.hasRoutes) {
      routeModal.open();
    }
  }, [data?.hasRoutes]);

  useEffect(() => {
    if (!data.client?.id) {
      setData(old => ({ ...old, hasRoutes: false }));
    }
  }, [data?.client?.id]);

  useEffect(() => {
    if (isPreLoad && data?.client?.id) {
      getAvailablePolicies(data.client.id);
    }
  }, [isPreLoad, data?.client?.id]);

  useEffect(() => {
    if (
      !params.id &&
      !location?.pathname.includes('gro') &&
      !location?.state?.clientData
    ) {
      snackbar.show(<Text>Acesso inválido!</Text>, { type: 'error' });
      history.push('/cargas');
    }
    if (!params.id && location?.state?.clientData) {
      setData(old => ({
        ...old,
        client: location?.state?.clientData,
        isGROLoad: !!location?.pathname.includes('gro'),
      }));
    }
  }, [params, location]);

  useEffect(() => {
    if (params?.id && countryList?.length > 0) {
      setRetrievingLoadData(true);
      handleFetchLoad(params?.id);
    } else {
      setLoading(false);
    }
  }, [params, countryList]);

  const value = {
    data,
    setData,
    errors,
    setErrors,
    rateResponse,
    loadingRate,
    clientCardToggle,
    setClientCardToggle,
    inputsCardToggle,
    setInputsCardToggle,
    discountsCardToggle,
    setDiscountsCardToggle,
    transferCardToggle,
    setTransferCardToggle,
    extraFields,
    setExtraFields,
    inputsExtraFields,
    setInputsExtraFields,
    discountsExtraFields,
    setDiscountsExtraFields,
    transferExtraFields,
    setTransferExtraFields,
    negotiatedCardToggle,
    setNegotiatedCardToggle,
    retrievingLoadData,
    dueDate,
    setDueDate,
    searchDriver,
    setSearchDriver,
    isPreLoad,
    countryList,
    loadingGro,
    setLoadingGro,
    setShowModal,
    setRegisteredLoad,
    isPreloadWithGRO,
    isPreLoadWithGROProductDisabled,
    isPreLoadWithGROVehicleDisabled,
    isPreLoadWithGROCargoValueDisabled,
  };

  return (
    <ApplicationLayout
      title={`${data?.isGROLoad ? 'Cadastro de Carga com Apólice' : 'Cadastro de carga'
        }`}
      RightComponent={
        data?.isGROLoad ? (
          <Button
            variant="secondary"
            onClick={() => history.push('/cargas')}
            disabled={loading}
            id="button-gro-cancelar-cadastro"
          >
            <Text weight={500} color="black" type="regular">
              Cancelar
            </Text>
          </Button>
        ) : (
          <Styled.SaveContainer style={{ right: saveContainerOffset }}>
            <Button
              variant="secondary"
              onClick={() => history.push('/cargas')}
              disabled={loading}
              id="button-cancelar-carga"
            >
              <Text weight={500} color="black" type="regular">
                Cancelar
              </Text>
            </Button>
            <Button
              variant="success"
              onClick={handleAttemptToRegisterLoad}
              loading={loading}
              disabled={retrievingLoadData}
              id="button-salvar-carga"
            >
              <Text weight={500} color="white" type="regular">
                Salvar carga
              </Text>
            </Button>
          </Styled.SaveContainer>
        )
      }
    >
      <Modal
        show={showModal}
        heading="Cadastro de cargas"
        body={
          <>
            <p>
              Carga <b id="label-carga-cadastrada-sucesso">{registeredLoad}</b>{' '}
              cadastrada com sucesso!
            </p>
          </>
        }
        closeButton={false}
        backdrop="static"
        size="lg"
        footer={
          <>
            <Button
              variant="white"
              outlined
              onClick={() => history.push('/cargas')}
              id="button-carga-ver-listagem"
            >
              <Text>Ver listagem</Text>
            </Button>
            <Button
              variant="primary"
              onClick={() => history.push(`/cargas/${registeredLoad}`)}
              id="button-carga-visualizar-carga"
            >
              <Text>Visualizar carga</Text>
            </Button>
            {hasPermissionToCreate && (
              <Button
                variant="success"
                onClick={() => {
                  if (isPreLoad) {
                    history.push('/pre-cargas/cadastrar');
                  } else {
                    history.push({
                      pathname: '/cargas',
                      state: {
                        openNewLoadModal: true,
                      },
                    });
                  }
                }}
                id="button-carga-nova-carga"
              >
                <Text>Nova carga</Text>
              </Button>
            )}
          </>
        }
      />
      <AttemptRegisterLoad
        isOpen={attemptRegisterLoadModal.isOpen}
        onClose={() => attemptRegisterLoadModal.close()}
      />

      <AddLoadProvider value={value}>
        {data?.isGROLoad ? (
          <RegisterLoadGRO />
        ) : (
          <>
            <RouteModal
              isOpen={routeModal.isOpen}
              onClose={routeModal.close}
              client={data.client}
            />
            <Row xs={12} className="row-eq-height">
              <Col xs={12} md={6}>
                <Row>
                  <Col xs={12}>
                    <LoadData
                      operationOptions={operationOptions}
                      onClientChange={handleClientChange}
                    />
                  </Col>
                  <Col xs={12}>
                    <Origin />
                  </Col>
                </Row>
              </Col>
              <Col xs={12} md={6}>
                <Row className="row-eq-height h-100">
                  <Col xs={12}>
                    <Vehicle />
                  </Col>
                  <Col xs={12}>
                    <Monitoring />
                  </Col>
                  <Col xs={12}>
                    <Destination />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <FreightValues />
            </Row>
            <Row>
              <Automations clientAutomations={clientAutomations} />
            </Row>
          </>
        )}
      </AddLoadProvider>
    </ApplicationLayout>
  );
}
