import React, { useState, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import Loader from 'v3/components/Loader';
import logo from './img/icon-color.png';
import playStoreButton from './img/playstore-buton.png';
import appleStoreButton from './img/applestore-buton.png';

export default function AppRedirectPage() {
  const { loadCode, aggregationCode } = useParams();
  const [loading, setLoading] = useState(true);

  let idsData;
  let appLinkUrl = 'coopertech://?action=';
  let postLinkUrl = '/';
  if (loadCode) {
    idsData = decodeURIComponent(loadCode);
    appLinkUrl += 'OPEN_LOAD&load_id=';
    postLinkUrl += 'offer-load/';
  } else if (aggregationCode) {
    idsData = decodeURIComponent(aggregationCode);
    appLinkUrl += 'OPEN_AGGREGATION&aggregation_id=';
    postLinkUrl += 'aggregation-invite/';
  }

  if (idsData && idsData.indexOf(';') > -1) {
    const idsCode = idsData.split(';');
    const itemId = idsCode[1];
    appLinkUrl += itemId;
  }

  const openApp = () => {
    try {
      window.location.href = appLinkUrl;
    } catch (error) {
      console.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    openApp();
  }, []);

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh', textAlign: 'center', padding: '20px' }}>
      <div>
        <img src={logo} alt="Logo do Site" style={{ width: '200px', marginBottom: '20px' }} />
        <Row className="justify-content-center align-items-center" style={{ marginBottom: '10px' }}>
          <Loader loading={loading} />
        </Row>
        <Row className="d-flex justify-content-center align-items-center">
          <Col
            md={3}
            sm={6}
            xs={12}
            title="Download iOS"
            onClick={() => window.open('https://apps.apple.com/br/app/coopercarga-fretes-e-cargas/id6450846713', '_blank')}
            style={{ margin: '10px', textAlign: 'center' }}
          >
            <img
              src={appleStoreButton}
              alt="Botão download na apple store"
              style={{
                width: '100%',
                maxWidth: '250px',
                transition: 'transform 0.3s ease',
                cursor: 'pointer'
              }}
              onMouseEnter={(e) => e.currentTarget.style.transform = 'scale(1.1)'}
              onMouseLeave={(e) => e.currentTarget.style.transform = 'scale(1)'}
            />
          </Col>
          <Col
            md={3}
            sm={6}
            xs={12}
            title="Download Android"
            onClick={() => window.open('https://play.google.com/store/apps/details?id=com.coopercarga.coopertech', '_blank')}
            style={{ margin: '10px', textAlign: 'center' }}
          >
            <img
              src={playStoreButton}
              alt="Botão download na play store"
              style={{
                width: '100%',
                maxWidth: '250px',
                transition: 'transform 0.3s ease',
                cursor: 'pointer'
              }}
              onMouseEnter={(e) => e.currentTarget.style.transform = 'scale(1.1)'}
              onMouseLeave={(e) => e.currentTarget.style.transform = 'scale(1)'}
            />
          </Col>
        </Row>
      </div>
    </div>
  );
}
