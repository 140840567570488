import React from 'react'
import Text from 'v3/components/Text';
import Box from '@material-ui/core/Box';
import { Link } from 'react-router-dom';
import moment from 'moment';

export default function RenderSubtitle({ deal }) {

  const Render = ({ data }) => {
    if (data?.travel_id) {

      if (data?.isAttendedInDeal) {
        return (
          <>
            <Text
              color={'success'}
              fontSize={'14px'}
              weight={600}> NEGOCIAÇÃO ENCERRADA - ATENDIDA - {data?.travel_created_at && moment(data?.travel_created_at).format('DD/MM/YYYY HH:MM') || null}
            </Text>
            <Box>
              <Text color={'success'} fontSize={'12px'} weight={600}>VIAGEM: </Text>
              <Link
                to={`/viagens/${data?.travel_id}`}
                key={data?.travel_id}
                target="_blank"
              >
                <Text color={'success'} fontSize={'14px'}>
                  {data?.travel_id}
                </Text>
              </Link>
            </Box >
          </>
        )
      } else if (data?.attendedOutherDriver) {
        return (
          <>
            <Text
              color={'error'}
              fontSize={'14px'}
              weight={600}> NEGOCIAÇÃO ENCERRADA - ATENDIDA PARA OUTRO MOTORISTA - {data?.travel_created_at && moment(data?.travel_created_at).format('DD/MM/YYYY HH:MM') || null}
            </Text>
            <Box>
              <Text color={'error'} fontSize={'12px'} weight={600}>VIAGEM: </Text>
              <Link
                to={`/viagens/${data?.travel_id}`}
                key={data?.travel_id}
                target="_blank"
              >
                <Text color={'error'} fontSize={'14px'}>
                  {data?.travel_id}
                </Text>
              </Link>
            </Box >
          </>
        )
      } else if (data?.isAttendedDriverNotDeal) {
        return (
          <>
            <Text
              color={'error'}
              fontSize={'14px'}
              weight={600}> NEGOCIAÇÃO ENCERRADA - ATENDIDA PARA MESMO MOTORISTA FORA DO CHAT NEGOCIAÇÃO - {data?.travel_created_at && moment(data?.travel_created_at).format('DD/MM/YYYY HH:MM') || null}
            </Text>
            <Box>
              <Text color={'error'} fontSize={'12px'} weight={600}>VIAGEM: </Text>
              <Link
                to={`/viagens/${data?.travel_id}`}
                key={data?.travel_id}
                target="_blank"
              >
                <Text color={'error'} fontSize={'14px'}>
                  {data?.travel_id}
                </Text>
              </Link>
            </Box >
          </>
        )
      } else {
        return null
      }

    } else if (data?.closed) {

      if (data?.loadIsCalceled) {
        return (
          <>
            <Text
              color={'error'}
              fontSize={'12px'}
              weight={600}> ENCERRADA - NÃO ATENDIDA - CARGA CANCELADA  -  {data?.closed_at && moment(data?.closed_at).format('DD/MM/YYYY HH:MM') || null}
            </Text>
          </>
        )
      } else if (data?.loadIsExpired) {
        return (
          <>
            <Text
              color={'error'}
              fontSize={'12px'}
              weight={600}> ENCERRADA - NÃO ATENDIDA -  CARGA VENCIDA  -  {data?.closed_at && moment(data?.closed_at).format('DD/MM/YYYY HH:MM') || null}
            </Text>
          </>
        )
      } else {
        return (
          <>
            <Text
              color={'error'}
              fontSize={'12px'}
              weight={600}> ENCERRADA - NÃO ATENDIDA  -  {data?.closed_at && moment(data?.closed_at).format('DD/MM/YYYY HH:MM') || null}
            </Text>
          </>
        )
      }

    } else {
      return null
    }
  }

  return (
    <Render data={deal} />
  )
}
