import React, { useState, useRef, useEffect } from 'react';

import { useHistory } from 'react-router-dom';

import Truck from '@material-ui/icons/LocalShippingTwoTone';
import ForwardIcon from '@mui/icons-material/Forward';
import Close from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Button from 'v3/components/Button';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import moment from 'moment';
import { Col } from 'react-bootstrap';
import dealAPI from '../../../services/apis/deal';
import Modal from './Modal';
import Select from 'v3/components/Select';

import Card from '../../../components/Card';
import Snackbar from '../../../components/Snackbar';
import Message from '../messages';
import TextComposer from '../textComposer';

import { RenderTitle } from '../shared';
import { RenderSubtitle } from '../shared';

import './style.css';
import { fetchUsers } from 'utils/fetches';

function Messenger({
  messages,
  setMessages,
  deal,
  setDeal,
  iniciateButton,
  setIniciateButton,
  requestPreLoad,
  setRequestPreLoad,
  requestPreLoadModal,
  filterNegotiations,
  openConversationItem,
  setSelectedList,
  setLoading,
  setCurrentDeal,
  handleStatusSelected,
  setLoadingMessages,
  setPage
}) {
  const scrollRef = useRef(null);
  const history = useHistory();
  const [dialogInfo, setDialogInfo] = useState('');
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [loadingButtonInitDeal, setLoadingButtonInitDeal] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [modalTransferTrading, setModalTransferTrading] = useState(false);

  const [user, setUser] = useState(null);
  const [snackbar, setSnackbar] = useState({
    display: false,
    type: 'error',
    message: '',
  });

  const userd = JSON.parse(localStorage.getItem('usuario'));
  const user_id = userd.id;

  const modalClose = () => {
    setModalTransferTrading(false);
    setUser(null);
  }

  useEffect(() => {
    if (scrollRef.current)
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
  }, [messages]);


  const titleAttendedLoad = (deal) => {
    if (!deal?.pre_load || (deal?.pre_load && deal?.pre_travel_id && deal?.pre_travel_is_attended)) {
      return 'Atender carga'
    } else {
      return 'Atender Pré Carga'
    }
  }

  const validAttendLoad = async (loadId) => {
    try {
      const response = await dealAPI.get(`deals/checkLoadDeals/${loadId}`);
      return response.data;
    } catch (error) {
      return null;
    }
  }

  async function setDealNegotiator() {
    const { driver_id, load_id, } = deal;

    try {
      setLoadingMessages(true);
      setLoading(true);
      setLoadingButtonInitDeal(true);
      const response = await dealAPI.post(`deals/closeAll/${load_id}/${user_id}/${driver_id}`);
      handleStatusSelected({ selected_in_service: true })
      setSelectedList(1);

      setIniciateButton(false);

      openConversationItem(response.data);

      setPage(0);

    } catch (err) {
      if (err.response) {
        const { data } = err.response;
        setSnackbar({
          type: 'error',
          message: data.message || 'Algo deu errado.',
          display: true,
        });
      } else if (err.message) {
        setSnackbar({
          type: 'error',
          message: err.message || 'Algo deu errado.',
          display: true,
        });
      }
    } finally {
      setLoadingMessages(false);
      setLoadingButtonInitDeal(false);
      setLoading(true);
    }
  }
  const renderMessages = () => {

    if (messages) {
      let i = 0;
      const tempMessages = [];

      while (i < messages.length) {
        const previous = messages[i - 1];
        const current = messages[i];
        const next = messages[i + 1];
        const isMine = current.sender_id === deal.negotiator_id;

        const currentMoment = moment(current.created_at);
        let prevBySameAuthor = false;
        let nextBySameAuthor = false;
        let startsSequence = true;
        let endsSequence = true;
        let showTimestamp = true;

        if (previous) {
          const previousMoment = moment(previous.created_at);
          const previousDuration = moment.duration(
            currentMoment.diff(previousMoment)
          );
          prevBySameAuthor = previous.sender_id === current.sender_id;

          if (prevBySameAuthor && previousDuration.as('hours') < 1) {
            startsSequence = false;
          }

          if (previousDuration.as('hours') < 1) {
            showTimestamp = false;
          }
        }

        if (next) {
          const nextMoment = moment(next.created_at);
          const nextDuration = moment.duration(nextMoment.diff(currentMoment));
          nextBySameAuthor = next.sender_id === current.sender_id;

          if (nextBySameAuthor && nextDuration.as('hours') < 1) {
            endsSequence = false;
          }
        }

        tempMessages.push(
          <Message
            key={i}
            isMine={isMine}
            startsSequence={startsSequence}
            endsSequence={endsSequence}
            showTimestamp={showTimestamp}
            data={current}
          />
        );

        i += 1;
      }
      return tempMessages;
    }
    return '';
  };

  const handleClick = event => {
    if (deal.closed) return;
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    let timeout = null;
    if (isDialogOpen === false && requestPreLoad?.id) {
      timeout = setTimeout(() => {
        requestPreLoadModal.open();
      }, 500);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [isDialogOpen, requestPreLoad]);

  function attendLoad() {
    setDialogOpen(false);

    if (!deal?.pre_load || (deal?.pre_load && deal?.pre_travel_id && deal?.pre_travel_is_attended)) {

      const url = `/viagens/cadastrar/${deal.load_id}/${deal.driver_id}/${deal.id}`;
      window.open(url, '_blank')

    } else {
      setRequestPreLoad({
        id: deal.load_id,
        currency: deal.currency
      });
    }
  }



  async function transferTrading() {

    try {
      setLoadingButton(true);
      await dealAPI.put(`deals/updateNegotiator/${user.id}/${deal.id}`);
      setSnackbar({
        display: true,
        type: 'success',
        message: `Negociação transferida com sucesso!`,
      });
      setUser(null)
      setModalTransferTrading(false);
      history.replace(window.location.pathname)
      setDeal(null);
      setCurrentDeal(null);
    } catch (error) {
      setSnackbar({
        display: true,
        type: 'error',
        message: 'Houve um erro ao transferir negociação.',
      });
    } finally {
      filterNegotiations()
      setLoadingButton(false);
    }
  }

  async function closeNegotiation() {
    try {
      setLoadingButton(true);
      await dealAPI.post(`deals/close/${deal.load_id}/load_id/${true}`);
      setSnackbar({
        display: true,
        type: 'success',
        message: `Negociação encerrada com sucesso!`,
      });
      // TODO: Refactor it to a callback instead a reassign
      // eslint-disable-next-line no-param-reassign
      deal.closed = 1;
      setDialogOpen(false);
      history.replace(window.location.pathname)
      setDeal(null);
      setCurrentDeal(null);
    } catch (error) {
      setSnackbar({
        display: true,
        type: 'error',
        message: 'Houve um erro ao encerrar negociação.',
      });
    } finally {
      setLoadingButton(false);
      filterNegotiations()
    }
  }

  function executeMenuAction(type) {
    switch (type) {
      case 'attendLoad':
        attendLoad();
        break;
      case 'closeNegotiation':
        closeNegotiation();
        break;
      case 'transferTrading':
        transferTrading();
        break;
      default:
        break;
    }
  }

  return (
    <>
      <Snackbar
        open={snackbar.display}
        type={snackbar.type}
        message={snackbar.message}
        onClose={() => setSnackbar(false)}
      />
      <Dialog
        open={isDialogOpen}
        fullWidth
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{dialogInfo.title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{dialogInfo.content}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            outline={1}
            color="primary"
            loading={loadingButton}
            onClick={() => {
              setDialogOpen(false);
              setRequestPreLoad(null);
            }}
          >
            Não
          </Button>
          <Button
            onClick={() => executeMenuAction(dialogInfo.type)}
            color="primary"
            loading={loadingButton}
          >
            Sim
          </Button>
        </DialogActions>
      </Dialog>
      <Card style={{ padding: '0px', height: '100%' }}>
        {messages && (
          <Grid
            style={{ padding: '8px', height: '100%' }}
            container
            className={`message-list `}
          >
            <Grid item md={12} xs={12} className="toolbar">
              <Box style={{ display: 'flex', flexDirection: 'column' }}>
                {
                  deal.negotiator_name && (
                    <Typography>
                      Negociador: {deal.negotiator_name}
                    </Typography>
                  )
                }
                <RenderTitle deal={deal} />
                <RenderSubtitle deal={deal} />
              </Box>
              <div>
                {
                  deal?.negotiator_id && deal.negotiator_id == user_id && !deal?.closed && (
                    <Button
                      aria-controls="actions"
                      onClick={handleClick}
                    >
                      AÇÕES
                    </Button>
                  )
                }
                <Menu
                  id="actions"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <MenuItem
                    onClick={async () => {
                      const load = await validAttendLoad(deal.load_id);

                      const validDialogLoad = !deal?.pre_load || (deal?.pre_load && deal?.pre_travel_id && deal?.pre_travel_is_attended)

                      const shouldBlockActionsButton = load?.travel_id;

                      if (load?.deleted_at) {
                        setSnackbar({
                          display: true,
                          type: 'error',
                          message: 'Não é possível atender essa negociação pois a carga foi cancelada.',
                        });
                        handleClose()
                        return
                      }

                      if (load?.due_date < moment().format('YYYY-MM-DD HH:mm:ss')) {
                        setSnackbar({
                          display: true,
                          type: 'error',
                          message: 'Não é possível atender essa negociação pois a carga está vencida.',
                        });
                        handleClose()
                        return
                      }

                      if (shouldBlockActionsButton) {
                        setSnackbar({
                          display: true,
                          type: 'error',
                          message: 'Não é possível atender essa negociação pois já foi atendida.',
                        });
                        handleClose()
                        return
                      }

                      setDialogOpen(true);
                      setDialogInfo({
                        title: validDialogLoad ? 'Atender carga' : 'Atender Pré Carga',
                        content: validDialogLoad
                          ? 'Deseja realmente atender essa carga?'
                          : 'Prosseguir para solicitação da pré carga?',
                        type: 'attendLoad',
                      });
                      handleClose();
                    }}
                  >
                    <Truck />
                    &nbsp; {titleAttendedLoad(deal)}
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setModalTransferTrading(true);
                      handleClose();
                    }}
                  >
                    <ForwardIcon />
                    &nbsp;Transferir negociação
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setDialogOpen(true);
                      setDialogInfo({
                        title: 'Encerramento de negociação',
                        content: 'Deseja realmente encerrar essa negociação?',
                        type: 'closeNegotiation',
                      });
                      handleClose();
                    }}
                  >
                    <Close />
                    &nbsp;Encerrar negociação
                  </MenuItem>
                </Menu>
              </div>
            </Grid>
            <Grid item md={12} xs={12}>
              <Box px={2}>
                <div className="message-list-container" ref={scrollRef}>
                  {deal.closed === null && deal.negotiator_id === null && (
                    <Col className="text-center">
                      <Button
                        onClick={() => setDealNegotiator()}
                        loading={loadingButtonInitDeal}>
                        INICIAR NEGOCIAÇÃO
                      </Button>
                    </Col>
                  )}
                  {renderMessages()}
                </div>
              </Box>
            </Grid>
            <Grid container>
              <Grid item md={12} xs={12}>
                {deal && <TextComposer deal={deal} setMessages={setMessages} iniciateButton={iniciateButton} userId={user_id} />}
              </Grid>
            </Grid>
          </Grid>
        )}
      </Card>

      <Modal
        show={modalTransferTrading}
        handleClose={() => modalClose()}
        heading="Transferir negociação"
        body={
          <>
            <Select.Async
              label="Usuario"
              onSearch={fetchUsers}
              value={user}
              onChange={value => {
                setUser(value);
              }}
              getOptionLabel={option => option.username}
              getOptionValue={option => option.id}
              horizontal
            />
            <Box style={{ marginTop: '10px', display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                onClick={() => {
                  setDialogOpen(true);
                  setDialogInfo({
                    title: 'Transferir a negociação',
                    content: `Tem certeza que deseja transferir a negociação para ${user.username}?`,
                    type: 'transferTrading',
                  })
                }}
                variant={'success'}
              >
                Salvar
              </Button>
            </Box>
          </>
        }
      />
    </>
  );
}

export default Messenger;
