import moment from 'moment';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { FaMapMarked, FaTruck } from 'react-icons/fa';
import { Text } from 'v3/components';
import theme from 'v3/theme';
import { formatCurrency } from 'v3/utils/formatter';

export function LoadDetails({ preLoad, preLoadCurrency }) {
  const preLoadCurrencySymbol = preLoad?.currency === 'BRL' ? 'R$' : '$';
  return (
    <Row>
      <Col md={12}>
        <Text type="label" color="#464E5F">
          Cliente Tomador
        </Text>
        <Text type="regular" color="gray" as="p" className="mt-2">
          {preLoad?.taker.socialName
            ? `${preLoad?.taker.socialName} - ${preLoad?.taker.cpfCnpj}`
            : 'Não informado'}
        </Text>
      </Col>
      <Col xs={12}>
        <Text type="label" color="#464E5F">
          Centro de custo
        </Text>
        <Text type="regular" color="gray" as="p" className="mt-2">
          {preLoad?.costCenter ? preLoad.costCenter?.name : 'Não informado'}
        </Text>
      </Col>
      <Col md={12}>
        <Text type="label" color="#464E5F">
          Produto
        </Text>
        <Text type="regular" color="gray" as="p" className="mt-2">
          {preLoad?.product ? `${preLoad?.product?.name}` : 'Não informado'}
        </Text>
      </Col>
      <Col md={6} xs={12}>
        <Text type="label" color="#464E5F">
          Filial
        </Text>
        <Text type="regular" color="gray" as="p" className="mt-2">
          {preLoad?.branchOffice ? preLoad?.branchOffice.name : 'Não informado'}
        </Text>
      </Col>
      <Col md={6} xs={12}>
        <Text type="label" color="#464E5F">
          Vencimento da carga
        </Text>
        <Text type="regular" color="gray" as="p" className="mt-2">
          {preLoad?.dueDate
            ? moment(preLoad.dueDate).format('DD/MM/YYYY HH:mm')
            : 'Não informado'}
        </Text>
      </Col>
      <Col md={6} xs={12} className="mb-2">
        <Text type="label" color="#464E5F">
          Valor do frete tomador
        </Text>
        <Text type="regular" color="gray" as="p" className="mt-2">
          {formatCurrency(
            preLoad?.takerValue,
            preLoad?.currency,
            preLoadCurrencySymbol
          )}
        </Text>
      </Col>
      <Col md={6} xs={12}>
        <Text type="label" color="#464E5F">
          Moeda Valor do Frete Tomador
        </Text>
        <Text type="regular" color="gray" as="p" className="mt-2">
          {preLoadCurrency?.label || 'Não informado'}
        </Text>
      </Col>
      <Col xs={12} md={6}>
        <Text type="label" color="#464E5F">
          Número do pedido cliente
        </Text>
        <Text type="regular" color="gray" as="p" className="mt-2">
          {preLoad?.clientOrderNumber
            ? preLoad?.clientOrderNumber
            : 'Não informado'}
        </Text>
      </Col>
      <Col xs={12} md={6}>
        <Text type="label" color="#464E5F">
          Invoice
        </Text>
        <Text type="regular" color="gray" as="p" className="mt-2">
          {preLoad?.externalInvoiceNumber
            ? preLoad?.externalInvoiceNumber
            : 'Não informado'}
        </Text>
      </Col>
      <Col xs={12}>
        <Text type="label" color="#464E5F">
          Observações
        </Text>
        <Text
          type="regular"
          color="gray"
          as="p"
          className="mt-2"
          wordwrap={true}
        >
          {preLoad?.comments ? preLoad?.comments : 'Não informado'}
        </Text>
      </Col>
      <Col xs={12}>
        <Text type="label" color="#464E5F">
          Publicar carga?
        </Text>
        <Text type="regular" color="gray" as="p" className="mt-2">
          {preLoad?.loadPublish ? 'Sim' : 'Não'}
        </Text>
      </Col>
      <Col xs={12} md={6}>
        <Text type="label" color="#464E5F">
          Buscar motorista no APP?
        </Text>
        <Text type="regular" color="gray" as="p" className="mt-2">
          {preLoad?.searchDriver ? 'Sim' : 'Não'}
        </Text>
      </Col>
      {preLoad?.searchDriver ? (
        <>
          <Col xs={12} md={6}>
            <Text type="label" color="#464E5F">
              Raio de busca de motorista no APP
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {`${preLoad?.searchDriverRadius}km`}
            </Text>
          </Col>
          <Col xs={12}>
            <Text type="label" color="#464E5F">
              Status notificações motoristas
            </Text>
            <div
              style={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                gap: '1rem',
                marginTop: '0.5rem',
              }}
            >
              <span
                title={`${preLoad?.searchDriver >= 2
                  ? 'Enviado para  motoristas à caminho'
                  : ' Motoristas à caminho'
                  }`}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '0.5rem',
                }}
              >
                <FaTruck
                  fill={
                    preLoad?.searchDriver >= 2
                      ? theme.colors.success
                      : theme.colors.gray
                  }
                />
                <Text as="span" type="medium" color="gray">
                  Motoristas à caminho
                </Text>
              </span>
              <span
                title={`${preLoad?.searchDriver >= 3
                  ? 'Enviado para motoristas próximos'
                  : 'Motoristas próximos'
                  }`}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '0.5rem',
                }}
              >
                <FaMapMarked
                  fill={
                    preLoad?.searchDriver >= 3
                      ? theme.colors.success
                      : theme.colors.gray
                  }
                />
                <Text as="span" type="medium" color="gray">
                  Motoristas próximos
                </Text>
              </span>
            </div>
          </Col>
        </>
      ) : null}
    </Row>
  );
}
