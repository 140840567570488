const Yup = require('yup');
const moment = require('moment');

function isBeforeThanStartOrigin(value) {
  if (value.start_schedule && value.date) {
    if (value.start_schedule.indexOf('/') > -1) {
      let iniData = '';
      const iniSplit = value.start_schedule.split(' ');
      iniData = `${iniSplit[0].split('/').reverse().join('-')} ${iniSplit[1]}`;

      let fimData = '';
      const fimSplit = value.date.split(' ');
      fimData = `${fimSplit[0].split('/').reverse().join('-')} ${fimSplit[1]}`;

      const isBefore = moment(iniData).isBefore(fimData);

      return isBefore;
    }

    const isBefore = moment(value.start_schedule).isBefore(value.date, 'day');

    return isBefore;
  }

  return true;
}

function isStartOriginAValidDate(value) {
  if (value && !moment(value, 'DD/MM/YYYY HH:mm', true).isValid()) {
    return false;
  }

  return true;
}

function isEndOriginAValidDate(value) {
  if (value && !moment(value, 'DD/MM/YYYY HH:mm', true).isValid()) {
    return false;
  }

  return true;
}

// Destinations

function isBeforeThanStartDest(value) {
  if (value.start_schedule && value.date) {
    if (value.start_schedule.indexOf('/') > -1) {
      let iniData = '';
      const iniSplit = value.start_schedule.split(' ');
      iniData = `${iniSplit[0].split('/').reverse().join('-')} ${iniSplit[1]}`;

      let fimData = '';
      const fimSplit = value.date.split(' ');
      fimData = `${fimSplit[0].split('/').reverse().join('-')} ${fimSplit[1]}`;

      const isBefore = moment(iniData).isBefore(fimData);

      return isBefore;
    }

    const isBefore = moment(value.start_schedule).isBefore(value.date, 'day');

    return isBefore;
  }

  return true;
}

function isStartDestAValidDate(value) {
  if (value && !moment(value, 'DD/MM/YYYY HH:mm', true).isValid()) {
    return false;
  }

  return true;
}

function isEndDestAValidDate(value) {
  if (value && !moment(value, 'DD/MM/YYYY HH:mm', true).isValid()) {
    return false;
  }

  return true;
}

function isDateInMoreThanMinutesFromNow(minutes, date) {
  const dateInformed = moment(date, 'DD/MM/YYYY hh:mm');
  const minimumDate = moment().add(minutes, 'minutes');
  return !date || dateInformed.diff(minimumDate) > 0;
}

export function validateDateTime(value) {
  if (!moment(value, 'DD/MM/YYYY HH:mm', true).isValid()) {
    return false
  }
  return true
}

export const schema = Yup.object().shape({
  operationPreLoad: Yup.object()
    .shape({
      value: Yup.number(),
    })
    .when('$isPreloadWithGRO', {
      is: true,
      then: Yup.object().shape({
        value: Yup.number().required('Operação pré-carga obrigatória'),
      }),
    })
    .required(),
  load_value_type: Yup.object()
    .shape({
      label: Yup.string().required(),
      value: Yup.string().required(),
    })
    .default('null')
    .required('Selecione um Tipo')
    .typeError('Tipo obrigatório'),
  negotiation_type: Yup.object()
    .shape({
      label: Yup.string().required(),
      value: Yup.string().required(),
    })
    .default('null')
    .required('Selecione uma Negociação')
    .typeError('Negociação obrigatória'),
  maxTemperature: Yup.number()
    .nullable()
    .test(
      'both-or-neither',
      'Ambas as temperaturas devem ser preenchidas',
      function (value) {
        const { minTemperature } = this.parent;
        return (
          (value == null && minTemperature == null) ||
          (value != null && minTemperature != null)
        );
      }
    ),
  minTemperature: Yup.number()
    .nullable()
    .test(
      'both-or-neither',
      'Ambas as temperaturas devem ser preenchidas',
      function (value) {
        const { maxTemperature } = this.parent;
        return (
          (value == null && maxTemperature == null) ||
          (value != null && maxTemperature != null)
        );
      }
    )
    .test(
      'min-less-than-max',
      'A temperatura mínima não pode ser maior que a máxima',
      function (value) {
        const { maxTemperature } = this.parent;
        return (
          value == null || maxTemperature == null || value <= maxTemperature
        );
      }
    ),
  loadCreationNumber: Yup.number().required('Campo obrigatório'),
  client: Yup.object()
    .shape({
      id: Yup.number().required('Cliente Tomador obrigatório'),
    })
    .typeError('Cliente Tomador obrigatório'),
  dueDate: Yup.string()
    .required('Data de vencimento obrigatória')
    .test(
      'date-valid-format',
      'Data de vencimento inválida',
      value => value ? validateDateTime(value) : true
    ),
  negotiator: Yup.array()
    .of(Yup.object())
    .min(1, 'Negociador obrigatório')
    .transform(value => {
      if (!value) {
        return undefined;
      }
      return value;
    })
    .required('Negociador obrigatório'),
  country: Yup.object()
    .shape({
      id: Yup.number().required(),
    })
    .typeError('País obrigatório'),
  phone: Yup.string()
    .transform(value => {
      if (!value) {
        return undefined;
      }
      return value.replace('_', '');
    })
    .matches(/^(\(\d{2}\)\d{5}-\d{4}|\d{11})$/, 'Formato incorreto!')
    .required('Telefone obrigatório!'),
  selectedVehicles: Yup.array()
    .of(Yup.object())
    .min(1, 'Selecione pelo menos um veículo!'),
  selectedImplements: Yup.array().when('selectedVehicles', {
    is: value => value && value.filter(e => e.id === 18).length > 0,
    then: Yup.array()
      .min(1, 'Selecione pelo menos um implemento!')
      .required('Selecione pelo menos um implemento!')
      .typeError('Selecione pelo menos um implemento!'),
    otherwise: Yup.array(),
  }),
  riskManager: Yup.mixed().when('tracked', {
    is: true,
    then: Yup.array()
      .of(
        Yup.object().shape({
          id: Yup.number().required(),
        })
      )
      .required('GR para rastreamento obrigatório')
      .typeError('GR para rastreamento obrigatório'),
  }),
  selectedBodies: Yup.array()
    .of(Yup.object())
    .min(1, 'Selecione pelo menos um tipo de carroceria!'),
  selectedDisposition: Yup.object()
    .shape({
      id: Yup.number().required('Disposição da carga obrigatório'),
    })
    .typeError('Disposição da carga obrigatório'),
  pallets: Yup.mixed().when('selectedDisposition', {
    is: value => value && value?.name?.toLowerCase() === 'paletizada',
    then: Yup.number()
      .positive('Pallets precisa ser um valor positivo')
      .max(128, 'Máximo de pallet é 128')
      .required('Número de pallets obrigatório')
      .typeError('Número de pallets obrigatório'),
    otherwise: Yup.mixed().nullable(),
  }),
  weight: Yup.number()
    .positive('Peso precisa ser um valor positivo')
    .required('Peso obrigatório')
    .typeError('Peso obrigatório'),
  product: Yup.object()
    .required('Produto obrigatório')
    .typeError('Produto obrigatório'),
  branchOffice: Yup.object()
    .required('Filial é obrigatória')
    .typeError('Filial é obrigatória'),

  origin: Yup.object()
    .shape({
      address: Yup.string()
        .required('Endereço obrigatório')
        .typeError('Endereço obrigatório'),
      cityModel: Yup.object()
        .required('Cidade obrigatória')
        .typeError('Cidade obrigatória'),
      province: Yup.string()
        .required('UF obrigatório')
        .typeError('UF obrigatório'),
      date: Yup.string()
        .required('Data máxima obrigatória')
        .typeError('Data máxima obrigatória')
        .test('is-end-a-valid-date', 'Data máxima da coleta inválida', value =>
          isEndOriginAValidDate(value)
        ),
      country: Yup.object()
        .required('País obrigatório')
        .typeError('País inválido'),
      zip_code: Yup.string(),
      start_schedule: Yup.string()
        .required('Data início obrigatória')
        .typeError('Data início inválida')
        .test('is-start-a-valid-date', 'Data de início inválida', value =>
          isStartOriginAValidDate(value)
        ),
      lat: Yup.number()
        .required('Selecione um logradouro utilizando o seletor do google')
        .transform((currentValue, originalValue) => {
          return originalValue === '' ? undefined : currentValue;
        }),

      lng: Yup.number()
        .required('Selecione um logradouro utilizando o seletor do google')
        .transform((currentValue, originalValue) => {
          return originalValue === '' ? undefined : currentValue;
        }),
    })
    .when('$limitSchedule', {
      is: true,
      then: Yup.object().shape({
        date: Yup.string()
          .test(
          'is-end-date-more-than-30-mins-from-now',
          'Data máxima da coleta precisa ser no mínimo daqui a 30 minutos',
          value => isDateInMoreThanMinutesFromNow(30, value)
        ),
        start_schedule: Yup.string()
          .test(
          'is-end-date-more-than-30-mins-from-now',
          'Data máxima da coleta precisa ser no mínimo daqui a 30 minutos',
          value => isDateInMoreThanMinutesFromNow(30, value)
        ),
      })
    })
    .test(
      'is-before-than-start-schedule',
      'Data início da coleta não pode ser maior que Data máxima da coleta',
      value => isBeforeThanStartOrigin(value)
    ),

  destinations: Yup.array().of(
    Yup.object()
      .shape({
        address: Yup.string()
          .required('Endereço obrigatório')
          .typeError('Endereço inválido'),
        cityModel: Yup.object()
          .required('Cidade obrigatória')
          .typeError('Cidade inválida'),
        province: Yup.string()
          .required('Estado obrigatório')
          .typeError('Estado inválido'),
        date: Yup.string()
          .required('Data máxima obrigatória')
          .typeError('Data máxima inválida')
          .test('is-end-a-valid-date', 'Data máxima inválida', value =>
            isEndDestAValidDate(value)
          ),
        type: Yup.object()
          .required('Ação obrigatório')
          .typeError('Ação inválida'),
        country: Yup.object()
          .required('País obrigatório')
          .typeError('País inválido'),
        start_schedule: Yup.string()
          .required('Data início obrigatória')
          .typeError('Data início inválida')
          .test(
            'is-start-a-valid-date',
            'Data de início inválida',
            isStartDestAValidDate
          ),
        lat: Yup.number()
          .required('Selecione um logradouro utilizando o seletor do google')
          .transform((currentValue, originalValue) => {
            return originalValue === '' ? undefined : currentValue;
          }),

        lng: Yup.number()
          .required('Selecione um logradouro utilizando o seletor do google')
          .transform((currentValue, originalValue) => {
            return originalValue === '' ? undefined : currentValue;
          }),
      })
      .when('$limitSchedule', {
        is: true,
        then: Yup.object().shape({
          date: Yup.string()
            .test(
            'is-end-date-more-than-30-mins-from-now',
            'Data máxima da coleta precisa ser no mínimo daqui a 30 minutos',
            value => isDateInMoreThanMinutesFromNow(30, value)
          ),
          start_schedule: Yup.string()
            .test(
            'is-end-date-more-than-30-mins-from-now',
            'Data máxima da coleta precisa ser no mínimo daqui a 30 minutos',
            value => isDateInMoreThanMinutesFromNow(30, value)
          ),
        })
      })
      .test(
        'is-before-than-start-schedule',
        'Data início não pode ser maior que Data máxima',
        value => isBeforeThanStartDest(value)
      )
  ),
  cargoValue: Yup.number()
    .required('O valor da carga é obrigatório')
    .positive('O valor da carga deve ser um número positivo maior que 0')
    .moreThan(0, 'O valor da carga deve ser maior que 0.00'),
  costCenter: Yup.object()
    .required('Centro de custo obrigatório')
    .typeError('Centro de custo inválido'),
  negotiation: Yup.object()
    .shape({
      id: Yup.number().required('Campo obrigatório'),
    })
    .required('Negociação obrigatório')
    .typeError('Negociação inválida'),
  crt: Yup.string().max(50, 'Valor máximo de 50 caracteres'),
  taker_value: Yup.string().required(' ').typeError(' '),
  pcp_observation: Yup.string().max(1000, 'Máximo 1000 caracteres'),
  freight_exchange: Yup.number()
    .transform((value, originalValue) => {
      return Number(originalValue);
    }, 'Informe cotação válida')
    .typeError('Informe cotação válida'),
  clientOrderNumber: Yup.string()
    .max(25, 'Valor máximo de 25 caracteres')
    .nullable(),
});

export const ValidatorExtraFields = Yup.array().of(
  Yup.object().shape({
    label: Yup.string()
      .min(3, 'Mínimo 3 caracteres')
      .required('Campo obrigatório')
      .typeError('Campo inválido'),
    value: Yup.string().required('Campo obrigatório'),
  })
);

export default schema;
