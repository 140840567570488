import React, { useState } from 'react';

import { Card, Input, Select, Text } from 'v3/components';
import { usePreLoad } from '../context';
import { Col, Row } from 'react-bootstrap';
import {
  fetchBranchOffices,
  fetchCostCenter,
  fetchProductAndCategorie,
  fetchSelectOptions,
} from 'utils/fetches';
import api from 'services/api';
import CurrencyInput from 'v3/components/Input/Currency';
import { currencyOptions } from 'v3/utils/formatter';
import { validateDateTime } from 'v3/utils/functions';

export function LoadData() {
  const { data, setData, snackbar, errors, setErrors } = usePreLoad();
  const [validatingTaker, setValidatingTaker] = useState(false);
  const loadSymbol = data?.currency?.value === 'BRL' ? 'R$' : '$';
  async function validateClientBlacklist(cpfCnpj) {
    try {
      const response = await api.get(`v3/blacklist-person/?value=${cpfCnpj}`);
      if (response.data.total > 0) {
        snackbar.show(
          <>
            <Text>
              Não é possivel selecionar o Tomador{' '}
              {response.data.data[0].description} - {cpfCnpj} pois ele está
              banido!{' '}
            </Text>
            <Text>Motivo: {response.data.data[0].reason}</Text>
          </>,
          {
            type: 'error',
          }
        );
        setData(old => ({ ...old, client: null }));
      }
      return true;
    } catch (error) {
      snackbar.show(
        <Text>
          Error ao validar o Tomador de CNPJ: {cpfCnpj} na lista de banidos!
        </Text>,
        {
          type: 'error',
        }
      );
      setData(old => ({ ...old, client: null }));
      return false;
    }
  }
  async function handleTakerChange(value) {
    let isClientValid = false;
    setValidatingTaker(true);
    if (value) {
      isClientValid = await validateClientBlacklist(value?.cgccpf);
      if (isClientValid) {
        setData(old => ({ ...old, taker: value }));
      }
    } else {
      setData(old => ({ ...old, taker: null }));
    }
    setValidatingTaker(false);
  }

  return (
    <Card
      header={
        <Text color="#464E5F" type="header">
          Dados da Carga
        </Text>
      }
      bodyCardPadding="1rem 3rem"
    >
      <Row className="form">
        <Col xs={12} md={6} className="mb-3">
          <Select.Async
            label="Cliente Tomador *"
            onSearch={search =>
              fetchSelectOptions('persons/customers', { search })
            }
            value={data?.taker}
            getOptionLabel={option => {
              let label = `${option?.social_name} - ${option?.cgccpf}`;

              if (option.city) {
                label += ` - ${option?.city}`;
              }

              return label;
            }}
            getOptionValue={option => option.id}
            error={errors.taker}
            onChange={handleTakerChange}
            loading={validatingTaker}
          />
        </Col>
        <Col xs={12} md={6} className="mb-3">
          <Select.Async
            label="Centro de custo *"
            onSearch={search => fetchCostCenter({ search })}
            value={data?.costCenter}
            onChange={value => {
              setData(old => ({ ...old, costCenter: value }));
            }}
            getOptionLabel={option => option.name}
            getOptionValue={option => option.id}
            error={errors.costCenter}
          />
        </Col>
        <Col xs={12} md={6} className="mb-3">
          <Select.Async
            label="Produto *"
            onSearch={search => fetchProductAndCategorie(search)}
            value={data?.product}
            getOptionLabel={option =>
              `${`${option.name} - ${option.product_type_name}`}`
            }
            getOptionValue={option =>
              `${`${option?.id}-${option?.product_type_id}`}`
            }
            onChange={value => setData(old => ({ ...old, product: value }))}
            error={errors.product}
          />
        </Col>
        <Col md={6} xs={12} className="mb-3">
          <Select.Async
            label="Filial *"
            onSearch={search => fetchBranchOffices(search)}
            value={data?.branchOffice}
            getOptionLabel={option => `${`${option.id} - ${option.name}`}`}
            getOptionValue={option => option?.id}
            onChange={value =>
              setData(old => ({ ...old, branchOffice: value }))
            }
            error={errors.branchOffice}
          />
        </Col>
        <Col md={6} xs={12}>
          <CurrencyInput
            label={`Valor do frete tomador (${loadSymbol || 'R$'}) *`}
            value={data?.takerValue}
            onChange={(_event, value) => {
              if (_event?.target?.value?.length > 9) {
                return;
              }
              setData(old => ({ ...old, takerValue: value }));
            }}
            error={errors.takerValue}
          />
        </Col>
        <Col md={6} xs={12} className="mb-3">
          <Select
            label="Moeda valor do frete *"
            options={currencyOptions}
            getOptionLabel={option => option.label}
            getOptionValue={option => option.value}
            value={data?.currency}
            onChange={value => {
              setData(old => ({ ...old, currency: value }));
            }}
            disableClear
          />
        </Col>
        <Col md={6} xs={12} className="mb-3">
          <Input
            type="datetime-local"
            value={data?.dueDate}
            label="Data de vencimento *"
            onChange={event => {
              let isValid = validateDateTime(event?.target?.value)
              if (isValid) {
                setData(old => ({ ...old, dueDate: event?.target?.value }));
                setErrors(old => ({ ...old, dueDate: null }));
              } else {
                setErrors(old => ({ ...old, dueDate: 'Data inválida' }));
              }
            }}
            error={errors.dueDate}
          />
        </Col>
      </Row>
    </Card>
  );
}
