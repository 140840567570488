import styled, { keyframes, css } from 'styled-components';

const blink = keyframes`
  0%, 50%, 100% {
    opacity: 1;
  }
  25%, 75% {
    opacity: 0;
  }
`;

export const AnimationCircleStyle = styled.div`
  border-radius:100%;
  position: absolute;
  z-index: 9999;

  ${({ top }) => top !== undefined && `top: ${top}px;`}
  ${({ left }) => left !== undefined && `left: ${left}px;`}
  ${({ right }) => right !== undefined && `right: ${right}px;`}
  ${({ bottom }) => bottom !== undefined && `bottom: ${bottom}px;`}

  background-color: ${({ color }) => color || 'red'};
  width: ${({ width }) => width || 15}px;
  height: ${({ height }) => height || 15}px;

  ${({ animation }) =>
    animation &&
    css`
      animation: ${blink} 2s infinite;
    `};
`;
