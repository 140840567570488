import moment from 'moment';

export const CTE_STATUS_VARIANTS = {
  1: 'success',
  2: 'primary',
  3: 'warning',
  4: 'error',
  5: 'error',
  6: 'warning',
  7: 'success',
  8: 'warning',
  9: 'primary',
  10: 'primary',
};

export const STATUS_DOCS_ISSUED_ORDER = 5;

export const isEmailValidRegex =
  /^(([^<>()\[\]\\.,;:\s@áàâãéèêíïóôõöúùçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const shippingCompanyRequiredData = [
  'cgccpf',
  'social_name',
  'phone',
  'type_id',
];

export const driverRequiredFields = [
  'social_name',
  'cgccpf',
  'phone_cooperplace',
];

export const LOAD_FILTER_DATE_OPTIONS = [
  {
    label: '3 dias',
    value: moment().subtract(3, 'days').format('DD/MM/YYYY'),
    id: 'button-filtro-coleta-agendada-3',
  },
  {
    label: '30 dias',
    value: moment().subtract(30, 'days').format('DD/MM/YYYY'),
    id: 'button-filtro-coleta-agendada-30',
  },
];

export const LOAD_STATUS_OPTIONS = [
  {
    label: 'Atendidas',
  },
  {
    label: 'Não atendidas',
  },
  {
    label: 'Canceladas',
  },
];

export const LOAD_FILTER_SORT_OPTIONS = [
  {
    label: 'Data de coleta crescente',
    value: 'coleta-crescente',
  },
  {
    label: 'Data de coleta decrescente',
    value: 'coleta-decrescente',
  },
  {
    label: 'Data de criação crescente',
    value: 'criacao-crescente',
  },
  {
    label: 'Data de criação decrescente',
    value: 'criacao-decrescente',
  },
];

export const DELIVERY_TYPE_OPTIONS = [
  { name: 'Coleta', id: 'collect' },
  { name: 'Entrega', id: 'delivery' },
  { name: 'Aduaneiro', id: 'customhouse' },
];
export const validAnttRegex = /[0-9.]$/;

export const vehicleRequiredFields = [
  'plate',
  'renavam',
  'chassi',
  'year_model',
  'year_manufacture',
  'plate_city_id',
  'vehicle_type_id',
  'vehicle_body_type_id',
  'antt_type',
  'owner_id',
  'antt_adherence',
  'antt',
];

export const trackedVehicleRequiredFiels = [
  'tracked',
  // 'tracker_code',
  'tracker',
  // 'tracker_type_id',
];

export const wagonRequiredFields = [
  'plate',
  'renavam',
  'chassi',
  'year_model',
  'year_manufacture',
  'plate_city_id',
  'vehicle_type_id',
  'vehicle_body_type_id',
  'vehicle_implement_type_id',
  'antt_type',
  'id_owner',
  'antt_adherence',
  'antt',
  'beneficiary_id',
];

export const clientAddressRequiredFields = [
  'address',
  'lat',
  'lng',
  'city_id',
  'city_name',
  'uf_name',
  'formatted_address',
  'country_id',
];

export const UNDER_GRO_ANALYSIS_SLUG = 'GR_ANALYSIS';
export const APPROVED_GRO_ANALYSIS_SLUG = 'GR_APPROVAL';

export const GroApprovedOptions = [
  {
    slug: 'GR_ANALYSIS',
    name: 'Iniciado análise GR',
  },
  {
    slug: 'GR_APPROVAL',
    name: 'Aprovado GR',
  },
  {
    slug: 'GR_DECLINE',
    name: 'Reprovado GR',
  },
  {
    slug: 'TRAVEL_ALTER_DATA_GR',
    name: 'Alteração de dados da viagem GR',
  },
];

export const TRAVEL_STATUS_CODES = {
  OrdemDeColeta: 2,
  EmViagem: 3,
  AguardandoDescarregamento: 4,
  EmAnalise: 5,
  Finalizado: 6,
  Cancelado: 7,
  AguardandoCarregamento: 8,
  AguardandoEmissao: 9,
  Transbordado: 10,
  CarregamentoFinalizado: 11,
  DocumentacaoEmitida: 12,
  LiberadoColeta: 13,
  Bloqueado: 14,
}

export const SERVICE_LEVEL_DESCRIPTIONS = {
  default: 'default',
  OURO: 'Agregado Ouro',
}

export const SERVICE_LEVEL_VALUES = {
  default: 120,
  OURO: 0,
};
