import React from 'react';
import { Row, Col } from 'react-bootstrap';
import CheckBox from 'components/CheckBox';
import InputCurrency from 'v3/components/Input/Currency/index';
import { Button, DatePicker, Input, Select, Text } from 'v3/components';
import TextArea from 'v3/components/TextArea';
import { useSnackbar } from 'v3/components/Snackbar';
import { onSearchGroups } from '../utils';
import { ButtonsContainer, SectionContainer } from '../styles';
import { OccurencesFieldsContainer } from './styles';
import { AddAttachment } from '../AddAttachments';

export function AddNewOccurrence({
  occurrencesOptions,
  occurrenceType,
  setOccurrenceType,
  responsible,
  setResponsible,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  description,
  setDescription,
  occurrenceValue,
  setOccurrenceValue,
  customerNumber,
  setCustomerNumber,
  setShow,
  handleSaveNewOccurrence,
  savingNewOccurrence,
  pendency,
  setPendency,
  displayClient,
  setDisplayClient,
  errors,
  setErrors,
  files,
  setFiles,
  groupOptions,
}) {
  const snackbar = useSnackbar();
  function handleCancel() {
    setErrors({});
    setOccurrenceType(null);
    setResponsible(null);
    setStartDate('');
    setEndDate('');
    setPendency(false);
    setDisplayClient(false);
    setDescription('');
    setOccurrenceValue('');
    setCustomerNumber('');
    setShow(old => ({ ...old, addNewOccurrence: false }));
  }

  function onChangeResponsible(value, event) {
    if (event.action === 'select-option' && value && value.length > 5) {
      snackbar.show(<Text>Máximo de 5 grupos</Text>, {
        type: 'error',
      });
      return;
    }

    setResponsible(value);
  }

  return (
    <SectionContainer>
      <div>
        <Text type="body" color="dark" weight={500}>
          Adicionar Ocorrência
        </Text>
        <OccurencesFieldsContainer>
          <Row>
            <Col lg={3} md={3} sm={6} xs={12}>
              <Select
                label="Tipo ocorrência *"
                value={occurrenceType}
                onChange={value => setOccurrenceType(value)}
                options={occurrencesOptions}
                getOptionLabel={option => option.name}
                getOptionValue={option => option.id}
                error={errors?.occurrenceType}
              />
            </Col>
            <Col lg={3} md={3} sm={6} xs={12}>
              <Select.Async
                label="Grupo Responsável *"
                onSearch={onSearchGroups}
                value={responsible}
                onChange={onChangeResponsible}
                getOptionLabel={option => option?.name}
                getOptionValue={option => option.id}
                error={errors?.responsible}
                options={groupOptions}
                multiple
              />
            </Col>
            <Col lg={3} md={3} sm={6} xs={12}>
              <DatePicker
                label="Início ocorrência *"
                value={startDate}
                onChange={e => setStartDate(e.target.value)}
                withTime
                error={errors?.startDate}
                placeholder="01/01/2023 13:30"
              />
            </Col>
            <Col lg={3} md={3} sm={6} xs={12}>
              <DatePicker
                label="Término ocorrência"
                value={endDate}
                onChange={e => setEndDate(e.target.value)}
                withTime
                error={errors?.endDate}
                placeholder="01/01/2023 13:30"
              />
            </Col>
            <Col lg={3} md={3} sm={6} xs={12}>
              <Input
                label="Nº ocorrência cliente"
                placeholder="Adicionar numero cliente"
                value={customerNumber}
                onChange={e =>  setCustomerNumber(e.target.value)}
                error={errors?.customerNumber}
              />
            </Col>
            <Col lg={3} md={3} sm={6} xs={12}>
              <InputCurrency
                label="Valor"
                placeholder="Adicionar valor da ocorrência"
                value={occurrenceValue}
                onChange={(_, value) => {
                  if (String(value)?.length > 11) {
                    return;
                  }
                  setOccurrenceValue(value);
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col lg={3} md={3} sm={6} xs={12}>
              <CheckBox
                label="Gera pendência"
                labelStyle={{
                  textTransform: 'capitalize',
                  color: '#464E5F',
                }}
                value={pendency}
                onChange={e => {
                  setPendency(!pendency);
                }}
              />
            </Col>
            <Col lg={3} md={3} sm={6} xs={12}>
              <CheckBox
                label="Cliente visualiza"
                labelStyle={{
                  textTransform: 'capitalize',
                  color: '#464E5F',
                }}
                value={displayClient}
                onChange={e => {
                  setDisplayClient(!displayClient);
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col lg={12} md={12} sm={12} xs={12}>
              <TextArea
                label="Descrição Ocorrência *"
                placeholder="Adicionar descrição"
                value={description}
                onChange={e => setDescription(e.target.value)}
                resizable={false}
                style={{ minHeight: '9rem' }}
                error={errors?.description}
              />
            </Col>
          </Row>
          <Row>
            <Col lg={12} md={12} sm={12} xs={12}>
              <AddAttachment
                files={files}
                setFiles={setFiles}
                loading={savingNewOccurrence}
              />
            </Col>
          </Row>
        </OccurencesFieldsContainer>
      </div>

      <ButtonsContainer>
        <Button
          variant="secondary"
          onClick={handleCancel}
          disabled={savingNewOccurrence}
        >
          <Text weight={500} color="dark" type="regular">
            CANCELAR
          </Text>
        </Button>
        <Button
          variant="primary"
          loading={savingNewOccurrence}
          onClick={handleSaveNewOccurrence}
        >
          <Text weight={500} color="white" type="regular">
            SALVAR
          </Text>
        </Button>
      </ButtonsContainer>
    </SectionContainer>
  );
}
