import React, { useState, useEffect } from 'react';
import { Button, DataTable, Input, Modal, Text } from 'v3/components';
import api, { cooperplaceApi } from 'services/api';
import { useSnackbar } from 'v3/components/Snackbar';
import { Col, Row } from 'react-bootstrap';
import ModalConfirmation from '../ModalConfirmation';
import { ObservationContainer } from './styles';

export default function AggregationOfferModal({ showModal, setShowModal, driverId }) {
  const snackbar = useSnackbar();
  const [aggregationData, setAgreggationData] = useState({});
  const [loadingOffer, setLoadingOffer] = useState(false);
  const [loading, setLoading] = useState(false);
  const [aggregationCodeInput, setAggregationCodeInput] = useState('');
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [selectedAggregationId, setSelectedAggregationId] = useState(null);

  useEffect(() => {
    let isMounted = true;
  
    const fetchAggregationOffer = async () => {
      setLoading(true);
      try {
        const response = await cooperplaceApi.get(`/aggregation-offer?limit=10`);
        if (isMounted) {
          setAgreggationData(response?.data);
        }
      } catch (error) {
        if (isMounted) {
          setAgreggationData([]);
          const errorMessage = error?.response?.data?.message || 'Erro ao buscar ofertas de agregamento';
          snackbar.show(<Text>{errorMessage}</Text>, { type: 'error' });
        }
      } finally {
        if (isMounted) {
          setLoading(false);
        }
      }
    };
    fetchAggregationOffer();
    return () => {
      isMounted = false;
    };
  }, [driverId, showModal]);

  async function onChangeLimit(limit) {
    try {
      setLoading(true);
      const response = await cooperplaceApi.get(`/aggregation-offer?limit=${limit}&page=1`);
      setAgreggationData(response?.data);
    } catch (error) {
      setAgreggationData([])
      snackbar.show(
        <Text>{error?.response?.data?.message || 'Erro ao buscar ofertas de agregamento'}</Text>,
        { type: 'error' }
      );
    } finally {
      setLoading(false);
    }
  }

  async function onChangePage(page) {
    try {
      setLoading(true);
      const response = await cooperplaceApi.get(
        `/aggregation-offer?limit=${aggregationData?.meta?.per_page}&page=${page}`
      );
      setAgreggationData(response?.data);
    } catch (error) {
      snackbar.show(
        <Text>{error?.response?.data?.message || 'Erro ao buscar ofertas de agregamento'}</Text>,
        { type: 'error' }
      );
    } finally {
      setLoading(false);
    }
  }

  async function handleOfferAggregation(aggregationId) {
    setLoadingOffer(true);
    try {
      const offer = await cooperplaceApi.post('/aggregation-invite', {
        person_id: driverId,
        aggregation_id: aggregationId,
      });
      setAggregationCodeInput('');
      snackbar.show(<Text>{`${ offer?.data?.offer?.type_id === 4 ? 'Agregamento ofertado via SMS com sucesso!' : 'Agregamento ofertado via whatsapp com sucesso!'}`}</Text>, { type: 'success' });
    } catch (error) {
      snackbar.show(
        <Text>{error?.response?.data?.message || 'Erro ao ofertar agregamento'}</Text>,
        { type: 'error' }
      );
    } finally {
      setLoadingOffer(false);
    }
  }

  return (
    <>
      <Modal
        show={showModal}
        handleClose={() => setShowModal(false)}
        heading="Oferta de Agregamento"
        body={
          <>
            <Row className="justify-content-center align-items-end">
              <Col md={2} sm={2}>
                <Input
                  label="Ofertar pelo código do agregamento"
                  value={aggregationCodeInput}
                  onChange={(e) => {
                    setAggregationCodeInput(e.target.value);
                  }}
                />
              </Col>
              <Col md={1} sm={2}>
                <Button
                  title="Ofertar Agregamento"
                  style={{ marginRight: '10px' }}
                  loading={loadingOffer}
                  onClick={() => {
                    setSelectedAggregationId(aggregationCodeInput);
                    setShowConfirmationModal(true);
                  }}
                >
                  <Text>Ofertar</Text>
                </Button>
              </Col>
            </Row>
            <DataTable
              data={aggregationData?.data || []}
              loading={loading}
              withFilterOption={false}
              defaultPerPage={10}
              page={aggregationData?.meta?.current_page}
              pages={aggregationData?.meta?.last_page}
              total={aggregationData?.meta?.total}
              onPageChanged={onChangePage}
              onPerPageChanged={onChangeLimit}
              perPageOptions={[{ v: 10 }, { v: 20 }, { v: 30 }]}
              tableStyles={{
                table: {
                  fontSize: '8px',
                  width: '100%',
                },
                td: {
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  padding: '5px 8px',
                },
                th: {
                  padding: '5px 8px',
                  fontWeight: 'bold',
                  fontSize: '12px',
                },
              }}
              columns={[
                {
                  name: 'Ação',
                  selector: (row) => (
                    <Button
                      title="Ofertar Agregamento"
                      style={{ marginRight: '10px' }}
                      loading={loadingOffer}
                      onClick={() => {
                        setSelectedAggregationId(row?.id);
                        setShowConfirmationModal(true);
                      }}
                    >
                      <Text>Ofertar</Text>
                    </Button>
                  ),
                },
                {
                  name: 'Quantidade',
                  selector: (row) => (
                    <Text type="label" weight={500} color="dark">
                      {row?.quantity || '-'}
                    </Text>
                  ),
                },
                {
                  name: 'Eixos',
                  selector: (row) => (
                    <Text type="label" weight={500} color="dark">
                      {row?.veichleAxi?.name || '-'}
                    </Text>
                  ),
                },
                {
                  name: 'Idade Minima',
                  selector: (row) => (
                    <Text type="label" weight={500} color="dark">
                      {row?.minimum_age || '-'}
                    </Text>
                  ),
                },
                {
                  name: 'Cidade',
                  selector: (row) => (
                    <Text type="label" weight={500} color="dark">
                      {row?.city?.name || '-'}
                    </Text>
                  ),
                },
                {
                  name: 'Estado',
                  selector: (row) => (
                    <Text type="label" weight={500} color="dark">
                      {row?.province?.uf || '-'}
                    </Text>
                  ),
                },
                {
                  name: 'Tipo de pessoa',
                  selector: (row) =>
                    row?.person_type === 'bt' ? (
                      <Text type="label" weight={500} color="dark">
                        Todos
                      </Text>
                    ) : row?.person_type === 'pf' ? (
                      <Text type="label" weight={500} color="dark">
                        CPF
                      </Text>
                    ) : (
                      <Text type="label" weight={500} color="dark">
                        CNPJ
                      </Text>
                    ),
                },
                {
                  name: 'Carroceria',
                  selector: (row) => (
                    <Text type="label" weight={500} color="dark">
                      {row?.vehicleBodyType?.name || '-'}
                    </Text>
                  ),
                },
                {
                  name: 'GR rastreamento',
                  selector: (row) => (
                    <Text type="label" weight={500} color="dark">
                      {row?.tracker?.name || '-'}
                    </Text>
                  ),
                },
                {
                  name: 'Observação',
                  selector: (row) => (
                    <ObservationContainer>
                      <Text type="label" weight={500} color="dark">
                        {row?.observation || '-'}
                      </Text>
                  </ObservationContainer>
                  ),
                },
              ]}
            />
          </>
        }
      />
      {showConfirmationModal && (
        <ModalConfirmation
          showModal={showConfirmationModal}
          setShowModal={setShowConfirmationModal}
          text={`Você tem certeza que deseja ofertar este agregamento?  (código: ${selectedAggregationId})`}
          onConfirm={() => {
            handleOfferAggregation(selectedAggregationId);
            setShowConfirmationModal(false);
          }}
        />
      )}
    </>
  );
}
