import React, { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Card, Input, Text } from 'v3/components';
import { usePreLoad } from '../context';
import TextArea from 'v3/components/TextArea';
import Radio from 'v3/components/Radio';
import CheckBox from 'components/CheckBox';

export function OptionalData() {
  const { data, setData, loading, errors, setErrors } = usePreLoad();

  useEffect(() => {
    if (Number(data?.loadCreationNumber) > 1) {
      setData(old => ({
        ...old,
        clientOrderNumber: '',
      }));
    }
  }, [data?.loadCreationNumber]);


  return (
    < Card
      header={
        < Text color="#464E5F" type="header" >
          Dados opcionais
        </Text >
      }
      bodyCardPadding="1rem 3rem"
    >
      <Row className="form">
        <Col xs={12} md={6} className="mb-3">
          <Input
            label="Invoice"
            value={data?.externalInvoiceNumber}
            type={'text'}
            onChange={({ target }) => {
              setData(old => ({
                ...old,
                externalInvoiceNumber: target.value,
              }));
            }}
            disabled={loading}
          />
        </Col>
        <Col md={6} xs={12} className="mb-3">
          <Input
            label="Número do pedido do cliente"
            value={data?.clientOrderNumber}
            onChange={event =>
              setData(old => ({
                ...old,
                clientOrderNumber: event.target.value,
              }))
            }
            error={errors.clientOrderNumber}
            disabled={loading || Number(data?.loadCreationNumber) > 1}
          />
        </Col>
        <Col xs={12} className="mb-3">
          <TextArea
            label="Observações"
            resizable={false}
            value={data?.observations}
            onChange={event =>
              setData(old => ({
                ...old,
                observations: event.target.value,
              }))
            }
            disabled={loading}
          />
        </Col>
        <Col xs={12} className="mb-3">
          <Radio.Group
            label="Publicar carga?"
            onChange={({ target }) => {
              setData(old => ({
                ...old,
                loadPublish: target.value,
              }))
            }}
            value={data?.loadPublish}
            horizontal
            disabled={false}
          >
            <Radio value={true} id="radio-publicar-carga-sim">
              <Text color="dark" type="label">
                Sim
              </Text>
            </Radio>
            <Radio value={false} id="radio-publicar-carga-nao">
              <Text color="dark" type="label">
                Não
              </Text>
            </Radio>
          </Radio.Group>
        </Col>
        <Col md={6} xs={12} className="mb-3">
          <Text as="span" color="dark" type="medium" weight={500}>
            Buscar motoristas no APP
          </Text>
          <CheckBox
            disabled={loading}
            label="Sim"
            labelStyle={{
              textTransform: 'capitalize',
              color: '#464E5F',
            }}
            value={data?.searchDriver}
            checked={data?.searchDriver}
            onChange={(e, checked) => {
              if (checked === false) {
                setData(old => ({
                  ...old,
                  searchDriverRadius: '0',
                }));
                setErrors(old => ({ ...old, searchDriverRadius: null }));
              } else {
                setData(old => ({
                  ...old,
                  searchDriverRadius: 500,
                }));
              }
              setData(old => ({
                ...old,
                searchDriver: checked,
              }));
            }}
          />
        </Col>
        <Col md={6} xs={12} className="mb-3">
          <Input
            label={
              data?.searchDriver
                ? 'Raio de busca de motorista (Km)*'
                : 'Raio de busca de motorista (Km)'
            }
            tooltip="Máximo de 500km"
            value={data?.searchDriverRadius}
            onKeyDown={evt =>
              ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault()
            }
            onChange={event => {
              if (
                Number(event?.target?.value) > 500 ||
                Number(event?.target?.value) < 0 ||
                event?.target?.value.length > 6
              ) {
                return;
              }
              setData(old => ({
                ...old,
                searchDriverRadius: event.target.value,
              }));
            }}
            error={errors.searchDriverRadius}
            type="number"
            min="1"
            max="500"
            disabled={
              loading ||
              data?.searchDriver === false ||
              data?.searchDriver === null
            }
          />
        </Col>
      </Row>
    </Card >
  );
}
