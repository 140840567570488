/* eslint-disable import/no-unresolved */
import React from 'react';
import { cooperplaceApi } from 'services/api';
import dealApi from 'services/apis/deal';
import Text from 'v3/components/Text';

export async function confirmCancelOption({
  setLoading,
  setConfirmButtonDisabled,
  reasonDescription,
  reasonSelected,
  snackbar,
  history,
  loadId,
}) {
  if (!loadId) {
    return;
  }
  setConfirmButtonDisabled(true);

  if (reasonSelected.value === undefined) {
    snackbar.show(<Text>Por favor, selecione um motivo.</Text>, {
      type: 'error',
    });
    setConfirmButtonDisabled(false);
    return;
  }

  if (!reasonDescription) {
    snackbar.show(
      <Text>Por favor, escreva uma descrição sobre o motivo.</Text>,
      { type: 'error' }
    );
    setConfirmButtonDisabled(false);
    return;
  }

  if (reasonDescription.length < 30) {
    snackbar.show(
      <Text>Por favor, a descrição precisa ter pelo menos 30 caracteres.</Text>,
      { type: 'error' }
    );
    setConfirmButtonDisabled(false);
    return;
  }

  const motivo = reasonSelected.value;
  const descricao = reasonDescription;

  try {
    setLoading(true)
    const response = await cooperplaceApi.post(`loads/${loadId}/cancel`, {
      deletedReason: motivo,
      deletedReasonDescription: descricao,
    });

    if (response.status === 200) {
      snackbar.show(
        <Text>
          Pré Carga cancelada com sucesso! Você será redirecionado para a
          listagem
        </Text>,
        { type: 'success' }
      );
      setTimeout(() => {
        setLoading(false);
        history.push('/pre-cargas');
      }, 3000);
      return;
    } else {
      setLoading(false);
      return snackbar.show(<Text>{response.message}</Text>, { type: 'error' })
    }
  } catch (ex) {
    if (ex.response?.data?.message) {
      snackbar.show(<Text>{ex.response?.data?.message}</Text>, {
        type: 'error',
      });
    } else
      snackbar.show(<Text>Não foi possível cancelar a pré carga.</Text>, {
        type: 'error',
      });
    setConfirmButtonDisabled(false);
    setLoading(false);
  }
}
