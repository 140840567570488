/* eslint-disable import/no-unresolved */
import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';

import useQuery from 'hooks/useQuery';
import useCurrentUser from 'hooks/useCurrentUser';
import dealAPI, { createSocketConnection } from 'services/apis/deal';
import ConversationList from '../conversationList/index';
import Messenger from '../messenger/index';
import ConversationSearch from '../conversationSearch/index';
import { useSnackbar } from 'v3/components/Snackbar';
import cooperplaceApi from 'services/apis/cooperplace';
import TablePagination from '@material-ui/core/TablePagination';

import './style.css';
import { RequestLoad } from 'v3/pages/PreLoad/List/Modals/RequestLoad';
import { useModal } from 'hooks';
import { usePermission } from 'hooks';

import { Text } from 'v3/components';

import { Filters } from 'pages/chat/Filters';
import { StatusSelector } from '../StatusSelector';
import Loading from 'components/Loading';
import { Row } from 'react-bootstrap';

const socket = createSocketConnection();

function Chat() {
  const queryParams = useQuery();
  const history = useHistory();
  const snackbar = useSnackbar();

  const requestPreLoadModal = useModal();
  const [requestPreLoad, setRequestPreLoad] = useState({
    id: null,
    currency: null,
  });
  const [messages, setMessages] = useState();
  const [dealList, setDealList] = useState([]);
  const [selectedDeal, setSelectedDeal] = useState();
  const [currentDeal, setCurrentDeal] = useState(null);
  const currentUser = useCurrentUser();
  const [selectedDealList, setSelectedDealList] = useState();
  const [listReload, setListReload] = useState(null)
  const [loadNumber, setLoadNumber] = useState('');
  const [filters, setFilters] = useState();

  const [iniciateButton, setIniciateButton] = useState();
  const [totalDeals, setTotalDeals] = useState();
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState();
  const [showActionButton, setShowActionButton] = useState(false);
  const [loadingMessages, setLoadingMessages] = useState(false);
  const [alertMessageNotAttend, setAlertNotMessageAttend] = useState();
  const [alertMessageAttend, setAlertMessageAttend] = useState();

  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(30);
  const [totalPages, setTotalPages] = useState(0);

  usePermission('VISUALIZAR_NEGOCIACOES', { redirect: true });

  const fetchData = async () => {
    try {
      const response = await cooperplaceApi.get(
        `/user/definitions?types[]=chat_negotiator_filters`
      );
      if (response?.data[0]?.definition) {
        const definition = JSON.parse(response?.data[0]?.definition);
        setFilters(definition);
      }
    } catch (error) {
      snackbar.show(<Text>Erro ao recuperar preferência de filtros</Text>, {
        type: 'error',
      });
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (!selectedDealList) {
      const local = JSON.parse(localStorage.getItem('selected_deal_list'));
      setSelectedDealList(local);

      if (local) {
        if (local?.selected_only_closed) {
          setSelected(0);
        } else if (local?.selected_in_service) {
          setSelected(1);
        } else if (local?.selected_not_attended) {
          setSelected(2);
        } else {
          return null
        }
      } else {
        setSelected(2);
        handleStatusSelected({ selected_not_attended: true })
      }
    }

    if (filters) {
      filterNegotiations();
    }
  }, [selectedDealList, loadNumber, listReload, filters, page, perPage]);

  useEffect(() => {
    if (dealList.length > 0) {
      markReadAlertMessage(dealList)
    }
  }, [selectedDeal]);

  const markReadAlertMessage = (dealList) => {

    const findSelectedNotAttended = dealList.length > 0 ? dealList.every(deal => !deal.negotiator_id && !deal.closed) : false
    const findSelectedInService = dealList.length > 0 ? dealList.every(deal => deal.negotiator_id && !deal.closed) : false

    if (findSelectedNotAttended) {
      const findMessages = dealList.every(deal => deal.messages_not_reads)

      if (findMessages) {
        setAlertNotMessageAttend(false)
      } else {
        setAlertNotMessageAttend(true)
      }
    } else {
      webUnread(currentUser.id).then(response => {
        if (response.selectedNotAttended > 0) {
          setAlertNotMessageAttend(true)
        } else {
          setAlertNotMessageAttend(false)
        }
      })
    }

    if (findSelectedInService) {
      const findMessages = dealList.filter(deal => deal.negotiator_id == currentUser.id).every(deal => deal.negotiator_not_reads == 0)

      if (findMessages) {
        setAlertMessageAttend(false)
      } else {
        setAlertMessageAttend(true)
      }
    } else {
      webUnread(currentUser.id).then(response => {
        if (response?.selectedinService > 0) {
          setAlertMessageAttend(true)
        } else {
          setAlertMessageAttend(false)
        }
      })
    }
  }

  const webUnread = async (user_id) => {
    try {
      const response = await dealAPI.get(`messages/WebUnread/${user_id}`);
      return response.data;
    } catch (error) {
      console.log(error)
      return null;
    }
  }

  function handleStatusSelected(param) {
    const dealId = +queryParams.get('deal_id');

    if (!dealId) {
      setSelectedDeal(null);
      setCurrentDeal(null);

      if (!selectedDealList?.selected_not_attended) {
        setFilters(old => ({
          ...old,
          my_attended: true
        }))
      }
    }

    localStorage.setItem('selected_deal_list', JSON.stringify(param));

    setSelectedDealList(param);
  }

  const updateFilters = useCallback(
    async () => {
      try {

        if (filters) {
          await cooperplaceApi.post(`/user/definitions`, {
            type: 'chat_negotiator_filters',
            definition: JSON.stringify(filters),
          })
        }
      } catch (error) {
        snackbar.show(<Text>Erro ao salvar preferencia de filtros</Text>, {
          type: 'error',
        });
      }
    }, [filters]
  )

  const fetchDeal = async (dealId) => {
    if (dealId === 0) {
      return null;
    }

    try {
      const response = await dealAPI.get(`deals/showDeal/${dealId}`);
      return response.data;
    } catch (error) {
      return null;
    }
  }

  const markRead = async (dealId, negotiatorId) => {
    try {
      const response = await dealAPI.put(`messages/markRead/${dealId}/${negotiatorId}`);
      return response.data;
    } catch (error) {
      return null;
    }
  }

  const updateOrCreatedMessagesUnread = async (deal_id, negotiator_id) => {
    try {
      const response = await dealAPI.put(`messages/updateMessagesUnread/${deal_id}/${negotiator_id}`);
      return response.data;
    } catch (error) {
      console.log(error)
      return null;
    }
  }

  useEffect(() => {
    if (!selectedDealList?.selected_not_attended) {
      updateFilters()
    }
  }, [filters])

  const orderListDealsByCreatedAt = (incoming, newMessage) => {

    setDealList(oldDealList => {
      const updatedDealList = oldDealList.map(deal => {

        if (deal.id === incoming) {
          return {
            ...deal,
            last_date: newMessage ? newMessage.created_at : deal.last_date,
          };
        }
        return deal;
      });

      return updatedDealList.sort((a, b) => {
        const timestampA = a.last_date ? new Date(a.last_date).getTime() : 0;
        const timestampB = b.last_date ? new Date(b.last_date).getTime() : 0;
        return timestampB - timestampA;
      });
    });
  }

  function markDealNewMessage(incoming, newMessage) {
    setDealList(oldDealList =>
      oldDealList.map(deal => {
        if (incoming?.deal_id && deal.id === incoming.deal_id) {
          if (deal.driver_id === incoming.sender_id) {
            return {
              ...deal,
              negotiator_not_reads: newMessage ? (deal.negotiator_not_reads || 0) + 1 : 0,
            };
          }

          return deal

        } else if (deal?.id && deal.id === incoming.id) {
          return {
            ...deal,
            negotiator_not_reads: newMessage ? (deal.negotiator_not_reads || 0) + 1 : 0,
          };

        } else {
          return deal;
        }
      })
    );
  }

  function markDealNewMessageNegotiatorIsNull(incoming, newMessage) {
    setDealList(oldDealList =>
      oldDealList.map(deal => {
        if (deal.id === incoming) {
          return {
            ...deal,
            messages_not_reads: newMessage ? false : true,
          };
        }
        return deal;
      })
    );
  }

  const markLastMessage = (incoming, newMessage) => {
    setDealList(oldDealList =>
      oldDealList.map(deal => {
        if (deal.id === incoming) {
          return {
            ...deal,
            last_message: newMessage,
          };
        }
        return deal;
      })
    );
  }

  function setupQueryParams() {
    history.push({ pathname: 'negociacoes', search: queryParams.toString() });
  }

  async function getNegotiationMessages(deal) {

    try {
      setLoadingMessages(true)
      const response = await dealAPI.get(`messages?deal_id=${deal.id}`);
      const inicButton = await dealAPI.get(
        `deals/showDealButton/${deal.driver_id}/${deal.load_id}`
      );

      setIniciateButton(inicButton.data);
      setMessages(response.data);

    } catch (error) {
      snackbar.show(<Text>Não foi possivel exibir a negociação</Text>, {
        type: 'error',
      });
      setIniciateButton(null);
      setMessages(null);
    } finally {
      setLoadingMessages(false);
    }
  }

  function openConversationItem(deal) {

    window.scrollTo(0, 1000);
    setSelectedDeal(deal);
    if (currentDeal === deal.id) {
      return;
    }

    getNegotiationMessages(deal);
    setupQueryParams();
    setCurrentDeal(deal.id);

    if (deal.negotiator_id && deal.negotiator_id === currentUser.id) {
      markDealNewMessage(deal, false);
    }

    if (deal.negotiator_id === null && deal.closed === null) {
      markDealNewMessageNegotiatorIsNull(deal.id, false);
    }
  }

  const getAndSetDeal = async () => {
    const dealId = +queryParams.get('deal_id');

    const deal = await fetchDeal(dealId)

    if (deal) {
      if (deal.negotiator_id === null && deal.closed === null) {
        handleStatusSelected({ selected_not_attended: true })
        setSelected(2);
        openConversationItem(deal)
        history.replace(window.location.pathname)
      }

      if (deal.negotiator_id && deal.closed === null) {
        handleStatusSelected({ selected_in_service: true })
        setSelected(1);
        openConversationItem(deal)
        history.replace(window.location.pathname)
      }

      if (deal.closed === true) {
        handleStatusSelected({ selected_only_closed: true })
        setSelected(0);
        openConversationItem(deal)
        history.replace(window.location.pathname)
      }
    }
  }

  useEffect(() => {
    getAndSetDeal()
  }, [queryParams]);

  useEffect(() => {

    socket.on('reloadList', args => {
      if (args.receiver_id === currentUser.id) {
        setListReload(new Date().getMilliseconds())
      }
    })

    socket.on('newDeal', args => {
      if (selectedDealList?.selected_not_attended && args?.receiver_id === currentUser?.id)
        filterNegotiations()
    })

    socket.on('reloadClosedChat', args => {

      if (!selectedDealList?.selected_in_service) {
        setListReload(new Date().getMilliseconds())
      }

      if (selectedDeal?.id === args.id) {
        setSelectedDeal(args);
        setCurrentDeal(args);
      }
    })

    socket.on('reloadChatUpdateNegotiator', args => {
      if (selectedDealList?.selected_in_service) {
        setListReload(new Date().getMilliseconds())
      }

      if (args?.id === selectedDeal?.id) {
        setSelectedDeal(args)
      }
    })

    socket.on('message', incomingMessage => {

      if (!selectedDealList?.selected_only_closed) {
        orderListDealsByCreatedAt(incomingMessage.deal_id, incomingMessage);
      }

      if (incomingMessage.receiver_id) {
        markDealNewMessage(incomingMessage, true);
        markLastMessage(incomingMessage.deal_id, incomingMessage.message);
      }

      if (incomingMessage.receiver_id === null) {
        markDealNewMessageNegotiatorIsNull(incomingMessage.deal_id, true);
        markLastMessage(incomingMessage.deal_id, incomingMessage.message);
        setAlertNotMessageAttend(true)
      }

      if (incomingMessage.receiver_id === currentUser.id) {
        setAlertMessageAttend(true)
      }

      if (selectedDeal) {
        if (selectedDeal.id !== incomingMessage.deal_id) {
          return;
        }

        setMessages(oldMessages => [
          ...oldMessages.filter(oldMessage => oldMessage.id),
          incomingMessage,
        ]);

        if (selectedDeal.negotiator_id === currentUser.id) {

          markDealNewMessage(selectedDeal, false);

          if (incomingMessage.receiver_id === currentUser.id) {
            markRead(selectedDeal.id, selectedDeal.negotiator_id).catch(error => {
              console.error('Erro ao marcar como lido:', error);
            });
            socket.emit('read', incomingMessage);
          }
        }

        if (selectedDeal?.negotiator_id === null && selectedDeal?.closed === null) {
          markDealNewMessageNegotiatorIsNull(selectedDeal.id, false);

          updateOrCreatedMessagesUnread(selectedDeal.id, currentUser.id).catch(error => {
            console.error('Erro ao marcar como lido:', error);
          })
        }
      }
    });

    return () => {
      socket.removeEventListener('message');
      socket.removeEventListener('reloadList');
      socket.removeEventListener('reloadClosedChat');
      socket.removeEventListener('reloadChatUpdateNegotiator');
      socket.removeEventListener('newDeal');
    };
  }, [selectedDeal, setMessages, currentDeal, selectedDealList]);

  const validParams = (args) => {
    const params = +queryParams.get('deal_id')

    if (!args && params == 0) {

      const locaStorage = localStorage.getItem('selected_deal_list')
      const parse = JSON.parse(locaStorage)

      if (parse?.selected_only_closed) {
        setSelected(0)
      }

      if (parse?.selected_in_service) {
        setSelected(1)
      }

      if (parse?.selected_not_attended) {
        setSelected(2)
      }

      return parse
    } else {
      return args
    }
  }

  const filterNegotiations = async () => {
    try {
      setLoading(true);
      const response = await dealAPI.get(`deals/web`, {
        params: {
          negotiator_id: currentUser.id,
          load_id: loadNumber || null,
          ...validParams(selectedDealList),
          ...filters,
          page,
          perPage
        },
      });

      setDealList(response.data.data);
      markReadAlertMessage(response.data.data)

      setTotalDeals(response.data.paginate.total)
      setTotalPages(response.data.totalPages)

      return response.data;
    } catch (error) {
      setDealList([]);
    } finally {
      setLoading(false);
    };
  }

  function renderNegotiations(negotiationsQty) {
    return (
      <Grid container className="chat">
        <Grid item md={4} xs={12}>
          <RequestLoad
            loadId={requestPreLoad?.id}
            show={requestPreLoadModal.isOpen}
            onClose={() => {
              requestPreLoadModal.close();
              setRequestPreLoad(null);
            }}
            fetchPreLoads={() => {
              queryParams.delete('deal_id');
              setupQueryParams();
              setSelectedDeal(null);
              setCurrentDeal(null);
              setMessages();
              setRequestPreLoad({ id: null, currency: null })
              filterNegotiations();
            }}
          />
          <StatusSelector
            handleStatusSelected={handleStatusSelected}
            setSelected={setSelected}
            selected={selected}
            loading={loading}
            setPage={setPage}
            alertMessageNotAttend={alertMessageNotAttend}
            alertMessageAttend={alertMessageAttend}
          />
          <Filters
            setFilters={setFilters}
            filters={filters}
            selectedDealList={selectedDealList}
            loading={loading}
            setPage={setPage}
          />
          <ConversationSearch
            handleStatusSelected={handleStatusSelected}
            setLoadNumber={setLoadNumber}
            totalDeals={totalDeals}
            loading={loading}
            setPage={setPage}
          />
          {loading ? (
            <Row style={{ display: 'flex', justifyContent: 'center' }}>
              <Loading size={40} />
            </Row>
          ) : (
            <div className="sidebar">
              {negotiationsQty ? (
                <>
                  <ConversationList
                    deals={dealList}
                    click={openConversationItem}
                    selected={selectedDeal || {}}
                    selectedDealList={selectedDealList}
                  />
                  <div style={{ textAlign: 'center', margin: '10px 0' }}>
                    Página {page + 1} de {totalPages}
                  </div>
                  <TablePagination
                    component="div"
                    count={totalDeals}
                    page={page}
                    onPageChange={(event, newPage) => {
                      setPage(newPage);
                      setSelectedDeal(null);
                      setCurrentDeal(null);
                    }}
                    rowsPerPage={perPage}
                    onRowsPerPageChange={(event) => {
                      setPerPage(parseInt(event.target.value, 10))
                      setPage(0)
                      setSelectedDeal(null);
                      setCurrentDeal(null);
                    }}
                    nextIconButtonProps={{ disabled: page + 1 >= totalPages }}
                    backIconButtonProps={{ disabled: page === 0 ? true : false }}
                    rowsPerPageOptions={[
                      { label: '30', value: 30 },
                      { label: '50', value: 50 },
                      { label: '100', value: 100 }
                    ]}
                    labelRowsPerPage="Itens por página:"
                    labelDisplayedRows={() => null}
                  />
                </>

              ) : (
                <Grid
                  container
                  item
                  md={12}
                  justifyContent="center"
                  style={{ marginTop: '20px' }}
                >
                  <div className="conversation-list-item">
                    <p className="conversation-snippet">
                      Não foram encontradas negociações para você.
                    </p>
                  </div>
                </Grid>
              )}
            </div>
          )}
        </Grid>
        <Grid item md={8} xs={12} className="box-chat">
          {
            loadingMessages ? (
              <Row style={{ display: 'flex', justifyContent: 'center', height: '100%', alignItems: 'center' }}>
                <Loading size={50} />
              </Row>
            ) : (
              selectedDeal && (
                <Messenger
                  getNegotiationMessages={getNegotiationMessages}
                  messages={messages}
                  setMessages={setMessages}
                  deal={selectedDeal}
                  setDeal={setSelectedDeal}
                  iniciateButton={iniciateButton}
                  setIniciateButton={setIniciateButton}
                  requestPreLoad={requestPreLoad}
                  setRequestPreLoad={setRequestPreLoad}
                  requestPreLoadModal={requestPreLoadModal}
                  filterNegotiations={filterNegotiations}
                  setSelected={setSelected}
                  setShowActionButton={setShowActionButton}
                  showActionButton={showActionButton}
                  setupQueryParams={setupQueryParams}
                  openConversationItem={openConversationItem}
                  setSelectedList={setSelected}
                  setLoading={setLoading}
                  setCurrentDeal={setCurrentDeal}
                  handleStatusSelected={handleStatusSelected}
                  setLoadingMessages={setLoadingMessages}
                  setPage={setPage}
                />
              )
            )
          }
        </Grid>
      </Grid>
    );
  }

  return <>{renderNegotiations(dealList ? dealList.length : 0)}</>;
}

export default Chat;
