import { useEffect, useState } from 'react';
import { cooperplaceApi } from 'services/api';

const setDefinitions = async (key, newFilter) => {
  try {
    await cooperplaceApi.post(`/user/definitions`, {
      type: key,
      definition: JSON.stringify(newFilter),
    });
  } catch (error) {
    return null;
  }
};

export const useDatabaseDefinitions = (key, defaultValue) => {
  const [value, setValue] = useState(() => {
    try {
      return defaultValue;
    } catch (error) {
      return defaultValue;
    }
  });

  useEffect(() => {
    setDefinitions(key, value).then();
  }, [key, value]);

  return [value, setValue];
};
