/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
/* eslint-disable import/no-unresolved */
import React, { useState, useEffect, useContext } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Text, Button, Select, Card, Modal, Badge } from 'v3/components';
import { useSnackbar } from 'v3/components/Snackbar';
import Tooltip from 'v3/components/Tooltip';
import api, { cooperplaceApi } from 'services/api';
import { useCurrentUser, useModal, usePermission } from 'hooks';
import { BlockedTravelContext } from 'contexts/BlockedTravelProvider';
import { ShippingCompanyServiceLevelMapper } from 'v3/utils/mapper';
import { checkShippingCompanyData } from 'v3/pages/PreLoad/functions';
import RegisterShippingCompanyModal from '../Modals/RegisterShippingCompany';
import ShippingCompanyDetails from '../Modals/ShippingCompanyDetails';
import { TravelContext, checkIfBlacklisted } from '../controller';
import { fetchShippingCompanies } from '../../shared/Data/controller';

const [, useTravel] = TravelContext;

export default function ShipperData() {
  const { setItensToFetchMargin, setIsFetchingBenner } =
    useContext(BlockedTravelContext);
  const travel = useTravel();
  const [showModal, setShowModal] = useState(false);
  const [shippingCompany, setShippingCompany] = useState();
  const [shippingCompanyDrivers, setShippingCompanyDrivers] = useState();
  const hasPermission = usePermission('CADASTRAR_TRANSPORTADORA');

  const snackbar = useSnackbar();
  const registerShippingCompany = useModal();
  const shippingCompanyDetails = useModal();
  const user = useCurrentUser();

  const shippingCompanyLevel =
    ShippingCompanyServiceLevelMapper[
    travel?.data?.shippingCompany?.shippingCompanies
      ?.description_service_level
    ] ?? 'Terceiro';

  function handleCloseModal() {
    setShowModal(!showModal);
  }

  async function fetchShippingCompanyDrivers(id) {
    try {
      const response = await api.get(`shipping-companies/drivers/${id}`);
      setShippingCompanyDrivers(response.data);
      return response.data;
    } catch (error) {
      // handle exeption
    }
  }

  async function addShippingCompany(value) {
    const onBlacklistedCallback = blacklistInfo =>
      snackbar.show(
        <>
          <Text>
            Não foi possível selecionar esse transportador pois ele está banido
          </Text>
          {blacklistInfo?.reason && (
            <Text> Razão: {blacklistInfo.reason} </Text>
          )}
        </>,
        { type: 'error' }
      );
    const onApiErrorCallback = () =>
      snackbar.show(<Text>Não foi possível validar o transportador!</Text>, {
        type: 'error',
      });
    if (
      await checkIfBlacklisted(
        value?.cgccpf,
        onBlacklistedCallback,
        onApiErrorCallback
      )
    )
      return;
    if (checkShippingCompanyData(value)) {
      setShippingCompany(value);
      snackbar.show(<Text>Faltam dados obrigatórios da transportadora!</Text>, {
        type: 'error',
      });
      shippingCompanyDetails.open();
    } else {
      travel.setData(old => ({ ...old, shippingCompany: value }));
    }
  }

  async function updateShippingCompanies(shippingId) {
    const getShippingUrl = `v3/shipping-company/${shippingId}`;
    const getShipping = await api.get(getShippingUrl);
    const updatedShippingCompany = getShipping.data[0].shippingCompanies;
    setItensToFetchMargin(prevState => ({
      ...prevState,
      shippingCompany: updatedShippingCompany,
    }));
  }

  useEffect(() => {
    if (shippingCompanyDrivers?.length > 0) {
      setShowModal(!showModal);
    }
  }, [shippingCompanyDrivers]);

  useEffect(() => {
    if (travel.data.shippingCompany) {
      fetchShippingCompanyDrivers(travel.data.shippingCompany.id);
    }
  }, [travel.isLoading, travel.data.shippingCompany]);

  useEffect(() => {
    async function importFromBenner(document) {
      try {
        setIsFetchingBenner(true);
        const url = `shipping-company/importFromBenner?cpfCnpj=${document}`;
        const response = await cooperplaceApi.get(url);
        const { data } = response;
        await updateShippingCompanies(data.id);
        snackbar.show(<Text>{data?.message}</Text>, {
          type: 'success',
        });
      } catch (error) {
        snackbar.show(
          <Text>
            {`Transportadora não importada do Benner! Valores de Margem ERP e
            porcentagem máxima de adiantamento ERP aplicadas de acordo com nível
            de serviço ${shippingCompanyLevel?.toLowerCase()}`}
          </Text>,
          {
            type: 'error',
          }
        );
      } finally {
        setIsFetchingBenner(false);
      }
    }
    if (travel?.data?.shippingCompany) {
      importFromBenner(travel.data.shippingCompany?.cgccpf);
    }
  }, [travel?.data?.shippingCompany]);

  function getModalBody() {
    return (
      <>
        <Row>
          <Col xs={12} className="px-5">
            <Text color="dark">
              Defina o motorista que irá realizar essa viagem
            </Text>
          </Col>
          <Col xs={12} className="px-4 mb-3">
            {shippingCompanyDrivers?.map(item => (
              <>
                <div className="d-flex align-content justify-content-between pt-4">
                  <Text color="dark">{item.social_name}</Text>
                  <Button
                    onClick={() => {
                      travel.setData(old => ({ ...old, driver: item }));
                      handleCloseModal();
                    }}
                    style={{ height: '28px' }}
                    className="py-0"
                    variant="success"
                  >
                    <Text type="regular" weight="500">
                      Escolher
                    </Text>
                  </Button>
                </div>
                <Text type="regular" color="gray">
                  {item?.cgccpf}
                </Text>
                <hr />
              </>
            ))}
          </Col>
        </Row>
      </>
    );
  }

  return (
    <>
      <RegisterShippingCompanyModal
        isOpen={registerShippingCompany.isOpen}
        onClose={registerShippingCompany.close}
      />
      <ShippingCompanyDetails
        isOpen={shippingCompanyDetails.isOpen}
        onClose={shippingCompanyDetails.close}
        shippingCompany={shippingCompany}
        setShippingCompany={setShippingCompany}
      />
      <Modal
        scrollable
        show={showModal}
        handleClose={handleCloseModal}
        heading={
          <Text type="header" color="dark" weight="500">
            Motoristas / Veículos
          </Text>
        }
        body={getModalBody()}
        size="lg"
      />
      <Card
        header={
          <Text color="#464E5F" type="header">
            Transportador
          </Text>
        }
      >
        <Row className="form">
          <Col xs={12}>
            <Select.Async
              label="Transportador *"
              NoOptionsComponent={() => (
                <Text
                  color="gray"
                  type="regular"
                  onClick={() => registerShippingCompany.open()}
                  clickable
                  id="button-nenhum-transportador-encontrado"
                >
                  Nenhum transportador encontrado, clique aqui para cadastrar
                </Text>
              )}
              onSearch={search =>
                fetchShippingCompanies({
                  search,
                  companyId: user.company_id,
                })
              }
              value={travel.data.shippingCompany}
              getOptionLabel={option => {
                const label = `${option.social_name} - ${option?.cgccpf}`;
                return label;
              }}
              getOptionValue={option => option.id}
              onChange={value => addShippingCompany(value)}
              error={travel.errors?.shippingCompanyId}
              id="select-transportador"
            />
            {hasPermission && (
              <Tooltip
                content={
                  <Text type="label">
                    Cadastro simplificado de transportadora
                  </Text>
                }
              >
                <Text
                  type="label"
                  weight="500"
                  color="dark_blue"
                  clickable
                  onClick={() => registerShippingCompany.open()}
                  id="button-novo-transportador"
                >
                  Transportadora nova? Clique aqui para cadastrar
                </Text>
              </Tooltip>
            )}
            {
              travel.data.shippingCompany && (
                <Row style={{ paddingLeft: '8px' }}>
                  <Text type={'label'} color={'placeholder'}>
                    Nivel de serviço: {
                      travel?.data?.shippingCompany?.shippingCompanies?.description_service_level
                      || 'Não informado'
                    }
                  </Text>
                </Row>
              )
            }
          </Col>
        </Row>
      </Card>
    </>
  );
}
