import React, { useState, useCallback, useEffect } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';

import dashboardApi from 'services/apis/dashboard';
import { Table, Text, Button } from 'v3/components';
import { formatDateTime } from 'v3/utils/formatter';

import { useQuery } from 'hooks';
import Card, { Number, Description, SeeAll } from './Card';
import { validatePermission } from 'actions';

export default function PreLoadRequests({ dashboard, name, id }) {
  const hasPermissionToAttend = validatePermission('ATENDER_PRECARGA');
  const [isFetching, setFetching] = useState(false);
  const [data, setData] = useState({});
  const [filter, setFilter] = useState({});
  const [currentPage, setCurrentPage] = useState({});
  const [pagination, setPagination] = useState({});
  const [loading, setLoading] = useState(false);
  const [daysFromNow, setDaysFromNow] = useState(3);

  const queryParams = useQuery();

  useEffect(() => {
    setDaysFromNow(moment(new Date()).diff(filter.start_date, 'days'));
  }, [filter.start_date]);

  const fetchAvailableLoads = useCallback(async (filters = {}) => {
    try {
      setLoading(true);

      const {
        branches = [],
        negotiators = [],
        costCenters = [],
        pcps = [],
        start_date,
        end_date,
        page = 1,
      } = filters;

      if (page === 1) {
        setFetching(true);
      }

      setFilter(filters);
      setCurrentPage(+page);

      const response = await dashboardApi.get('pre-attendances', {
        params: {
          start_date,
          negotiators: negotiators?.map(negotiator => negotiator?.id),
          costCenters: costCenters?.map(costCenter => costCenter?.id),
          branches: branches?.map(branch => branch?.id),
          pcps: pcps?.map(pcp => pcp?.id),
          end_date: end_date || undefined,
          page,
        },
      });
      setPagination(response.data.last.pagination);

      if (page === 1) {
        setData(response.data);
      } else {
        setData(oldData => ({
          ...oldData,
          last: {
            ...oldData.last,
            data: [...oldData.last.data, ...response.data.last.data],
          },
        }));
      }
    } catch (ex) {
      // Handle exception
    } finally {
      setFetching(false);
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    const startDate = moment().subtract(1, 'months').format('YYYY-MM-DD');
    queryParams.set('startDate', startDate);
  }, []);

  return (
    <Card
      title="Solicitações de pré carga"
      isLoading={isFetching}
      fetchFn={fetchAvailableLoads}
      onFiltersChanged={fetchAvailableLoads}
      dashboard={dashboard}
      name={name}
      id={id}
      excludeFilterByClients
      excludeFilterByTags
      excludeFilterByCompany
      ListComponent={
        <>
          <Table striped>
            <thead>
              <tr>
                <th>
                  <Text weight="500" color="dark">
                    Nº pré carga
                  </Text>
                </th>
                <th>
                  <Text weight="500" color="dark">
                    Cliente
                  </Text>
                </th>
                <th>
                  <Text weight="500" color="dark">
                    Usuário Solicitação
                  </Text>
                </th>
                <th>
                  <Text weight="500" color="dark">
                    Data Solicitação
                  </Text>
                </th>
                <th>
                  <Text weight="500" color="dark">
                    Origem
                  </Text>
                </th>
                <th>
                  <Text weight="500" color="dark">
                    Destinos
                  </Text>
                </th>
                <th>
                  <Text weight="500" color="dark">
                    Centro de Custo
                  </Text>
                </th>
                <th>
                  <Text weight="500" color="dark">
                    Filial
                  </Text>
                </th>
                <th
                  style={{
                    textAlign: 'center',
                  }}
                >
                  <Text weight="500" color="dark">
                    Ação
                  </Text>
                </th>
              </tr>
            </thead>
            <tbody>
              {data.last?.data?.map(load => {
                const clientTitle = `Cliente ${load.social_name} - ${load.cgccpf}`;
                const userTitle = `Usuário ${load?.userName} - ${load?.userEmail}`;

                return (
                  <tr>
                    <td>
                      <Link to={`/pre-cargas/${load.loadId}`} target="_blank">
                        {load.loadId}
                      </Link>
                    </td>
                    <td
                      style={{
                        verticalAlign: 'middle',
                      }}
                    >
                      <Text type="regular" color="gray" title={clientTitle}>
                        {`${load?.social_name} - ${load?.cgccpf}`}
                      </Text>
                    </td>
                    <td
                      style={{
                        verticalAlign: 'middle',
                      }}
                    >
                      <Text type="regular" color="gray" title={userTitle}>
                        {load?.userName}
                      </Text>
                    </td>
                    <td
                      style={{
                        verticalAlign: 'middle',
                      }}
                    >
                      <Text type="regular" color="gray">
                        {formatDateTime(load.created_at)}
                      </Text>
                    </td>
                    <td
                      style={{
                        verticalAlign: 'middle',
                      }}
                    >
                      <Text type="regular" color="gray">
                        {`${load?.city_origin} - ${load?.uf_origin}`}
                      </Text>
                    </td>
                    <td
                      style={{
                        verticalAlign: 'middle',
                      }}
                    >
                      <Text type="regular" color="gray" truncate>
                        {load?.loadDestinations}
                      </Text>
                    </td>
                    <td
                      style={{
                        verticalAlign: 'middle',
                      }}
                    >
                      <Text type="regular" color="gray" truncate>
                        {load?.cost_center_name}
                      </Text>
                    </td>
                    <td
                      style={{
                        verticalAlign: 'middle',
                      }}
                    >
                      <Text type="regular" color="gray" truncate>
                        {`${load?.branch_office_id} - ${load?.branch_office_name}`}
                      </Text>
                    </td>
                    <td
                      style={{
                        verticalAlign: 'middle',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'flex-start',
                      }}
                    >
                      <Link
                        to={{
                          pathname: hasPermissionToAttend ? `/cargas/${load?.loadId}/atender`:'',
                        }}
                      >
                        <Button
                          color="dark"
                          variant="primary"
                          onClick={() => {}}
                          title={hasPermissionToAttend ? "Criar carga":'Você não tem permissão para criar carga'}
                          size="sm"
                          disabled={!hasPermissionToAttend}
                        >
                          Completar Carga
                        </Button>
                      </Link>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          {pagination?.lastPage > 1 && pagination?.to < pagination?.total && (
            <div className="text-center mt-3 col-12">
              <Button
                className="mt-2 ml-auto align-item-center"
                onClick={() => {
                  fetchAvailableLoads({
                    page: currentPage + 1,
                    start_date: filter?.start_date,
                    end_date: filter?.end_date,
                    costCenters: filter?.costCenters,
                    branches: filter?.branches,
                    pcps: filter?.pcps,
                    negotiators: filter?.negotiators,
                  });
                }}
                loading={loading}
              >
                <Text type="regular" color="white" truncate>
                  Ver mais
                </Text>
              </Button>
            </div>
          )}

          {!!data?.total && (
            <SeeAll
              to={{ pathname: '/pre-cargas', search: queryParams.toString() }}
            >
              ver todas as {data?.total} pré cargas
            </SeeAll>
          )}
        </>
      }
      NumericComponent={
        <div className="d-flex justify-content-center text-center flex-column">
          <Number color="error">{data?.total}</Number>
          <Description>
          {daysFromNow > 1 && daysFromNow < 61
                ? ` nos últimos ${daysFromNow} dias`
                : ` nos últimos ${filter.start_date} dias`}
          </Description>
        </div>
      }
    />
  );
}
