import { BlockedTravelContext } from 'contexts/BlockedTravelProvider';
import React, { useContext, useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';
import { FaDollarSign, FaQuestionCircle } from 'react-icons/fa';
import { percentage } from 'utils/number';
import { Card, Text, Tooltip } from 'v3/components';
import {
  CardHeaderComponent,
  CardSummaryComponent,
  ExtraItemDetailsRow,
  FreightItemRow,
  HeaderTotalSummary,
  Observations,
  SummaryRow,
  ValueDetailsRow,
} from 'v3/pages/Load/Register/PriceData/components';
import {
  ExtraFieldsContainer,
  StyledActionButton,
  StyledToggleCurrency,
} from 'v3/pages/Load/Register/PriceData/styles';
import { clientLoadFieldsToSum } from 'v3/pages/Load/Register/PriceData/utils';
import theme from 'v3/theme';
import { formatCurrency, makeConversion } from 'v3/utils/formatter';
import { getPercentageFromValue } from 'v3/utils/functions';

export function PreFreightValues({ load }) {
  return (
    <Row className="my-2">
      <Col md={2} xs={12}>
        <Text type="label" color="#464E5F">
          Moeda
        </Text>
        <Text
          id="label-moeda"
          type="regular"
          color="gray"
          as="p"
          className="mt-1"
        >
          {formatCurrency(load?.freightExchange, 'USD', 'R$')}
        </Text>
      </Col>
      <Col md={2} xs={12}>
        <Text type="label" color="#464E5F">
          Moeda do dia
        </Text>
        <Text
          id="label-moeda-dia"
          type="regular"
          color="gray"
          as="p"
          className="mt-1"
        >
          {formatCurrency(load?.exchangeDay, 'USD', 'R$')}
        </Text>
      </Col>

      <Col xs={12} md={2}>
        <Text type="label" color="#464E5F">
          Câmbio
        </Text>
        <Text
          id="label-cambio"
          type="regular"
          color="gray"
          as="p"
          className="mt-1"
        >
          {load?.currency === 'BRL' ? 'Real' : 'Dólar'}
        </Text>
      </Col>
      <Col sm={12} md={3}>
        <Text type="label" color="#464E5F">
          Tipo valor carga
        </Text>
        <Text
          id="label-calculo-valor-carga"
          type="regular"
          color="gray"
          as="p"
          className="mt-1"
        >
          {load?.loadValueType || 'Não informado'}
        </Text>
      </Col>
      <Col sm={12} md={3}>
        <Text type="label" color="#464E5F">
          Negociação
        </Text>
        <Text
          id="label-negociacao"
          type="regular"
          color="gray"
          as="p"
          className="mt-1"
        >
          {load?.negotiationType || 'Não informado'}
        </Text>
      </Col>
    </Row>
  );
}

export function ReceiveClientCardDetails({
  load,
  isBRLCurrency,
  dollarToUse,
  showDailyRate,
  setShowDailyRate,
  clientCardToggle,
  setClientCardToggle,
}) {
  const hasInformedGriss = load?.gris > 0;
  const grissPercentage =
    load?.grisPercentage ??
    getPercentageFromValue(load?.gris, load?.cargoValue);
  const hasInformedAd = load?.adValorem > 0;
  const adPercentage =
    load?.adValoremPercentage ??
    getPercentageFromValue(load?.adValorem, load?.cargoValue);
  const hasInformedCharge = load?.chargeValue > 0;
  const hasInformedFerry = load?.ferry > 0;
  const totalItemsWithoutIcms = load?.totalTakerValue - load?.icms;
  const hasInformedICMS = load?.icms > 0;
  const icmsPercentage =
    load?.icmsPercentage ??
    Number(
      100 *
        (1 -
          (
            totalItemsWithoutIcms /
            (totalItemsWithoutIcms + Number(load?.icms))
          )?.toFixed(2))
    )?.toFixed(2);
  const hasClientExtraFields = load?.extraFields?.length > 0;
  const needToRecalculateTotal = !!load && !load?.totalTakerValue;
  let newTotal = 0;
  function recalculate(load) {
    let acc = 0;
    clientLoadFieldsToSum.forEach(field => {
      acc += Number(load[field]);
    });
    return acc;
  }

  if (needToRecalculateTotal) {
    newTotal = recalculate(load);
  }
  const receiveClientTags = useMemo(() => {
    let tags = '';
    if (Number(load?.takerValue) > 0) {
      tags += 'Frete base,';
    }
    if (
      Number(load?.tollValue) > 0 &&
      load?.tollObservation?.code !== 'client_provides_card'
    ) {
      tags += 'Pedágio,';
    }
    if (Number(load?.dischargeValue) > 0) {
      tags += 'Descarga,';
    }
    if (Number(load?.chargeValue) > 0) {
      tags += 'Taxa de Carregamento,';
    }
    if (Number(load?.gris) > 0) {
      tags += 'Gris,';
    }
    if (Number(load?.adValorem) > 0) {
      tags += 'Ad Valorem,';
    }
    if (Number(load?.ferry) > 0) {
      tags += 'Balsa,';
    }
    if (Number(load?.icms) > 0) {
      tags += 'ICMS,';
    }
    if (load?.extraFields?.length > 0) {
      tags += 'Valores extras';
    }
    return tags;
  }, [
    load?.takerValue,
    load?.tollValue,
    load?.dischargeValue,
    load?.gris,
    load?.adValorem,
    load?.chargeValue,
    load?.ferry,
    load?.icms,
    load?.extraFields,
  ]);
  function convertValue(rawValue) {
    const operation = isBRLCurrency ? '/' : '*';
    const processedValue = +makeConversion(rawValue, operation, dollarToUse);
    return operation === '/'
      ? processedValue.toFixed(4)
      : processedValue.toFixed(2);
  }

  return (
    <Col xs={12}>
      <Card containerCardBoxShadow="0px 0px 10px rgba(0, 0, 0, 0.1)">
        <CardHeaderComponent
          label="Receber Cliente Tomador"
          isOpen={clientCardToggle}
          setIsOpen={() => setClientCardToggle(!clientCardToggle)}
          rightSection={
            <StyledToggleCurrency>
              <StyledActionButton
                onClick={() => setShowDailyRate(!showDailyRate)}
                title={showDailyRate ? 'Cotação do dia' : 'Cotação informada'}
              >
                <FaDollarSign
                  title={showDailyRate ? 'Cotação do dia' : 'Cotação informada'}
                />
              </StyledActionButton>
              <Tooltip
                content={
                  <Text type="label" color="white">
                    Alterna entre valores da cotação do dólar no dia e a cotação
                    informada no campo Moeda.
                  </Text>
                }
              >
                <FaQuestionCircle style={{ color: theme.colors.light_blue }} />
              </Tooltip>
            </StyledToggleCurrency>
          }
          tooltipContent="Todo o valor que é recebido pelo cliente deve ser destacado separadamente. Após preencher todos os campos irá chegar no Valor Cliente Tomador automaticamente "
          totalsSection={
            <HeaderTotalSummary
              label="Valor Cliente Tomador"
              totalInReal={
                isBRLCurrency
                  ? load?.totalTakerValue
                  : convertValue(load?.totalTakerValue)
              }
              totalInDollar={
                !isBRLCurrency
                  ? load?.totalTakerValue
                  : convertValue(load?.totalTakerValue)
              }
            />
          }
          hasTagsRow
          tagsContent={receiveClientTags}
        />
        {clientCardToggle && (
          <>
            <ValueDetailsRow
              label="Valor Frete Base"
              value={load?.takerValue}
              exchangeToUse={dollarToUse}
              isBRLCurrency={isBRLCurrency}
            />
            <ValueDetailsRow
              label="Pedágio"
              value={load?.tollValue}
              exchangeToUse={dollarToUse}
              obs={load?.tollObservation?.name}
              isBRLCurrency={isBRLCurrency}
            />
            <ValueDetailsRow
              label="Descarga"
              value={load?.dischargeValue}
              exchangeToUse={dollarToUse}
              obs={load?.dischargeObservation?.name}
              isBRLCurrency={isBRLCurrency}
            />
            {hasInformedGriss && (
              <ValueDetailsRow
                label="Griss"
                exchangeToUse={dollarToUse}
                value={load?.gris}
                percentageValue={grissPercentage}
                obs={load?.grissAdObservation?.name}
                isBRLCurrency={isBRLCurrency}
              />
            )}
            {hasInformedAd && (
              <ValueDetailsRow
                label="Ad valorem"
                exchangeToUse={dollarToUse}
                value={load?.adValorem}
                percentageValue={adPercentage}
                obs={load?.grissAdObservation?.name}
                isBRLCurrency={isBRLCurrency}
              />
            )}
            {hasInformedCharge && (
              <ValueDetailsRow
                label="Taxa de carregamento"
                exchangeToUse={dollarToUse}
                value={load?.chargeValue}
                obs={load?.chargeObservation?.name}
                isBRLCurrency={isBRLCurrency}
              />
            )}
            {hasInformedFerry && (
              <ValueDetailsRow
                label="Balsa"
                exchangeToUse={dollarToUse}
                value={load?.ferry}
                obs={load?.ferryObservation?.name}
                isBRLCurrency={isBRLCurrency}
              />
            )}
            {hasInformedICMS && (
              <ValueDetailsRow
                label="ICMS"
                exchangeToUse={dollarToUse}
                value={load?.icms}
                percentageValue={icmsPercentage}
                obs={load?.icmsObservation?.name}
                isBRLCurrency={isBRLCurrency}
              />
            )}
            <>
              {load?.extraFields?.length > 0 ? (
                <Text type="regular" weight={500}>
                  Valores extras
                </Text>
              ) : null}
              <ExtraFieldsContainer>
                {hasClientExtraFields &&
                  load?.extraFields?.map(field => {
                    return (
                      <ExtraItemDetailsRow
                        key={field.id}
                        exchangeToUse={dollarToUse}
                        label={field.label}
                        value={field.value}
                        showToDriver={field.show_to_drivers}
                        isBRLCurrency={isBRLCurrency}
                      />
                    );
                  })}
              </ExtraFieldsContainer>
            </>

            <SummaryRow
              label="Valor Cliente Tomador"
              totalInBRL={
                isBRLCurrency
                  ? load?.totalTakerValue || newTotal
                  : convertValue(load?.totalTakerValue || newTotal)
              }
              totalInUSD={
                !isBRLCurrency
                  ? load?.totalTakerValue || newTotal
                  : convertValue(load?.totalTakerValue || newTotal)
              }
            />
          </>
        )}
      </Card>
    </Col>
  );
}

export function InputsCardDetails({
  load,
  extraFieldsFieldName,
  totalComputed,
  cardTitle,
  cardTotalTitle,
  tooltipContent,
  shouldShowConditions = [],
  hasCooperInsurance = false,
  cooperInsurance = {},
  hasIssueRate = false,
  issueRate = {},
  hasObservationFields = false,
  hasNegotiatorObs = false,
  negotiatorObs = '',
  dollarToUse = 0,
  cardToggle = false,
  setCardToggle = () => {},
  isBRLCurrency = true,
  shouldDisableGrisField = false,
  currentShippingLevel = false,
}) {
  const hasInformedTollAndShouldShow =
    load?.tollValue > 0 &&
    shouldShowConditions.includes(load?.tollObservation?.code);
  const hasInformedDischargeAndShouldShow =
    load?.dischargeValue > 0 &&
    shouldShowConditions.includes(load?.dischargeObservation?.code);
  const hasInformedGrissAndShouldShow =
    load?.gris > 0 &&
    shouldShowConditions.includes(load?.grissAdObservation?.code) &&
    shouldDisableGrisField === false;
  const grissPercentage = getPercentageFromValue(load?.gris, load?.cargoValue);
  const hasInformedAdAndShouldShow =
    load?.adValorem > 0 &&
    shouldShowConditions.includes(load?.grissAdObservation?.code);
  const adPercentage = getPercentageFromValue(
    load?.ad_valorem,
    load?.cargo_value,
    3
  );
  const hasInformedChargeAndShouldShow =
    load?.chargeValue > 0 &&
    shouldShowConditions.includes(load?.chargeObservation?.code);
  const hasInformedFerryAndShouldShow =
    load?.ferry > 0 &&
    shouldShowConditions.includes(load?.ferryObservation?.code);
  const hasInformedICMSAndShouldShow =
    load?.icms > 0 &&
    shouldShowConditions.includes(load?.icmsObservation?.code);
  const totalItemsWithoutIcms = load?.totalTakerValue - load?.icms;
  const icmsPercentage =
    load?.icmsPercentage ??
    Number(
      100 *
        (1 -
          (
            totalItemsWithoutIcms /
            (totalItemsWithoutIcms + Number(load?.icms))
          )?.toFixed(2))
    )?.toFixed(2);
  const hasExtraFields = load && load[extraFieldsFieldName]?.length > 0;
  const cooperInsurancePercentage =
    hasCooperInsurance &&
    getPercentageFromValue(cooperInsurance.value, load?.cargoValue);

  function convertValue(rawValue) {
    const operation = isBRLCurrency ? '/' : '*';
    const processedValue = +makeConversion(rawValue, operation, dollarToUse);
    return operation === '/'
      ? processedValue.toFixed(4)
      : processedValue.toFixed(2);
  }
  const tagsContent = useMemo(() => {
    let tags = '';
    if (
      hasInformedTollAndShouldShow &&
      load?.tollObservation?.code !== 'client_provides_card'
    ) {
      tags += 'Pedágio,';
    }
    if (hasInformedDischargeAndShouldShow) {
      tags += 'Descarga,';
    }
    if (hasInformedChargeAndShouldShow) {
      tags += 'Taxa de Carregamento,';
    }
    if (hasInformedGrissAndShouldShow) {
      tags += 'Gris,';
    }
    if (hasInformedAdAndShouldShow) {
      tags += 'Ad Valorem,';
    }
    if (hasInformedFerryAndShouldShow) {
      tags += 'Balsa,';
    }
    if (hasInformedICMSAndShouldShow) {
      tags += 'ICMS,';
    }
    if (hasCooperInsurance) {
      tags += 'Seguro Coopercarga,';
    }
    if (hasIssueRate) {
      tags += 'Taxa de Emissão,';
    }
    if (
      !!load &&
      load[extraFieldsFieldName]?.filter(
        item =>
          item?.label?.toLowerCase() !== 'taxa de emissão' &&
          item?.label?.toLowerCase() !== 'seguro coopercarga'
      )?.length > 0
    ) {
      tags += 'Valores extras,';
    }
    return tags;
  }, [
    load,
    hasInformedTollAndShouldShow,
    hasInformedDischargeAndShouldShow,
    hasInformedChargeAndShouldShow,
    hasInformedGrissAndShouldShow,
    hasInformedAdAndShouldShow,
    hasInformedFerryAndShouldShow,
    hasInformedICMSAndShouldShow,
    hasCooperInsurance,
    hasIssueRate,
  ]);
  const wasAttendedByGoldenShippingCompany =
    currentShippingLevel &&
    load?.travel?.shipper?.shippingCompany?.descriptionServiceLevel?.toLowerCase() ===
      'agregado ouro';

  return (
    <Col xs={12}>
      <Card containerCardBoxShadow="0px 0px 10px rgba(0, 0, 0, 0.1)">
        <CardHeaderComponent
          label={cardTitle}
          isOpen={cardToggle}
          setIsOpen={() => setCardToggle(!cardToggle)}
          tooltipContent={tooltipContent}
          totalsSection={
            <HeaderTotalSummary
              label={cardTotalTitle}
              totalInReal={
                isBRLCurrency ? totalComputed : convertValue(totalComputed)
              }
              totalInDollar={
                !isBRLCurrency ? totalComputed : convertValue(totalComputed)
              }
            />
          }
          hasTagsRow
          tagsContent={tagsContent}
        />
        {cardToggle && (
          <>
            {hasCooperInsurance && (
              <ValueDetailsRow
                label={cooperInsurance?.label}
                value={cooperInsurance?.value}
                percentageValue={cooperInsurancePercentage}
                exchangeToUse={dollarToUse}
                hasTooltip
                tooltipContent="Valor aproximado, pode variar de acordo com o valor do produto na Nota Fiscal"
                isBRLCurrency={isBRLCurrency}
              />
            )}
            {hasIssueRate && (
              <ValueDetailsRow
                label={issueRate?.label}
                value={issueRate?.value}
                exchangeToUse={dollarToUse}
                hasTooltip
                tooltipContent="Valor da taxa para emissão dos documentos, é preenchido automaticamente conforme nível de associado e pode ser editado pelo usuário."
                isBRLCurrency={isBRLCurrency}
              />
            )}
            {hasInformedTollAndShouldShow && (
              <ValueDetailsRow
                label="Pedágio"
                value={load?.tollValue}
                exchangeToUse={dollarToUse}
                obs={load?.tollObservation?.name}
                isBRLCurrency={isBRLCurrency}
              />
            )}
            {hasInformedDischargeAndShouldShow && (
              <ValueDetailsRow
                label="Descarga"
                value={load?.dischargeValue}
                exchangeToUse={dollarToUse}
                obs={load?.dischargeObservation?.name}
                isBRLCurrency={isBRLCurrency}
              />
            )}
            {hasInformedGrissAndShouldShow && (
              <ValueDetailsRow
                label="Griss"
                exchangeToUse={dollarToUse}
                value={load?.gris}
                percentageValue={grissPercentage}
                obs={load?.grissAdObservation.name}
                hasTooltip
                tooltipContent="Valor aproximado, pode variar de acordo com o valor do produto na Nota Fiscal"
                isBRLCurrency={isBRLCurrency}
              />
            )}
            {hasInformedAdAndShouldShow &&
            wasAttendedByGoldenShippingCompany === false ? (
              <ValueDetailsRow
                label="Ad valorem"
                exchangeToUse={dollarToUse}
                value={load?.adValorem}
                percentageValue={adPercentage}
                obs={load?.grissAdObservation.name}
                isBRLCurrency={isBRLCurrency}
              />
            ) : hasInformedAdAndShouldShow &&
              wasAttendedByGoldenShippingCompany === true ? (
              <>
                <ValueDetailsRow
                  label="Ad valorem"
                  exchangeToUse={dollarToUse}
                  value={load?.adValorem}
                  percentageValue={adPercentage}
                  obs={load?.grissAdObservation.name}
                  isBRLCurrency={isBRLCurrency}
                />
                <ValueDetailsRow
                  label="Ad valorem"
                  exchangeToUse={dollarToUse}
                  value={load?.adValorem}
                  percentageValue={adPercentage}
                  obs={load?.grissAdObservation.name}
                  isBRLCurrency={isBRLCurrency}
                  wasValueRemovedFromTotal={wasAttendedByGoldenShippingCompany}
                />
              </>
            ) : null}
            {hasInformedChargeAndShouldShow && (
              <ValueDetailsRow
                label="Taxa de carregamento"
                exchangeToUse={dollarToUse}
                value={load?.chargeValue}
                obs={load?.chargeObservation.name}
                isBRLCurrency={isBRLCurrency}
              />
            )}
            {hasInformedFerryAndShouldShow && (
              <ValueDetailsRow
                label="Balsa"
                exchangeToUse={dollarToUse}
                value={load?.ferry}
                obs={load?.ferryObservation.name}
                isBRLCurrency={isBRLCurrency}
              />
            )}
            {hasInformedICMSAndShouldShow && (
              <ValueDetailsRow
                label="ICMS"
                exchangeToUse={dollarToUse}
                value={load?.icms}
                percentageValue={icmsPercentage}
                obs={load?.icmsObservation.name}
                isBRLCurrency={isBRLCurrency}
              />
            )}
            <>
              {load[extraFieldsFieldName]?.filter(
                item =>
                  item?.label?.toLowerCase() !== 'taxa de emissão' &&
                  item?.label?.toLowerCase() !== 'seguro coopercarga'
              )?.length > 0 ? (
                <Text type="medium" weight={500}>
                  Valores extras
                </Text>
              ) : null}
              <ExtraFieldsContainer>
                {hasExtraFields &&
                  load[extraFieldsFieldName]?.map(field => {
                    if (
                      field.label.toLowerCase() !== 'seguro coopercarga' &&
                      field.label.toLowerCase() !== 'taxa de emissão'
                    )
                      return (
                        <ExtraItemDetailsRow
                          key={field.id}
                          exchangeToUse={dollarToUse}
                          label={field.label}
                          value={field.value}
                          showToDriver={field.showToDriver}
                          isBRLCurrency={isBRLCurrency}
                        />
                      );
                  })}
              </ExtraFieldsContainer>
            </>

            <SummaryRow
              label={cardTotalTitle}
              totalInBRL={
                isBRLCurrency ? totalComputed : convertValue(totalComputed)
              }
              totalInUSD={
                !isBRLCurrency ? totalComputed : convertValue(totalComputed)
              }
            />
            {hasObservationFields && (
              <Observations
                hasNegotiatorObs={hasNegotiatorObs}
                load={load}
                negotiatorObs={negotiatorObs}
              />
            )}
          </>
        )}
      </Card>
    </Col>
  );
}

export function CardDetails({
  load,
  extraFieldsFieldName,
  totalComputed,
  cardTitle,
  cardTotalTitle,
  tooltipContent,
  shouldShowConditions = [],
  hasCooperInsurance = false,
  cooperInsurance = {},
  hasIssueRate = false,
  issueRate = {},
  hasObservationFields = false,
  hasNegotiatorObs = false,
  negotiatorObs = '',
  dollarToUse = 0,
  cardToggle = false,
  setCardToggle = () => {},
  isBRLCurrency = true,
  shouldDisableGrisField = false,
}) {
  const hasInformedTollAndShouldShow =
    load?.tollValue > 0 &&
    shouldShowConditions.includes(load?.tollObservation?.code);
  const hasInformedDischargeAndShouldShow =
    load?.dischargeValue > 0 &&
    shouldShowConditions.includes(load?.dischargeObservation?.code);
  const hasInformedGrissAndShouldShow =
    load?.gris > 0 &&
    shouldShowConditions.includes(load?.grissAdObservation?.code) &&
    shouldDisableGrisField === false;
  const grissPercentage = getPercentageFromValue(load?.gris, load?.cargoValue);
  const hasInformedAdAndShouldShow =
    load?.adValorem > 0 &&
    shouldShowConditions.includes(load?.grissAdObservation?.code);
  const adPercentage = getPercentageFromValue(
    load?.ad_valorem,
    load?.cargo_value,
    3
  );
  const hasInformedChargeAndShouldShow =
    load?.chargeValue > 0 &&
    shouldShowConditions.includes(load?.chargeObservation?.code);
  const hasInformedFerryAndShouldShow =
    load?.ferry > 0 &&
    shouldShowConditions.includes(load?.ferryObservation?.code);
  const hasInformedICMSAndShouldShow =
    load?.icms > 0 &&
    shouldShowConditions.includes(load?.icmsObservation?.code);
  const icmsPercentage = getPercentageFromValue(load?.icms, load?.takerValue);
  const hasExtraFields = load && load[extraFieldsFieldName]?.length > 0;
  const cooperInsurancePercentage =
    hasCooperInsurance &&
    getPercentageFromValue(cooperInsurance.value, load?.cargoValue);

  function convertValue(rawValue) {
    const operation = isBRLCurrency ? '/' : '*';
    const processedValue = +makeConversion(rawValue, operation, dollarToUse);
    return operation === '/'
      ? processedValue.toFixed(4)
      : processedValue.toFixed(2);
  }
  const tagsContent = useMemo(() => {
    let tags = '';
    if (
      hasInformedTollAndShouldShow &&
      load?.tollObservation?.code !== 'client_provides_card'
    ) {
      tags += 'Pedágio,';
    }
    if (hasInformedDischargeAndShouldShow) {
      tags += 'Descarga,';
    }
    if (hasInformedChargeAndShouldShow) {
      tags += 'Taxa de Carregamento,';
    }
    if (hasInformedGrissAndShouldShow) {
      tags += 'Gris,';
    }
    if (hasInformedAdAndShouldShow) {
      tags += 'Ad Valorem,';
    }
    if (hasInformedFerryAndShouldShow) {
      tags += 'Balsa,';
    }
    if (hasInformedICMSAndShouldShow) {
      tags += 'ICMS,';
    }
    if (hasCooperInsurance) {
      tags += 'Seguro Coopercarga,';
    }
    if (hasIssueRate) {
      tags += 'Taxa de Emissão,';
    }
    if (
      !!load &&
      load[extraFieldsFieldName]?.filter(
        item =>
          item?.label?.toLowerCase() !== 'taxa de emissão' &&
          item?.label?.toLowerCase() !== 'seguro coopercarga'
      )?.length > 0
    ) {
      tags += 'Valores extras,';
    }
    return tags;
  }, [
    load,
    hasInformedTollAndShouldShow,
    hasInformedDischargeAndShouldShow,
    hasInformedChargeAndShouldShow,
    hasInformedGrissAndShouldShow,
    hasInformedAdAndShouldShow,
    hasInformedFerryAndShouldShow,
    hasInformedICMSAndShouldShow,
    hasCooperInsurance,
    hasIssueRate,
  ]);

  return (
    <Col xs={12}>
      <Card containerCardBoxShadow="0px 0px 10px rgba(0, 0, 0, 0.1)">
        <CardHeaderComponent
          label={cardTitle}
          isOpen={cardToggle}
          setIsOpen={() => setCardToggle(!cardToggle)}
          tooltipContent={tooltipContent}
          totalsSection={
            <HeaderTotalSummary
              label={cardTotalTitle}
              totalInReal={
                isBRLCurrency ? totalComputed : convertValue(totalComputed)
              }
              totalInDollar={
                !isBRLCurrency ? totalComputed : convertValue(totalComputed)
              }
            />
          }
          hasTagsRow
          tagsContent={tagsContent}
        />
        {cardToggle && (
          <>
            {hasCooperInsurance && (
              <ValueDetailsRow
                label={cooperInsurance?.label}
                value={cooperInsurance?.value}
                percentageValue={cooperInsurancePercentage}
                exchangeToUse={dollarToUse}
                hasTooltip
                tooltipContent="Valor aproximado, pode variar de acordo com o valor do produto na Nota Fiscal"
                isBRLCurrency={isBRLCurrency}
              />
            )}
            {hasIssueRate && (
              <ValueDetailsRow
                label={issueRate?.label}
                value={issueRate?.value}
                exchangeToUse={dollarToUse}
                hasTooltip
                tooltipContent="Valor da taxa para emissão dos documentos, é preenchido automaticamente conforme nível de associado e pode ser editado pelo usuário."
                isBRLCurrency={isBRLCurrency}
              />
            )}
            {hasInformedTollAndShouldShow && (
              <ValueDetailsRow
                label="Pedágio"
                value={load?.tollValue}
                exchangeToUse={dollarToUse}
                obs={load?.tollObservation?.name}
                isBRLCurrency={isBRLCurrency}
              />
            )}
            {hasInformedDischargeAndShouldShow && (
              <ValueDetailsRow
                label="Descarga"
                value={load?.dischargeValue}
                exchangeToUse={dollarToUse}
                obs={load?.dischargeObservation?.name}
                isBRLCurrency={isBRLCurrency}
              />
            )}
            {hasInformedGrissAndShouldShow && (
              <ValueDetailsRow
                label="Griss"
                exchangeToUse={dollarToUse}
                value={load?.gris}
                percentageValue={grissPercentage}
                obs={load?.grissAdObservation.name}
                hasTooltip
                tooltipContent="Valor aproximado, pode variar de acordo com o valor do produto na Nota Fiscal"
                isBRLCurrency={isBRLCurrency}
              />
            )}
            {hasInformedAdAndShouldShow && (
              <ValueDetailsRow
                label="Ad valorem"
                exchangeToUse={dollarToUse}
                value={load?.adValorem}
                percentageValue={adPercentage}
                obs={load?.grissAdObservation.name}
                isBRLCurrency={isBRLCurrency}
              />
            )}
            {hasInformedChargeAndShouldShow && (
              <ValueDetailsRow
                label="Taxa de carregamento"
                exchangeToUse={dollarToUse}
                value={load?.chargeValue}
                obs={load?.chargeObservation.name}
                isBRLCurrency={isBRLCurrency}
              />
            )}
            {hasInformedFerryAndShouldShow && (
              <ValueDetailsRow
                label="Balsa"
                exchangeToUse={dollarToUse}
                value={load?.ferry}
                obs={load?.ferryObservation.name}
                isBRLCurrency={isBRLCurrency}
              />
            )}
            {hasInformedICMSAndShouldShow && (
              <ValueDetailsRow
                label="ICMS"
                exchangeToUse={dollarToUse}
                value={load?.icms}
                percentageValue={icmsPercentage}
                obs={load?.icmsObservation.name}
                isBRLCurrency={isBRLCurrency}
              />
            )}
            <>
              {load[extraFieldsFieldName]?.filter(
                item =>
                  item?.label?.toLowerCase() !== 'taxa de emissão' &&
                  item?.label?.toLowerCase() !== 'seguro coopercarga'
              )?.length > 0 ? (
                <Text type="medium" weight={500}>
                  Valores extras
                </Text>
              ) : null}
              <ExtraFieldsContainer>
                {hasExtraFields &&
                  load[extraFieldsFieldName]?.map(field => {
                    if (
                      field.label.toLowerCase() !== 'seguro coopercarga' &&
                      field.label.toLowerCase() !== 'taxa de emissão'
                    )
                      return (
                        <ExtraItemDetailsRow
                          key={field.id}
                          exchangeToUse={dollarToUse}
                          label={field.label}
                          value={field.value}
                          showToDriver={field.showToDriver}
                          isBRLCurrency={isBRLCurrency}
                        />
                      );
                  })}
              </ExtraFieldsContainer>
            </>

            <SummaryRow
              label={cardTotalTitle}
              totalInBRL={
                isBRLCurrency ? totalComputed : convertValue(totalComputed)
              }
              totalInUSD={
                !isBRLCurrency ? totalComputed : convertValue(totalComputed)
              }
            />
            {hasObservationFields && (
              <Observations
                hasNegotiatorObs={hasNegotiatorObs}
                load={load}
                negotiatorObs={negotiatorObs}
              />
            )}
          </>
        )}
      </Card>
    </Col>
  );
}

export function NegotiatedCard({
  cardTitle = '',
  cardToggle = '',
  setCardToggle = () => {},
  tooltipContent = '',
  load = {},
  isBRLCurrency = true,
  dollarToUse = 0,
  fromTravel = false,
  balanceValue = null,
  advanceMoneyPercentage = null,
  onChangeAdvancePercentage = () => {},
  advanceMoneyValue = null,
  onChangeAdvanceValue = () => {},
  marginValue = null,
  isEditing = false,
  onChangeBalance = () => {},
}) {
  const { margins } = useContext(BlockedTravelContext);
  const isAttended = fromTravel ? true : load?.travel;

  function convertValue(rawValue) {
    const operation = isBRLCurrency ? '/' : '*';
    const processedValue = +makeConversion(rawValue, operation, dollarToUse);
    return operation === '/'
      ? processedValue.toFixed(4)
      : processedValue.toFixed(2);
  }
  return (
    <Col xs={12}>
      <Card containerCardBoxShadow="0px 0px 10px rgba(0, 0, 0, 0.1)">
        <CardHeaderComponent
          label={cardTitle}
          isOpen={cardToggle}
          setIsOpen={() => setCardToggle(!cardToggle)}
          tooltipContent={tooltipContent}
          totalsSection={
            <>
              <HeaderTotalSummary
                label="Frete tirar margem"
                totalInReal={
                  isBRLCurrency
                    ? load?.fareCompany
                    : convertValue(load?.fareCompany)
                }
                totalInDollar={
                  !isBRLCurrency
                    ? load?.fareCompany
                    : convertValue(load?.fareCompany)
                }
              />
              {isAttended ? (
                <HeaderTotalSummary
                  label="Frete negociado"
                  totalInReal={
                    isBRLCurrency ? balanceValue : convertValue(balanceValue)
                  }
                  totalInDollar={
                    !isBRLCurrency ? balanceValue : convertValue(balanceValue)
                  }
                />
              ) : null}
            </>
          }
        />
        {cardToggle && (
          <>
            {isAttended && (
              <>
                <ValueDetailsRow
                  label="% máxima de adiantamento no ERP"
                  value={
                    percentage(
                      load?.advanceMoneyPercentageExternal,
                      balanceValue
                    ) || 0
                  }
                  exchangeToUse={dollarToUse}
                  percentageValue={load?.advanceMoneyPercentageExternal}
                  isBRLCurrency={isBRLCurrency}
                />
              </>
            )}
            {isEditing && isAttended ? (
              <FreightItemRow
                label="% adiantamento negociado"
                shouldDisableField={!balanceValue}
                percentageValueReference={balanceValue}
                onChangePercentageValue={onChangeAdvancePercentage}
                percentageValue={advanceMoneyPercentage}
                value={advanceMoneyValue}
                isBRLCurrency={isBRLCurrency}
                dollarToUse={dollarToUse}
                onChangeValue={onChangeAdvanceValue}
                advanceMarginError={
                  advanceMoneyPercentage > load?.advanceMoneyPercentageExternal
                }
                showCalculator
              />
            ) : isAttended ? (
              <ValueDetailsRow
                label="% adiantamento negociado"
                value={
                  percentage(load?.advanceMoneyPercentage, balanceValue) || 0
                }
                exchangeToUse={dollarToUse}
                percentageValue={load?.advanceMoneyPercentage}
                isBRLCurrency={isBRLCurrency}
              />
            ) : null}
            <ValueDetailsRow
              label="Frete Tirar Margem"
              value={load?.fareCompany}
              exchangeToUse={dollarToUse}
              hasTooltip
              tooltipContent="Base de cálculo para Frete Tirar Margem (Valor Cliente Tomador - Desconto Insumos)"
              isBRLCurrency={isBRLCurrency}
            />
            {isEditing && isAttended ? (
              <FreightItemRow
                label="Frete negociado"
                value={balanceValue || 0}
                isBRLCurrency={isBRLCurrency}
                dollarToUse={dollarToUse}
                onChangeValue={onChangeBalance}
                showCalculator
              />
            ) : (
              isAttended && (
                <ValueDetailsRow
                  label="Frete negociado"
                  value={balanceValue || 0}
                  exchangeToUse={dollarToUse}
                  isBRLCurrency={isBRLCurrency}
                />
              )
            )}
            {isAttended && (
              <>
                <ValueDetailsRow
                  label="Margem Aplicada"
                  value={Number(load?.fareCompany) - Number(balanceValue) || 0}
                  exchangeToUse={dollarToUse}
                  percentageValue={marginValue}
                  isBRLCurrency={isBRLCurrency}
                />
                <ValueDetailsRow
                  label="Margem ERP"
                  value={
                    percentage(
                      margins?.benner_margin ?? 0,
                      load?.fareCompany ?? 0
                    ) || 0
                  }
                  exchangeToUse={dollarToUse}
                  percentageValue={margins?.benner_margin || '0'}
                  isBRLCurrency={isBRLCurrency}
                />
              </>
            )}
          </>
        )}
      </Card>
    </Col>
  );
}

export function SummaryPayingTransporterCard({
  cardTitle = '',
  isBRLCurrency = true,
  dollarToUse = 0,
  balanceValue = null,
  totalShipmentTransfer = null,
  totalShipmentDiscounts = null,
  advanceMoneyPercentage = null
}) {

  const totalValueToPay = Number(balanceValue) + totalShipmentTransfer - totalShipmentDiscounts
  const totalBalance = totalValueToPay - percentage(advanceMoneyPercentage || 0, balanceValue || 0)

  return (
    <Col xs={12}>
      <Card containerCardBoxShadow="0px 0px 10px rgba(0, 0, 0, 0.1)">
        <CardSummaryComponent
          label={cardTitle}
        />
          <>
            <ValueDetailsRow
              label="Total a pagar"
              value={totalValueToPay || 0}
              exchangeToUse={dollarToUse}
              isBRLCurrency={isBRLCurrency}
            />
            <ValueDetailsRow
              label="% adiantamento"
              value={
                percentage(advanceMoneyPercentage || 0, balanceValue || 0) || 0
              }
              exchangeToUse={dollarToUse}
              percentageValue={advanceMoneyPercentage || 0}
              isBRLCurrency={isBRLCurrency}
            />
            <ValueDetailsRow
              weight={ 600 }
              color='success'
              label="Saldo"
              value={totalBalance || 0}
              exchangeToUse={dollarToUse}
              isBRLCurrency={isBRLCurrency}
            />
          </>
      </Card>
    </Col>
  );
}
