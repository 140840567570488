import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import Text from 'v3/components/Text';
import { useSnackbar } from 'v3/components/Snackbar';
import api from 'services/api';
import { formatDateTime } from 'v3/utils/formatter';
import { useUser } from '../shared/context';
import SharedData from '../shared/Section';
import Inputs from '../shared/Access';
import Editable from '../shared/Editable';
import { validate } from '../shared/validator';

export default function Access() {
  const user = useUser();
  const params = useParams();
  const snackbar = useSnackbar();
  const [isEditing, setEditing] = useState(false);
  const [loading, setLoading] = useState(false);

  async function saveData() {
    setLoading(true);
    const isValid = await validate(
      'access',
      { access: user.data },
      user.setErrors,
      { hasParams: params.id }
    );

    if (!isValid) {
      snackbar.show(<Text>Verifique os campos obrigatórios</Text>, {
        type: 'error',
      });
      setLoading(false);
      return;
    }

    if (user.data.profile.name !== 'Motorista') {
      if (!user?.data?.email) {
        snackbar.show(<Text>E-mail obrigatório!</Text>, { type: 'error' });
        setLoading(false);
        return;
      }
    }
    if (user.prevUser.email !== user.data.email) {
      if (user.data.verifyEmail !== user?.data?.email) {
        snackbar.show(<Text>O e-mail não confere!</Text>, { type: 'error' });
        setLoading(false);
        return;
      }
    }

    if (user.data.verifyPassword !== user?.data?.password) {
      snackbar.show(<Text>A senha não confere!</Text>, { type: 'error' });
      setLoading(false);
      return;
    }
    try {
      if (params.id) {
        const response = await api.put(`v3/user/${params.id}/access`, {
          data: {
            email:
              user.prevUser.email === user.data.email &&
              user.data.profile.name === 'Motorista'
                ? undefined
                : user.data.email || '',
            password: user.data.password || undefined,
            verified: user.data.verified,
            change_password: user?.data?.change_password,
          },
          sites: user?.data?.sites?.map(item => item?.id) || [],
        });
        user.setData({
          verifyPassword: '',
          password: '',
          date_verified: response.data.date_verified,
        });
      }
      user.updatePrevUser();
      snackbar.show(<Text>Salvo com sucesso</Text>, { type: 'success' });
      setEditing(false);
    } catch (error) {
      if (error.response) {
        const errorList = {};
        error.response.data.forEach(err => {
          errorList[err.field] = err?.message;
        });
        user.setErrors(errorList);
      }
      snackbar.show(<Text>Erro ao salvar</Text>, { type: 'error' });
    } finally {
      setLoading(false);
    }
  }

  return (
    <SharedData
      Title="Dados de acesso"
      HeaderRightComponent={
        <Editable
          isEditing={isEditing}
          setEditing={setEditing}
          onSave={saveData}
          isLoading={loading}
          disabled={!user.hasPermissionToEdit}
        />
      }
      loading={user.isFetching}
    >
      {isEditing ? (
        <Inputs />
      ) : (
        <Row>
          <Col xs={12}>
            <Text type="label" color="#464E5F">
              Email
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {user?.data?.email || '-'}
            </Text>
          </Col>
          <Col xs={12}>
            <Text type="label" color="#464E5F">
              Senha
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              ***********
            </Text>
          </Col>
          <Col xs={12}>
            <Text type="label" color="#464E5F">
              Verificado por SMS
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {user?.data?.verified ? (
                <>
                  <Text type="regular" color="gray" as="p" className="mt-2">
                    Sim
                  </Text>
                  <Text type="label" color="#464E5F">
                    Token enviado via SMS
                  </Text>
                  <Text type="regular" color="gray" as="p" className="mt-2">
                    {user?.data?.token}
                  </Text>
                  <Text type="label" color="#464E5F">
                    Verificado em
                  </Text>
                  <Text type="regular" color="gray" as="p" className="mt-2">
                    {formatDateTime(user?.data?.date_verified)}
                  </Text>
                </>
              ) : (
                <Text type="regular" color="gray" as="p" className="mt-2">
                  Não
                </Text>
              )}
            </Text>
            {user?.data?.profile?.name !== 'Motorista' && (
              <>
                <Text type="label" color="#464E5F">
                  Portais com acesso
                </Text>
                <Text type="regular" color="gray" as="p" className="mt-2">
                  {user.data?.sites?.length > 0
                    ? user.data.sites.map(site => site.name).join(', ')
                    : 'Não Informado'}
                </Text>
              </>
            )}
          </Col>
        </Row>
      )}
    </SharedData>
  );
}
