import React from 'react';
import { formatCurrency, formatDateTime } from 'v3/utils/formatter';
import { Link } from 'react-router-dom';

import { Row, Col } from 'react-bootstrap';

import Text from 'v3/components/Text';
import Card from 'v3/components/Card';

import * as Styled from './styles';

function TravelCard({ travel }) {
  return (
    <Col xs={12}>
      <Card>
        <Row>
          <Col xs={7} className="mb-3">
            <div className="mb-2">
              <Link to={`/viagens/${travel.attendance_id}`}>
                <Text weight={600} color="primary" type="subtitle">
                  Viagem: {travel?.attendance_id || ''} {' - '} {travel?.travel_number || ''}
                </Text>
              </Link>
            </div>
            <div style={{ wordBreak: 'break-all' }}>
              <Text weight={400} color="secundary" type="regular">
                {travel?.origin_city || ''} {travel?.origin_province || ''}
                <b>{' > '}</b>
                {travel?.destination_city || ''}{' '}
                {travel?.destination_province || ''}
              </Text>
              <br></br>
              <Text weight={400} color="secundary" type="regular">
                <b>Cliente:</b> {travel?.taker || ''} {travel?.taker_document || ''}
              </Text>
              <br></br>
              <Text weight={400} color="secundary" type="regular">
                <b>Motorista:</b> {travel?.driver || ''}
              </Text>
            </div>
          </Col>
          <Col xs={5} className="mb-3" style={{textAlign: 'end'}}>
            <Styled.Badge pill variant={travel?.travel_status} className="mr-2">
              {travel?.travel_status || ''}: {formatDateTime(travel?.status_date) || ''}
            </Styled.Badge>

          </Col>
          <Col xs={3}>
            <div className="mb-2">
              <Text type="regular" color="dark">
                Tempo Contratação: {travel.time_hiring || 'Não informado'}
              </Text>
            </div>
          </Col>
          <Col xs={3}>
            <div>
              <Text type="regular" color="dark">
                Tempo cadastro: {travel.time_register || 'Não informado'}
              </Text>
            </div>
          </Col>
          <Col xs={3}>
            <div>
              <Text type="regular" color="dark">
                Tempo GR: {travel.time_risk_manager || 'Não informado'}
              </Text>
            </div>
          </Col>
          <Col xs={3}>
            <div>
              <Text type="regular" color="dark">
                Tempo emissão: {travel.time_issuing || 'Não informado'}
              </Text>
            </div>
          </Col>
        </Row>
      </Card>
    </Col>
  );
}

export default TravelCard;
