import React, { useState, useEffect } from 'react';
import { Button, DataTable, Text } from 'v3/components';
import { useSnackbar } from 'v3/components/Snackbar';
import ApplicationLayout from 'v3/layouts/Application';
import { cooperplaceApi } from 'services/api';
import { formatDateTime } from 'utils/formatter';
import Filter from './Filter';
import FilterModal from './FilterModal';

export function WhatsAppLogs() {
  const snackbar = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [logs, setLogs] = useState([]);
  const [showFilterModa, setShowFilterModal] = useState(false);
  const [pagination, setPagination] = useState({
    last_page: 1,
    current_page: 1,
    total: 0,
  });
  const [filters, setFilters] = useState({
    perPage: 10,
    page: 1,
  });

  async function fetchData(params) {
    try {
      setLoading(true);
      const { data } = await cooperplaceApi.get('whatsapp_logs', {
        params: {
          ...params,
        },
      });
      setLogs(data.data);
      setPagination({
        current_page: data.meta.page,
        total: data.meta.total,
        last_page: data.meta.last_page,
      });
    } catch (error) {
      const message =
        error?.response?.data?.error ||
        'Não foi possível recuperar os checklists';
      snackbar.show(<Text>{message}</Text>, {
        type: 'error',
      });
    } finally {
      setLoading(false);
    }
  }

  async function changePage(page) {
    setFilters({ ...filters, page });
    await fetchData({ ...filters, page });
  }

  async function changePerPage(perPage) {
    setFilters({ ...filters, perPage });
    await fetchData({ ...filters, perPage });
  }

  useEffect(() => {
    async function loadLogs() {
      await fetchData(filters);
    }
    loadLogs();
  }, [filters]);

  return (
    <>
      <FilterModal
        submit={fetchData}
        perPage={filters.perPage}
        setFilters={setFilters}
        showModal={showFilterModa}
        setShowModal={setShowFilterModal}
        filters={filters}
      />
      <ApplicationLayout
        title="Logs do WhatsApp"
        RightComponent={
          <>
            <>
              <Button
                variant="primary"
                className="mr-2"
                onClick={() => setShowFilterModal(true)}
              >
                <Text weight={500} color="white" type="regular">
                  Filtros avançados
                </Text>
              </Button>
            </>
          </>
        }
      >
        <Filter
          submit={fetchData}
          perPage={filters.perPage}
          setFilters={setFilters}
          filters={filters}
        />
        <DataTable
          data={logs}
          loading={loading}
          withFilterOption={false}
          page={pagination?.current_page}
          pages={pagination?.last_page}
          total={pagination?.total}
          onPageChanged={changePage}
          onPerPageChanged={changePerPage}
          defaultPerPage={10}
          perPageOptions={[{ v: 10 }, { v: 20 }, { v: 30 }]}
          columns={[
            {
              name: 'ID Log',
              selector: row => row?.id || '-',
            },
            {
              name: 'Enviado por',
              selector: row => row?.user?.username || '-',
            },
            {
              name: 'Motorista',
              selector: row => row?.person?.socialName || '-',
            },
            {
              name: 'Telefone',
              selector: row => row?.phonenumber || '-',
            },
            {
              name: 'Tipo do Log',
              selector: row => row?.whatsAppTypes?.description || '-',
            },
            {
              name: 'ID da Oferta',
              selector: row => row?.relatedLoad?.id || row?.aggregationId,
            },
            {
              name: 'Data',
              selector: row => formatDateTime(row?.created_at) || '-',
            },
            {
              name: 'status',
              selector: row => row?.status ? 'Enviado' : 'Não enviado',
            },
            {
              name: 'Ticket Id',
              selector: row => row?.ticketId ? row?.ticketId : '-' ,
            },
            {
              name: 'Error',
              selector: row => row?.error ? row?.error : '-' ,
            },
          ]}
        />
      </ApplicationLayout>
    </>
  );
}
